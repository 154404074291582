import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Colors from '../colors'
import calendar from '../../assets/calendar.png';
import marker from '../../assets/marker.png';
import client from '../../assets/client.png';
import company from '../../assets/company.png';
import Api from '.././api/api'
import MyLoader from '.././util/loader'
import { jobPhotosUrl } from '.././api/api'
import { useSelector } from 'react-redux';
var FileSaver = require('file-saver');

const DetailView = (props) => {
    const history = useHistory()
    const location = useLocation()

    const [loader, setLoader] = useState(false);
    const [customer, setCustomer] = useState({});

    const job = location.state.job
    console.log('job: ' + JSON.stringify(job));

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }
 
    useEffect(() => {
        getCustomerProfile()
    }, [])

    const getCustomerProfile = () => {
        setLoader(true)
        Api.customerProfile(job.customer_id,
            (response) => {
                console.log('customerProfile: ' + JSON.stringify(response));
                setLoader(false)
                setCustomer(response.data)
            },
            (error) => {
                console.log(error);
                setLoader(false)
            });
    };

    const cancelJob = () => {
        setLoader(true)
        Api.cancelJob(job.job_id, user.id,
            (response) => {
                console.log('cancelJob: ' + JSON.stringify(response));
                setLoader(false)
                if (response.status === 'success') {
                    history.goBack()
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
            });
    };

    return (
        <div style={{ backgroundColor: props.changeView ? '#fafafa' : 'white', padding: props.changeView ? 10 : 0, marginLeft: props.changeView ? 10 : 0, width: '100%', minWidth: 350 }}>

            <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 0 }}>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        style={{ marginTop: 0, flex: 1, fontSize: 22, fontWeight: 'bold' }}>
                        {job.title}
                    </div>
                    <div style={{ fontSize: 18, marginTop: 3, color: job.status === 'cancelled' ? 'orange' : 'green' }}>
                        {job.status.toUpperCase()}
                    </div>
                </div>

                <div className='my-line' style={{}} />

                <div
                    className='option-label'
                    style={{ marginTop: 20, fontSize: 16 }}>
                    Expected Salary
                </div>
                <div
                    style={{ marginTop: 6, fontSize: 16 }}>
                    ${job.salary}
                </div>

                <div>
                    <div
                        className='option-label'
                        style={{ marginTop: 20, fontSize: 16 }}>
                        Job Summary
                    </div>
                    <div
                        style={{ marginTop: 6, fontSize: 16 }}>
                        {job.detail}
                    </div>
                </div>

                <div>
                    <div
                        className='option-label'
                        style={{ marginTop: 30, fontSize: 16 }}>
                        Skills Required
                    </div>
                    <div style={{ marginTop: 10, display: 'flex', flexWrap: 'wrap' }}>
                        {job.skills.split(',').map((item, index) => {
                            if (item === '') {
                                return null
                            }
                            return (
                                <div style={{ padding: 5, paddingLeft: 10, paddingRight: 10, marginRight: 10, borderRadius: 30, border: '1px solid ' + Colors.theme1 }}>
                                    <div
                                        style={{ marginTop: 0, fontSize: 16, color: Colors.theme1 }}>
                                        {item}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>

                <div
                    style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div
                        className='option-label'
                        style={{ marginTop: 0, fontSize: 16 }}>
                        Work Time & Location
                    </div>
                </div>

                <div
                    style={{ display: 'flex', marginTop: 10 }}>
                    <img
                        style={{ width: 17, height: 17, objectFit: 'contain', marginTop: 3 }}
                        src={calendar} />
                    <div>
                        <div
                            style={{ fontSize: 16, marginLeft: 10, color: 'black' }}>
                            {job.preferred_date}  {job.preferred_time}
                        </div>
                    </div>
                </div>

                <div
                    style={{ display: 'flex', marginTop: 5, marginLeft: -6, alignItems: 'center' }}>
                    <img
                        style={{ width: 30, height: 30, objectFit: 'contain' }}
                        src={marker} />
                    <div
                        style={{ fontSize: 16, marginLeft: 3 }}>
                        <div style={{ fontSize: 16, marginLeft: 0, color: 'black', textAlign: 'start' }}>
                            Destination: {job.location}, {job.country}
                        </div>
                    </div>
                </div>

                {customer !== null &&
                    <div>
                        <div
                            style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                            <div
                                className='option-label'
                                style={{ marginTop: 0, fontSize: 16 }}>
                                About Client
                            </div>
                        </div>
                        <div
                            style={{ display: 'flex', marginTop: 10 }}>
                            <img
                                style={{ width: 20, height: 20, objectFit: 'contain', marginTop: 3 }}
                                src={client} />
                            <div>
                                <div
                                    style={{ fontSize: 16, marginLeft: 10, color: 'black' }}>
                                    {customer.first_name} {customer.last_name}
                                </div>
                            </div>
                        </div>

                        {customer.company && customer.company !== '' &&
                            <div
                                style={{ display: 'flex', marginTop: 10 }}>
                                <img
                                    style={{ width: 20, height: 20, objectFit: 'contain', marginTop: 3 }}
                                    src={company} />
                                <div>
                                    <div
                                        style={{ fontSize: 16, marginLeft: 10, color: 'black' }}>
                                        {customer.company} {customer.location && customer.location !== '' ?
                                            ' - ' + customer.location
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }

                <div
                    style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                </div>

                {job.photos != null &&
                    <div style={{ marginTop: -25 }}>
                        <div className='option-label' style={{ fontSize: 14 }}>
                            Photos
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5 }}>
                            {job.photos.split(',').map((photoName, index) => {
                                console.log('url: ' + jobPhotosUrl + photoName);

                                return (
                                    <button
                                        onClick={() => {
                                            console.log('download');
                                            window.open(jobPhotosUrl + photoName, '_blank')
                                            // FileSaver.saveAs(jobPhotosUrl + photoName, "image.jpg")
                                        }}
                                        style={{ height: 200, marginRight: 15, border: '0px' }}>
                                        <img src={jobPhotosUrl + photoName} style={{ width: 200, height: 200, objectFit: 'contain' }} />
                                    </button>
                                )
                            })}
                        </div>
                    </div>

                }

            </div>

            <MyLoader loader={loader} marginTop={-10} />

        </div>
    );
}

export default DetailView; 
import React from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';

const Terms = () => {
    const history = useHistory();

    return (
        <div className=''>
            <div className="form" style={{ textAlign: 'left', padding: 30 }}>
                <h2 style={{marginTop:40}}>Terms of Use</h2>
                <p><strong>Effective Date:</strong> Nov 2024</p>
                <p>
                    These Terms of Use (“Terms”) govern your use of https://cariworks.com (the “Website”) and
                    its services. By accessing or using the Website, you agree to these Terms. If you do not
                    agree, please do not use the Website.
                </p>
                <h3>1. Use of the Website</h3>
                <ul>
                    <li>You must be at least 18 years old to use the Website.</li>
                    <li>You agree to use the Website only for lawful purposes, including job postings,
                        applications, and related activities.</li>
                </ul>
                <h3>2. User Accounts</h3>
                <ul>
                    <li>You are responsible for maintaining the confidentiality of your account and password.</li>
                    <li>You agree to provide accurate and truthful information during registration.</li>
                </ul>
                <h3>3. Prohibited Activities</h3>
                <ul>
                    <li>Post false or misleading job offers or applications.</li>
                    <li>Engage in fraud, abuse, or harassment of other users.</li>
                    <li>Upload viruses, malware, or harmful content.</li>
                </ul>
                <h3>4. Payments</h3>
                <ul>
                    <li>Payments made through the Website are non-refundable unless explicitly stated otherwise.</li>
                    <li>You are responsible for any applicable taxes.</li>
                </ul>
                <h3>5. Intellectual Property</h3>
                <p>
                    All content on the Website, including text, graphics, logos, and software, is owned by
                    CariWorks or its licensors. You may not copy, distribute, or create derivative works
                    without permission.
                </p>
                <h3>6. Disclaimer of Warranties</h3>
                <p>
                    The Website is provided “as is” without warranties of any kind. We do not guarantee the
                    accuracy, reliability, or availability of the Website.
                </p>
                <h3>7. Limitation of Liability</h3>
                <p>
                    To the fullest extent permitted by law, CariWorks shall not be liable for any indirect,
                    incidental, or consequential damages arising from your use of the Website.
                </p>
                <h3>8. Termination</h3>
                <p>
                    We reserve the right to suspend or terminate your account for any violation of these Terms
                    or applicable laws.
                </p>
                <h3>9. Changes to These Terms</h3>
                <p>
                    We may update these Terms at any time. Continued use of the Website after changes are posted
                    constitutes acceptance of the updated Terms.
                </p>
                <h3>10. Governing Law</h3>
                <p>
                    These Terms are governed by the laws of [Insert Jurisdiction]. Any disputes shall be
                    resolved in the courts of [Insert Location].
                </p>
                <h3>11. Contact Us</h3>
                <p>
                    If you have questions about these Terms, contact us at ddsurg@ddsurgical.com.
                </p>
            </div>
        </div>
    )
}

export default Terms
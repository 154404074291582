import React from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import Api from './api/api'

const AboutUs = () => {
    const history = useHistory()

    return (
        <div className='main'>

            <div className='login'>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}

                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    About Cariworks
                </div>

                <div className="form" style={{ marginTop: 20 }}>

                    <div>
                        Discover the vibrant world of opportunities with CariWorks, the ultimate Caribbean freelance job listing and job-seeking site! 
                        <br/><br/>Whether you're a skilled professional looking to offer your services or an employer in search of top talent, CariWorks connects you with the best the Caribbean has to offer. 
                        <br/><br/>Join our community today and start finding or listing jobs effortlessly. Embrace the island spirit and elevate your career with CariWorks – where Caribbean talent meets opportunity. Join now!
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', height: 70 }}>
                        <button
                            className='search-btn'
                            style={{ display: 'flex', alignSelf: 'center', marginTop: 20, width: 300 }}
                            onClick={() => {
                                history.push({
                                    pathname: '/signup'
                                })
                            }}>
                            Join Now
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AboutUs; 
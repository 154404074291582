import React, { useState, useEffect } from 'react';
import '../App.css';
import { Redirect, useHistory } from 'react-router-dom';
import logo from '../assets/home-logo.png';
import apple_icon from '../assets/apple_icon.png';
import play_icon from '../assets/play_icon.png';

const Footer = ({ toggleNav }) => {
    const history = useHistory()

    return (
        <div>
            <div style={{ width: '100%', height: 1, backgroundColor: 'lightgray' }} />
            <div
                className='footer'
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex:1 }}>
                <div
                    className='home-panel'
                    style={{}}>
                    <div
                        style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'space-between', justifyContent: 'center' }}>

                        <div className='footer-col' style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                            <div
                                className='footer-col1'
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold' }}>About</div>
                                <a
                                    href='/about-us'>
                                    About Us
                                </a>
                                <a
                                    href='/contact-form'>
                                    Contact us
                                </a>
                                <a
                                    href='/privacy'>
                                    Privacy Policy
                                </a>
                                <a
                                    href='/terms'>
                                    Terms of Use
                                </a>
                            </div>
                        </div>

                        <div className='footer-col' style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                            <div
                                className='footer-col1'
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold' }}>For Customers</div>
                                <a
                                    href='/how-customers'>
                                    How it Works
                                </a>
                                <a
                                    href='/post-job'>
                                    Post a Job
                                </a>
                                {/* <a
                                    href='/'>
                                    Join Us
                                </a> */}
                            </div>
                        </div>

                        <div className='footer-col' style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                            <div
                                className='footer-col1'
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold' }}>For Workers</div>
                                <a
                                    href='/how-pros'>
                                    How It Works
                                </a>
                                <a
                                    href='/contact-form'>
                                    Help
                                </a>
                            </div>
                        </div>

                        {/* <div className='footer-col' style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}> */}
                            {/* <div
                                className='footer-col1'
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold' }}>Mobile App</div>

                                <button
                                    onClick={() => {
                                        //
                                    }}
                                    className='icon-tab'
                                    style={{ border: '0px', padding: 10, maxWidth: 150, marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                                    <img
                                        src={play_icon}
                                        style={{ width: 35, height: 40, objectFit: 'contain' }} />
                                    <div>
                                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                                            Download
                                        </div>
                                        <div style={{ marginLeft: 10, }}>
                                            Google Play
                                        </div>
                                    </div>
                                </button>

                                <button
                                    onClick={() => {
                                        //
                                    }}
                                    className='icon-tab'
                                    style={{ border: '0px', padding: 10, maxWidth: 150, marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                                    <img
                                        src={apple_icon}
                                        style={{ width: 35, height: 40, objectFit: 'contain' }} />
                                    <div>
                                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                                            Download
                                        </div>
                                        <div style={{ marginLeft: 10, }}>
                                            Apple Store
                                        </div>
                                    </div>
                                </button>

                            </div> */}
                            
                        {/* </div> */}

                    </div>


                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                        <div style={{ width: '50%', height: 1, backgroundColor: 'lightgray', marginBottom: 10 }} />
                        <div style={{}}>
                            © 2024 Cariworks, All Rights Reserved
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Footer;

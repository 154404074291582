import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { useLocation, useHistory } from 'react-router-dom';
import * as Colors from '.././colors'
import { FormGroup, Input } from 'reactstrap';
import MyLoader from '.././util/loader'
import image from '../../assets/image.png';
import cross from '../../assets/cross1.png';
import { showOption } from './option'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import showToast from '.././util/toast'
import GLOBAL from '.././constants/global';
import {plumberQuestions} from '../constants/constants'

var files = []

const PlumberQuote = () => {
    const history = useHistory()

    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const questions = plumberQuestions

    const [services, setServices] = useState([
        { title: 'Install', selected: false },
        { title: 'Repair', selected: false },
        { title: 'Replace', selected: false },
        { title: 'Removal', selected: false }
    ])

    const [problems, setProblems] = useState([
        { title: 'No problem. Install / replace only.', selected: false },
        { title: 'Leak / drip', selected: false },
        { title: 'Burst / cracked', selected: false },
        { title: 'Clogged', selected: false },
        { title: 'Low pressure', selected: false },
        { title: 'Noisy', selected: false },
        { title: 'Smell / dirty', selected: false },
        { title: 'Ceiling water stain', selected: false },
        { title: 'Wall seepage', selected: false },
        { title: 'Fixture not draining / flushing', selected: false },
        { title: 'Others (Explain in notes below)', selected: false },
    ])

    const [items, setItems] = useState([
        { title: 'Toilet', selected: false },
        { title: 'Tap / basin', selected: false },
        { title: 'Shower', selected: false },
        { title: 'Pipe / drain', selected: false },
        { title: 'Bathtub', selected: false },
        { title: 'Water heater', selected: false },
        { title: 'Others (Explain in notes below)', selected: false },
    ])

    const [wheres, setWheres] = useState([
        { title: 'Bathroom', selected: false },
        { title: 'Kitchen', selected: false },
        { title: 'Bedroom', selected: false },
        { title: 'Living / Dining', selected: false },
        { title: 'Garden / Outdoor', selected: false },
        { title: 'Others (Explain in notes below)', selected: false },
    ])

    const [buildings, setBuildings] = useState([
        { title: 'Landed (e.g terrace, semi-d, bungalow)', selected: false },
        { title: 'High-rise (e.g. condo, apartment)', selected: false },
        { title: 'Light commercial (e.g. office, shop, cafe)', selected: false },
        { title: 'Commercial (e.g. shopping centre, factory)', selected: false },
    ])

    const [times, setTimes] = useState([
        { title: 'Morning (09:00 - 11:00)', selected: false },
        { title: 'Lunch Time (11:00 - 13:00)', selected: false },
        { title: 'Early Afternoon (13:00 - 15:00)', selected: false },
        { title: 'Late Afternoon (15:00 - 17:00)', selected: false },
    ])

    const [preferredDate, setPreferredDate] = useState(new Date());
    const [details, setDetails] = useState('');

    const addFile = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(async () => {
        let plumberJobState = localStorage.getItem('plumberJobState')
        if (plumberJobState != null) {
            plumberJobState = JSON.parse(plumberJobState)
            setServices(plumberJobState.services)
            setProblems(plumberJobState.problems)
            setItems(plumberJobState.items)
            setBuildings(plumberJobState.buildings)
            setWheres(plumberJobState.wheres)
            setTimes(plumberJobState.times)
            setPreferredDate(moment(plumberJobState.preferredDate, 'DD MMM YYYY').toDate())
            setDetails(plumberJobState.details)
            setSelectedFiles(GLOBAL.plumberCacheFiles)
        }
    }, []);

    const onFilesSelect = (event) => {
        files = event.target.files
        setSelectedFiles(Array.from(event.target.files))
    };

    const saveInfo = () => {
        // services
        let servicesSelected = ''
        for (let i = 0; i < services.length; i++) {
            if (services[i].selected) {
                servicesSelected = servicesSelected + services[i].title + ','
            }
        }
        servicesSelected = servicesSelected.substring(0, servicesSelected.length - 1)

        // problems
        let problemsSelected = ''
        for (let i = 0; i < problems.length; i++) {
            if (problems[i].selected) {
                problemsSelected = problemsSelected + problems[i].title + ','
            }
        }
        problemsSelected = problemsSelected.substring(0, problemsSelected.length - 1)

        // items
        let itemsSelected = ''
        for (let i = 0; i < items.length; i++) {
            if (items[i].selected) {
                itemsSelected = itemsSelected + items[i].title + ','
            }
        }
        itemsSelected = itemsSelected.substring(0, itemsSelected.length - 1)

        // wheres
        let wheresSelected = ''
        for (let i = 0; i < wheres.length; i++) {
            if (wheres[i].selected) {
                wheresSelected = wheresSelected + wheres[i].title + ','
            }
        }
        wheresSelected = wheresSelected.substring(0, wheresSelected.length - 1)

        // buildings
        let buildingsSelected = ''
        for (let i = 0; i < buildings.length; i++) {
            if (buildings[i].selected) {
                buildingsSelected = buildingsSelected + buildings[i].title + ','
            }
        }
        buildingsSelected = buildingsSelected.substring(0, buildingsSelected.length - 1)

        // times
        let timesSelected = ''
        for (let i = 0; i < times.length; i++) {
            if (times[i].selected) {
                timesSelected = timesSelected + times[i].title + ','
            }
        }
        timesSelected = timesSelected.substring(0, timesSelected.length - 1)

        // date
        let preferredDateSelected = true
        // if (moment(preferredDate) > moment()) {
        //     preferredDateSelected = true
        // }

        if (servicesSelected === '' || problemsSelected === '' || itemsSelected === '' || wheresSelected === '' || buildingsSelected === '' || timesSelected === '') {
            showToast('error', 'Please fill complete form')
            return
        }
        if (!preferredDateSelected) {
            showToast('error', 'Invalid preferred date!')
            return
        }

        // store state
        let plumberJobState = {
            services: services,
            problems: problems,
            items: items,
            wheres: wheres,
            buildings: buildings,
            preferredDate: moment(preferredDate).format('DD MMM YYYY'),
            times: times,
            details: details,
        }
        localStorage.setItem('plumberJobState', JSON.stringify(plumberJobState))
        GLOBAL.plumberCacheFiles = selectedFiles

        // send job details
        let plumberJob = {
            questions: [
                { q: questions[0], a: servicesSelected },
                { q: questions[1], a: problemsSelected },
                { q: questions[2], a: itemsSelected },
                { q: questions[3], a: wheresSelected },
                { q: questions[4], a: buildingsSelected },
            ],
            timesSelected: timesSelected,
            preferredDate: moment(preferredDate).format('DD MMM YYYY'),
            selectedFiles: selectedFiles,
            details: details,
        }
        history.push({
            pathname: '/quote-contact-details',
            state: { job: plumberJob, jobType: 'plumber' }
        })
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1, paddingBottom: 30 }}>

            <div className='login quote-top' style={{ paddingLeft: 0, paddingRight: 0, marginTop: 30 }}>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}

                <div className='home-label' style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 20 }}>
                    Plumber
                </div>

                <div className='my-line' />

                <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>

                    <div style={{ fontSize: 16 }}>
                        Questions marked <span style={{ color: 'red' }}>*</span> are required<br />
                        Hire our trained, professional plumbers to fix your leaks, clogs, or install your electrical appliances.<br />
                        <span style={{ fontWeight: 'bold' }}>How it works:</span><br />
                        1. Select your required service below, tell us as much as you can about what you need.<br />
                        2. Upon submission of the booking, our plumbing technicians will contact you.<br />
                        3. Our technician team will do a site visit and provide you a detailed quotation depending on what service you need. If you decide not to go ahead with the service, there may be a site visit fee.<br />
                    </div>

                    {showOption(questions[0], services, setServices, false)}

                    {showOption(questions[1], problems, setProblems, true)}

                    {showOption(questions[2], items, setItems, true)}

                    {showOption(questions[3], wheres, setWheres, true)}

                    {showOption(questions[4], buildings, setBuildings, false)}

                    <div style={{ fontSize: 16, marginTop: 30 }}>
                        Condo residents: Remember to inform your condo management office if the appointment date is after 5pm or on weekends.
                    </div>

                    <div className='option-label'>
                        <span style={{ color: 'red' }}>*</span> Preferred Appointment Date
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <DatePicker
                            selected={preferredDate}
                            onChange={(date) => setPreferredDate(date)}
                            customInput={
                                <FormGroup>
                                    <Input
                                        minDate={moment()}
                                        style={{ fontSize: 18 }}
                                        type='text'
                                        placeholder='Choose Date'
                                        value={moment(preferredDate).format('DD MMM YYYY')}
                                        onChange={(event) => setPreferredDate(event.target.value)} />
                                </FormGroup>
                            } />
                    </div>

                    <div className='option-label'>
                        <span style={{ color: 'red' }}>*</span> Preferred Appointment Time
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                        {times.map((item, index) => {
                            return (
                                <button
                                    onClick={() => {
                                        const newList = times.map((item_) => {
                                            if (item_.title === item.title)
                                                return { ...item_, selected: !item.selected };
                                            else
                                                return { ...item_, selected: false }; // set others false
                                        });
                                        setTimes(newList)
                                    }}
                                    className={item.selected ? 'tab-item2-sel' : 'tab-item2'}>
                                    {item.title}
                                </button>
                            )
                        })}
                    </div>

                    <div className='option-label'>
                        Tell us more about your requirements
                    </div>
                    <div style={{ fontSize: 14, color: 'gray' }}>
                        Share any other details so our Pros can troubleshoot your plumbing problems better
                    </div>
                    <FormGroup style={{ marginTop: 10 }}>
                        <Input
                            type='textarea'
                            placeholder=''
                            value={details}
                            onChange={(event) => {
                                setDetails(event.target.value)
                            }} />
                    </FormGroup>

                    <div
                        className='option-label'
                        style={{ alignSelf: 'flex-start' }}>
                        Upload photo (Optional)
                    </div>
                    <button
                        onClick={() => {
                            addFile.current.click();
                        }}
                        style={{ marginTop: 0, backgroundColor: 'transparent', border: '0px', width: '100%', padding: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, borderRadius: 5, border: '1px dotted gray', borderColor: 'gray', alignItems: 'center', justifyContent: 'center', padding: 30 }}>
                            <img
                                src={image}
                                style={{ width: 40, height: 40 }} />
                            <div style={{ color: 'gray', fontSize: 16 }}>Choose File</div>
                        </div>
                    </button>
                    <input
                        type='file'
                        multiple="multiple"
                        style={{ display: 'none' }}
                        ref={addFile}
                        onChange={(event) => {
                            onFilesSelect(event)
                        }} />

                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedFiles.map((item, index) => {
                            let url = URL.createObjectURL(item)
                            return (
                                <div style={{ display: 'flex', marginTop: 10, marginRight: 20, width: 100, height: 100 }}>
                                    <img
                                        style={{ width: 100, height: 100, objectFit: 'contain' }}
                                        src={url} />
                                    <img
                                        onClick={() => {
                                            const temp = selectedFiles.filter((item, j) => {
                                                return j != index; //false will delete
                                            });
                                            setSelectedFiles(temp)
                                        }}
                                        style={{ width: 40, height: 40, borderRadius: 20, marginLeft: -20, backgroundColor: 'white' }}
                                        src={cross} />
                                </div>
                            )
                        })}
                    </div>

                    {error &&
                        <div style={{ color: 'red', textAlign: 'center', marginTop: 10 }}>
                            {error}
                        </div>
                    }

                    <button
                        onClick={saveInfo}
                        className='login-btn'
                        style={{ marginTop: 20, fontSize: 17, width: '100%' }}>
                        Next
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default PlumberQuote; 
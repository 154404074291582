import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useHistory, useLocation } from 'react-router-dom';
import * as Colors from '../colors'
import plumber from '../../assets/plumber.png';
import paint from '../../assets/paint.png';
import aircon from '../../assets/aircon.png';
import search from '../../assets/search.png';
import marker from '../../assets/marker.png';
import calendar from '../../assets/calendar.png';
import bag from '../../assets/bag.png';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { countries } from '../util/cats';
import arrow_right from '../../assets/arrow_right.png';
import Card from '@mui/material/Card';
import Api from '../api/api';
import showToast from '../util/toast'
import { useSelector } from 'react-redux';
import { styled, textAlign } from '@mui/system';
import MyLoader from '../util/loader';
const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '55px',
        fontSize: '16px', // Adjust font size to fit
    },
    '& .MuiInputLabel-root': {
        marginTop: 6,
        fontSize: '16px', // Make label size consistent
    },
});
const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiInputBase-root': {
        height: '55px',
        fontSize: '14px',
    },
});

const SearchJobs = () => {
    const history = useHistory()
    const loc = useLocation()
    const { jobTitle, location } = loc.state || {}; // Destructure passed data

    const [country, setCountry] = useState(location ? location : 'United States');
    const [jobTitle_, setJobTitle] = useState(jobTitle ? jobTitle : '');

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }
    console.log('user: ' + user);

    const [tabSel, setTabSel] = useState(1)

    const [loader, setLoader] = useState(false)
    const [openJobs, setOpenJobs] = useState([])
    const [quotedJobs, setQuotedJobs] = useState([])
    // const quotedJobs = useSelector(state => state.MyReducer.quotedJobs);
    const [activeJobs, setActiveJobs] = useState([])

    useEffect(() => {
        loadOpenJobs()
        loadAppliedJobs()
    }, [])

    const loadOpenJobs = () => {
        setLoader(true)
        Api.searchJobs(user != null ? user.id : '', '', '',
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    if (response.jobs.length > 0) {
                        console.log('JOB: ' + JSON.stringify(response.jobs[0]));
                    }
                    setOpenJobs(response.jobs)
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Request failed!')
            });
    };

    const loadAppliedJobs = () => {
        setLoader(true)
        Api.appliedJobs(user != null ? user.id : '',
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    let activeJobs = []
                    let appliedJobs = []
                    for (let i = 0; i < response.applied_jobs.length; i++) {
                        const job = response.applied_jobs[i];
                        console.log('accepted: ' + job.accepted);
                        if (job.accepted + '' === '1') {
                            activeJobs.push(job)
                        }
                        else {
                            appliedJobs.push(job)
                        }
                    }
                    setActiveJobs(activeJobs)
                    setQuotedJobs(appliedJobs)
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Request failed!')
            });
    };

    const getIcon = (job) => {
        let icon = bag
        return icon
    };

    const getJobStatus = (job) => {
        return job.status
    };

    const getJobType = (job) => {
        let jobType = ''
        if (job.job_type === 'plumber') {
            jobType = 'Plumber'
        }
        else if (job.job_type === 'painting') {
            jobType = 'House Painting'
        }
        else if (job.job_type === 'aircon') {
            jobType = 'Aircon'
        }
        else if (job.job_type === 'mover') {
            jobType = 'Domestic Mover'
        }
        return jobType
    };

    const showJobs = () => {
        let jobs = []
        if (tabSel == 1)
            jobs = openJobs
        else if (tabSel == 2)
            jobs = quotedJobs
        else if (tabSel == 3)
            jobs = activeJobs

        if (loader) {
            return null
        }
        if (jobs.length == 0) {
            return <div style={{ alignSelf: 'center', textAlign: 'center', color: 'gray' }}>No Jobs Found</div>
        }

        return jobs.map((job_, index) => {
            let job = job_
            if (tabSel == 2 || tabSel == 3) {
                job = job_.job
            }
            if (!job.country.toLowerCase().includes(country.toLowerCase())) {
                return null
            }
            if (!job.title.toLowerCase().includes(jobTitle_)) {
                return null
            }

            return (
                <Card
                    onClick={() => {
                        // console.log('job: ' + JSON.stringify(job)
                    }}
                    sx={{ marginBottom: 2, backgroundColor: Colors.theme3 }}>

                    <button
                        className='card-hover'
                        style={{ border: '0px', width: '100%', padding: 0, display: 'flex', flexDirection: 'column' }}>
                        <div
                            className='job-item'
                            style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%', marginBottom: -19, alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>

                            <div style={{ padding: 20, marginRight: 15, marginLeft: 10, marginTop: 2, borderRadius: 50, backgroundColor: 'white', marginBottom: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    style={{ width: 45, height: 45 }}
                                    src={getIcon(job)} />
                            </div>

                            <div
                                className='margin-left-mobile'
                                style={{ display: 'flex', minWidth: 300, flexDirection: 'column', flex: 3, marginTop: 0, paddingTop: 10, paddingBottom: 10 }}>
                                <div className='vendor-info'>
                                    <div style={{ fontSize: 18, marginLeft: 0, fontWeight: 'bold' }}>
                                        {job.title}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: -6, marginTop: 5 }}>
                                    <img
                                        style={{ width: 30, height: 30, objectFit: 'contain' }}
                                        src={marker} />
                                    <div style={{ fontSize: 16, marginLeft: 5, color: 'gray', textAlign: 'start' }}>
                                        Destination: {job.location}, {job.country}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flex: 1, alignItems: 'center', marginTop: 10 }}>
                                    <img
                                        style={{ width: 20, height: 20, objectFit: 'contain' }}
                                        src={calendar} />
                                    <div style={{ fontSize: 16, marginLeft: 10, color: 'gray' }}>
                                        Work Date: {job.preferred_date}
                                    </div>
                                </div>

                            </div>

                            <div
                                style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 15 }}
                                className='vendor-btns'>
                                {
                                    tabSel == 2 ?
                                        <div style={{ color: 'gray', fontSize: 16, fontWeight: 'bold', marginLeft: 5, backgroundColor: 'white', borderRadius: 25, padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                                            Quoted
                                        </div>
                                        :
                                        tabSel == 3 ?
                                            <div style={{ color: 'gray', fontSize: 16, fontWeight: 'bold', marginLeft: 5, backgroundColor: 'white', borderRadius: 25, padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                                                Active
                                            </div>
                                            :
                                            <div style={{ color: 'gray', fontSize: 16, fontWeight: 'bold', marginLeft: 5, backgroundColor: 'white', borderRadius: 25, padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                                                Open
                                            </div>
                                }
                            </div>

                        </div>

                        <div style={{ marginTop: 20, padding: 15, backgroundColor: 'white', width: '100%' }}>

                            {tabSel >= 2 &&
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <div style={{ fontSize: 16, fontWeight: 'bold', color: 'gray', minWidth: 300, textAlign: 'start' }}>
                                            My Quote:
                                        </div>
                                        {/* <div style={{ fontSize: 16, color: 'gray', fontStyle: 'italic', textAlign: 'start' }}>
                                            26 Nov 2022 10:30 am
                                        </div> */}
                                    </div>
                                    <div style={{ fontSize: 16, marginTop: 0, color: 'gray' }}>
                                        {job_.price_type}: ${job_.price}
                                    </div>
                                </div>}

                            <div
                                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                                className='vendor-btns'>
                                <button
                                    onClick={() => {
                                        if (user == null) {
                                            history.push('/login')
                                            return
                                        }
                                        history.push({
                                            pathname: '/vendor-project-details',
                                            state: {  // location state
                                                leftPanel: tabSel == 1 ? 'quote' : 'chat',
                                                job: job,
                                            },
                                        });
                                    }}
                                    style={{ border: '1px solid ' + Colors.theme1, backgroundColor: 'transparent', width: 150, height: 35, borderRadius: 5 }}>
                                    <div style={{ color: Colors.theme1, fontSize: 16, marginLeft: 5 }}>
                                        VIEW DETAILS
                                    </div>
                                </button>
                            </div>

                        </div>
                    </button>

                </Card>
            )
        })
    };




    return (
        <div style={{ backgroundColor: Colors.background, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 20 }}>

            <div
                className='home-panel'
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>

                <div style={{ width: '100%', paddingBottom: 20, paddingTop: 0, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

                    <div
                        style={{ flexDirection: 'column', flex: 3, minWidth: 275, alignSelf: 'center', padding: 20, backgroundColor: 'white', borderRadius: 10, height: '90vh', overflow: 'scroll', marginLeft: 20, }}>

                        <div className='my-title' style={{ width: '100%', textAlign: 'center' }}>
                            <img src={search} style={{ width: 30, height: 30, marginTop: -3, marginRight: 10 }} />
                            Search Jobs
                        </div>

                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 20, alignSelf: 'center', flexWrap: 'wrap' }}>
                            <div style={{ marginTop: 5, marginRight: window.innerWidth < 800 ? 0 : 10 }}>
                                <StyledAutocomplete
                                    disablePortal
                                    options={countries}
                                    containerStyle={{ fontSize: 15 }}
                                    value={country}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <StyledTextField {...params} label="" />}
                                    onChange={(event, newVal) => {
                                        console.log('country: ' + newVal);
                                        setCountry(newVal == null ? '' : newVal)
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: 5 }}>
                                <StyledTextField
                                    id="outlined-basic"
                                    label="Job Title"
                                    sx={{ width: 300 }}
                                    variant="outlined"
                                    value={jobTitle_}
                                    size='small'
                                    onChange={(event) => {
                                        setJobTitle(event.target.value)
                                    }} />
                            </div>
                        </div>

                        {showJobs()}

                        <MyLoader loader={loader} marginTop={-10} />
                    </div>

                </div>

            </div>

        </div >
    );
}

export default SearchJobs; 
import React, { useState, useRef, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import * as Colors from '../../colors'
import * as Constants from '../../constants/constants'
import TextField from '@mui/material/TextField';
import download from '../../../assets/view.png';
import { documentsUrl } from '../../api/api'
import GLOBAL from '../../constants/global.js';
var FileSaver = require('file-saver');

const ProfileSection4 = (props) => {
    const history = useHistory()
    let businessProfile = { ...props.businessProfile }

    const [icNumber, setIcNumer] = useState('');

    const addFile = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const checkValid = (key) => {
        return businessProfile[key] !== undefined && businessProfile[key] != null && businessProfile[key] !== '' && businessProfile[key] !== 'null'
    };

    useEffect(() => {
        if (checkValid('id_number'))
            setIcNumer(businessProfile.id_number)
        if (checkValid('documents')) {
            let arr = businessProfile.documents.split(',')
            let docs = []
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === '')
                    continue
                console.log();
                docs.push({ name: arr[i], url: true })
            }
            setSelectedFiles(docs)
        }
    }, [props])

    const onFilesSelect = (event) => {
        let files = [...selectedFiles]

        let arr = Array.from(event.target.files)
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            files.push(item)
        }

        setSelectedFiles(files)
        GLOBAL.businessDocsCache = files
    };

    const updateValue = (key, value) => {
        businessProfile[key] = value
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Business Validation Check
            </div>

            <div style={{ fontSize: 14, marginTop: 5, color: 'gray', textAlign: 'center', alignSelf: 'center' }}>
                Please upload a copy of your business registration document (SSM) for verification if you are a registered business. If you are a freelancer, please upload a copy of your identity card (IC) along with your latest utility bill (water, electricity, internet or phone bill etc.) that is registered under your name.
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <TextField
                    id="outlined-basic"
                    label="ID Card Number"
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    value={icNumber}
                    size='small'
                    onChange={(event) => {
                        setIcNumer(event.target.value)
                        updateValue('id_number', event.target.value)
                    }} />
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 5, display: 'flex', flexDirection: 'column' }}>

                {selectedFiles.map((fileItem, index) => {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', marginTop: 5, height: 45, alignItems: 'center' }}>
                                <div style={{ width: 100, display: 'flex', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, paddingLeft: 10, paddingRight: 10, marginRight: -1, fontSize: 18, color: 'gray', backgroundColor: 'white', border: '1px solid lightgray', height: 45, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                    Document
                                </div>
                                <div
                                    style={{ flex: 1, maxLines: 1, fontSize: 16, paddingLeft: 10, borderTopRightRadius: 5, borderBottomRightRadius: 5, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: Colors.theme1, textAlign: 'center', alignItems: 'center', display: 'flex' }}
                                    className='one-line-txt'>
                                    {fileItem.name}
                                </div>
                                <div style={{ width: 30, display: 'flex', justifyContent: 'flex-end', marginLeft: 10 }}>
                                    <button
                                        onClick={() => {
                                            if (fileItem.url)
                                                FileSaver.saveAs(documentsUrl + fileItem.name, fileItem.name)
                                            else
                                                FileSaver.saveAs(fileItem, fileItem.name)
                                        }}
                                        style={{ border: '0px', backgroundColor: 'transparent', fontSize: 14, fontWeight: 'bold' }}>
                                        <img src={download} style={{ width: 25, height: 25 }} />
                                    </button>
                                </div>
                                <div style={{ width: 30, display: 'flex', justifyContent: 'flex-end' }}>
                                    <button
                                        onClick={() => {
                                            const temp = selectedFiles.filter((item, j) => {
                                                return index !== j; //false will delete
                                            });
                                            setSelectedFiles(temp)
                                            GLOBAL.delete_files = GLOBAL.delete_files + fileItem.name + ','
                                        }}
                                        style={{ border: '0px', fontSize: 14, backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>
                                        X
                                    </button>
                                </div>

                            </div>
                        </div>
                    )
                })}

                <button
                    onClick={() => {
                        addFile.current.click();
                    }}
                    className='login-btn vendor-view-profile'
                    style={{ height: 45, fontSize: 17, marginTop: 10, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                    Add Documents
                </button>

                <input
                    type='file'
                    multiple="multiple"
                    style={{ display: 'none' }}
                    ref={addFile}
                    onChange={(event) => {
                        onFilesSelect(event)
                    }} />

            </div>

        </div>
    )
}

export default ProfileSection4; 
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Colors from '../colors'
import calendar from '../../assets/calendar.png';
import marker from '../../assets/marker.png';
import client from '../../assets/client.png';
import company from '../../assets/company.png';
import { plumberQuestions, paintingQuestions, airconQuestions, moverQuestions } from '../constants/constants'
import showToast from '../util/toast';
import Api from '.././api/api'
import MyLoader from '.././util/loader'
import { jobPhotosUrl } from '.././api/api'
import { useSelector } from 'react-redux';
import GLOBAL from '../constants/global';
var FileSaver = require('file-saver');

const ConfirmJob = () => {
    const history = useHistory()
    const location = useLocation()
    const [loader, setLoader] = useState(false);

    const job = location.state.job
    // console.log('job: ' + JSON.stringify(job));

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const postJob = () => {
        console.log('postJob');
        history.push({
            pathname: '/payment',
            state: { job: job }
        })
        // setLoader(true)
        // Api.postNewJob(job,
        //     (response) => {
        //         console.log('response: ' + JSON.stringify(response));
        //         if (response.status === 'success') {
        //             // clear caches
        //             localStorage.removeItem('jobState')
        //             GLOBAL.cacheFiles = []
        //             showToast('success', 'Job Posted!')
        //             history.replace('/customer-dashboard')
        //         }
        //         else {
        //             showToast('error', response.msg)
        //         }
        //         setLoader(false)
        //     },
        //     (error) => {
        //         setLoader(false)
        //         console.log(JSON.stringify(error));
        //         showToast('error', 'Some issue on server side. Please try later')
        //     });
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 30, backgroundColor: '#f2f2f2' }}>
            <div className='login quote-top' style={{ maxWidth: 650, borderRadius: 10, marginTop: 20, backgroundColor: 'white', padding: 0, marginLeft: 0, width: '100%', minWidth: 350 }}>

                <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div
                            style={{ marginTop: 0, flex: 1, fontSize: 22, fontWeight: 'bold' }}>
                            Confirm Job Details
                        </div>
                    </div>

                    <div className='my-line' style={{}} />

                    <div
                        className='option-label'
                        style={{ marginTop: 20, fontSize: 16 }}>
                        Job Title
                    </div>
                    <div
                        style={{ marginTop: 6, fontSize: 16 }}>
                        {job.title}
                    </div>

                    <div
                        className='option-label'
                        style={{ marginTop: 20, fontSize: 16 }}>
                        Expected Salary
                    </div>
                    <div
                        style={{ marginTop: 6, fontSize: 16 }}>
                        ${job.salary}
                    </div>

                    <div>
                        <div
                            className='option-label'
                            style={{ marginTop: 20, fontSize: 16 }}>
                            Job Summary
                        </div>
                        <div
                            style={{ marginTop: 6, fontSize: 16 }}>
                            {job.detail}
                        </div>
                    </div>

                    <div>
                        <div
                            className='option-label'
                            style={{ marginTop: 30, fontSize: 16 }}>
                            Skills Required
                        </div>
                        <div style={{ marginTop: 10, display: 'flex', flexWrap: 'wrap' }}>
                            {job.skills.map((item, index) => {
                                return (
                                    <div style={{ padding: 5, paddingLeft: 10, paddingRight: 10, marginRight: 10, borderRadius: 30, border: '1px solid ' + Colors.theme1 }}>
                                        <div
                                            style={{ marginTop: 0, fontSize: 16, color: Colors.theme1 }}>
                                            {item}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>

                    <div
                        style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div
                            className='option-label'
                            style={{ marginTop: 0, fontSize: 16 }}>
                            More Details
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10 }}>
                        <img
                            style={{ width: 17, height: 17, objectFit: 'contain', marginTop: 3 }}
                            src={calendar} />
                        <div>
                            <div
                                style={{ fontSize: 16, marginLeft: 10, color: 'black' }}>
                                {job.preferredDate}  {job.timesSelected}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ display: 'flex', marginTop: 5, marginLeft: -6, alignItems: 'center' }}>
                        <img
                            style={{ width: 30, height: 30, objectFit: 'contain' }}
                            src={marker} />
                        <div
                            style={{ fontSize: 16, marginLeft: 3 }}>
                            <div style={{ fontSize: 16, marginLeft: 0, color: 'black', textAlign: 'start' }}>
                                Destination: {job.location}, {job.country}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ display: 'flex', marginTop: 5, marginLeft: -6, alignItems: 'center' }}>
                        <img
                            style={{ width: 17, height: 17, marginLeft: 6, objectFit: 'contain' }}
                            src={company} />
                        <div
                            style={{ fontSize: 16, marginLeft: 10 }}>
                            <div style={{ fontSize: 16, marginLeft: 0, color: 'black', textAlign: 'start' }}>
                                Sector: {job.jobType}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    </div>

                    {GLOBAL.cacheFiles != null &&
                        <div style={{ marginTop: -25 }}>
                            <div
                                style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                <div
                                    className='option-label'
                                    style={{ marginTop: 0, fontSize: 16 }}>
                                    Files
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {GLOBAL.cacheFiles.map((item, index) => {
                                    let url = URL.createObjectURL(item)
                                    return (
                                        <div style={{ display: 'flex', marginTop: 10, marginRight: 20, alignItems: 'center' }}>
                                            <div style={{ color: 'blue' }}>
                                                File{(index + 1)}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>

                    }

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                            onClick={() => {
                                history.goBack()
                            }}
                            className='login-btn'
                            style={{ marginTop: 20, fontSize: 17, width: '100%', backgroundColor: 'gray' }}>
                            Edit
                        </button>
                        <button
                            onClick={postJob}
                            className='login-btn'
                            style={{ marginTop: 20, marginLeft: 10, fontSize: 17, width: '100%' }}>
                            Post Job
                        </button>
                    </div>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default ConfirmJob; 
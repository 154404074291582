import React, { useState, useEffect, useRef } from 'react';
import '../../../App';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from '../../colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from '../../util/loader'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux';

const MoverSection11 = (props) => {
    const history = useHistory()
    let moverJobInfo = { ...props.moverJobInfo }

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        let user = localStorage.getItem('user')
        if (user != null)
            user = JSON.parse(user)

        let quoteContactDetails = localStorage.getItem('quoteContactDetails')
        if (user != null) {
            setEmail(user.email)
            if (quoteContactDetails != null) {
                let obj = JSON.parse(quoteContactDetails)
                if (user.phone != null && user.phone !== '') {
                    setPhone(user.phone)
                }
                else {
                    setPhone(obj.phone)
                }
            }
        }
        else {
            console.log('user null');
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Let us know how to contact you!
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 5 }}>
                <FormGroup>
                    <Input
                        disabled
                        style={{ fontSize: 16, borderRadius: 5, height: 45, marginTop: 5 }}
                        type='email'
                        placeholder='Email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)} />
                </FormGroup>
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 5 }}>
                <FormGroup style={{ marginTop: 5 }}>
                    <PhoneInput
                        inputStyle={{ height: 45, fontSize: 16, width: '100%' }}
                        country={'my'}
                        value={phone}
                        onChange={phone => {
                            setPhone(phone)
                            // store state
                            let quoteContactDetails = {
                                phone: phone,
                            }
                            localStorage.setItem('quoteContactDetails', JSON.stringify(quoteContactDetails))
                        }}
                    />
                </FormGroup>
            </div>

            <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: 16, color: 'gray' }}>Please note by submitting your request, you are agreeing to our</div>
                <button
                    onClick={() => {
                        // history.push('/login')
                    }}
                    style={{ fontSize: 16, marginLeft: 0, border: '0px', backgroundColor: 'transparent', color: Colors.theme1 }}>
                    Terms of Use
                </button>
            </div>

        </div>
    );
}

export default MoverSection11; 
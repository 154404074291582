import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from '.././colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from '.././util/loader'
import MyError from '.././util/error'
import MyStorage from '.././util/storage'
import Api from '.././api/api'
import image from '../../assets/image.png';
import cross from '../../assets/cross1.png';
import { showOption } from './option'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import GLOBAL from '.././constants/global';
import showToast from '.././util/toast'
import { airconQuestions } from '../constants/constants';

const AirconQuote = () => {
    const history = useHistory()

    const questions = airconQuestions

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const [services, setServices] = useState([
        {
            title: 'Wall Unit - General Cleaning',
            detail: 'Recommended if your aircon just needs regular servicing and does not have issues.',
            input: false,
            selected: false,
            count: 0,
            cost: 100,
        },
        {
            title: 'Wall Unit - Chemical Cleaning',
            detail: 'Recommended if your aircon is not cold, or has not been cleaned for more than 6 months.',
            input: false,
            selected: false,
            count: 0,
            cost: 150,
        },
        {
            title: 'Wall Unit - Overhaul cleaning',
            detail: 'Recommended if your aircon has not been cleaned for 12 months.',
            input: false,
            selected: false,
            count: 0,
            cost: 200,
        },
        {
            title: 'Casette Unit - General cleaning',
            detail: 'Recommended if your aircon just needs regular servicing and does not have issues',
            input: false,
            selected: false,
            count: 0,
            cost: 250,
        },
        {
            title: 'Casette Unit - Chemical cleaning',
            detail: 'Recommended if your aircon is not cold, or has not been cleaned for more than 6 months',
            input: false,
            selected: false,
            count: 0,
            cost: 300,
        },
        {
            title: 'Casette Unit - Overhaul cleaning',
            detail: 'Recommended if your aircon has not been cleaned for 12 months.',
            input: false,
            selected: false,
            count: 0,
            cost: 350,
        },
        {
            title: 'Diagnosis',
            detail: 'Please specify issue. Aircon is problematic and needs deeper diagnosis. Rate includes inspection fee only.',
            input: true,
            placeholder: 'Describe the problem (e.g leaking, noise, smell, cannot power on etc)',
            inputTxt: '',
            selected: false,
            count: 0,
        },
    ])

    const [buildings, setBuildings] = useState([
        { title: 'Landed', selected: false },
        { title: 'Condo / Apartment', selected: false },
        { title: 'Office / Commercial / Others', selected: false },
    ])

    const [times, setTimes] = useState([
        { title: 'Morning (09:00 - 11:00)', selected: false },
        { title: 'Lunch Time (11:00 - 13:00)', selected: false },
        { title: 'Early Afternoon (13:00 - 15:00)', selected: false },
        { title: 'Late Afternoon (15:00 - 17:00)', selected: false },
    ])

    const [preferredDate, setPreferredDate] = useState(new Date());
    const [details, setDetails] = useState('');

    useEffect(async () => {
        let airconJobState = localStorage.getItem('airconJobState')
        if (airconJobState != null) {
            airconJobState = JSON.parse(airconJobState)
            setServices(airconJobState.services)
            setBuildings(airconJobState.buildings)
            setTimes(airconJobState.times)
            setPreferredDate(moment(airconJobState.preferredDate, 'DD MMM YYYY').toDate())
            setDetails(airconJobState.details)
        }
    }, []);

    const updateServicesCount = (index, count) => {
        const arr = services.map((item, i) => {
            if (i == index) {
                return { ...item, count: count };
            }
            else {
                return item;
            }
        });
        setServices(arr)
    };

    const updateServicesInput = (index, inputTxt) => {
        const arr = services.map((item, i) => {
            if (i == index) {
                return { ...item, inputTxt: inputTxt };
            }
            else {
                return item;
            }
        });
        setServices(arr)
    };

    const saveInfo = () => {
        let servicesSelected = ''
        for (let i = 0; i < services.length; i++) {
            if (services[i].count > 0) {
                if (services[i].input) {
                    servicesSelected = servicesSelected + services[i].inputTxt + ':' + services[i].count + ', '
                }
                else {
                    servicesSelected = servicesSelected + services[i].title + ':' + services[i].count + ', '
                }
            }
        }
        servicesSelected = servicesSelected.substring(0, servicesSelected.length - 2)

        let buildingsSelected = ''
        for (let i = 0; i < buildings.length; i++) {
            if (buildings[i].selected) {
                buildingsSelected = buildingsSelected + buildings[i].title + ', '
            }
        }
        buildingsSelected = buildingsSelected.substring(0, buildingsSelected.length - 2)

        // times
        let timesSelected = ''
        for (let i = 0; i < times.length; i++) {
            if (times[i].selected) {
                timesSelected = timesSelected + times[i].title + ','
            }
        }
        timesSelected = timesSelected.substring(0, timesSelected.length - 1)

        // date
        let preferredDateSelected = true
        // if (moment(preferredDate) > moment()) {
        //     preferredDateSelected = true
        // }

        if (servicesSelected === '' || buildingsSelected === '' || timesSelected === '') {
            showToast('error', 'Please fill complete form')
            return
        }
        if (!preferredDateSelected) {
            showToast('error', 'Invalid preferred date!')
            return
        }

        // store state
        let airconJobState = {
            services: services,
            buildings: buildings,
            preferredDate: moment(preferredDate).format('DD MMM YYYY'),
            times: times,
            details: details,
        }
        localStorage.setItem('airconJobState', JSON.stringify(airconJobState))

        // send job details
        let airconJob = {
            questions: [
                { q: questions[0], a: servicesSelected },
                { q: questions[1], a: buildingsSelected },
            ],
            timesSelected: timesSelected,
            preferredDate: moment(preferredDate).format('DD MMM YYYY'),
            details: details,
            budget: getTotalCost()
        }
        history.push({
            pathname: '/quote-contact-details',
            state: { job: airconJob, jobType: 'aircon' }
        })
    };

    const getTotalCost = () => {
        let cost = 0
        for (let i = 0; i < services.length; i++) {
            const item = services[i];
            if (!item.cost || item.cost == 0) {
                continue
            }
            cost = cost + (item.count * item.cost)
        }
        return cost
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1, paddingBottom: 30 }}>

            <div className='login quote-top' style={{ paddingLeft: 0, paddingRight: 0, marginTop: 30 }}>

                <div className='home-label' style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 20 }}>
                    Aircon
                </div>

                <div className='my-line' />

                <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>

                    <div style={{ fontSize: 16 }}>
                        Questions marked <span style={{ color: 'red' }}>*</span> are required<br />
                        <span style={{ fontWeight: 'bold' }}>How it works:</span><br />
                        1. Select your required service below, tell us as much as you can about what you need.<br />
                        2. Upon submission of the request, our Pros will submit their quotes.<br />
                    </div>

                    <div className='option-label' style={{ marginTop: 15 }}>
                        <span style={{ color: 'red' }}>*</span> 1) {questions[0]}
                    </div>

                    {services.map((item, index) => {
                        return (
                            <div style={{ marginTop: 10, padding: 15, border: '1px solid lightgray', borderRadius: 10, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                                    {item.title}
                                </div>
                                <div style={{ fontSize: 14, marginTop: 5 }}>
                                    {item.detail}
                                </div>
                                {item.input &&
                                    <FormGroup style={{ marginTop: 10 }}>
                                        <Input
                                            style={{ fontSize: 16 }}
                                            type='textarea'
                                            placeholder={item.placeholder}
                                            value={item.inputTxt}
                                            onChange={(event) => {
                                                updateServicesInput(index, event.target.value)
                                            }} />
                                    </FormGroup>
                                }
                                <div style={{ marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {item.cost &&
                                        <div style={{ fontSize: 14, color: 'gray', marginRight: 10 }}>
                                            RM{item.cost} / unit
                                        </div>
                                    }
                                    <button
                                        onClick={() => {
                                            let count = item.count - 1
                                            if (count < 0)
                                                count = 0
                                            updateServicesCount(index, count)
                                        }}
                                        style={{ backgroundColor: 'transparent', fontSize: 18, fontWeight: 'bold', padding: 0, width: 30, height: 30, border: '1px solid ' + Colors.theme1, color: Colors.theme1, alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                        -
                                    </button>
                                    <div style={{ marginLeft: 10, marginRight: 10, fontSize: 18 }}>
                                        {item.count}
                                    </div>
                                    <button
                                        onClick={() => {
                                            updateServicesCount(index, item.count + 1)
                                        }}
                                        style={{ backgroundColor: 'transparent', fontSize: 18, fontWeight: 'bold', padding: 0, width: 30, height: 30, border: '1px solid ' + Colors.theme1, color: Colors.theme1, alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                        +
                                    </button>
                                </div>
                            </div>
                        )
                    })}

                    <div className='option-label' style={{ marginTop: 20 }}>
                        <span style={{ color: 'red' }}>*</span> 2) {questions[1]}
                    </div>
                    {showOption('', buildings, setBuildings, false, true)}


                    <div className='option-label'>
                        <span style={{ color: 'red' }}>*</span> 3) What date and time are you available?
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <DatePicker
                            selected={preferredDate}
                            onChange={(date) => setPreferredDate(date)}
                            customInput={
                                <FormGroup>
                                    <Input
                                        minDate={moment()}
                                        style={{ fontSize: 18 }}
                                        type='text'
                                        placeholder='Choose Date'
                                        value={moment(preferredDate).format('DD MMM YYYY')}
                                        onChange={(event) => setPreferredDate(event.target.value)} />
                                </FormGroup>
                            } />
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 0 }}>
                        {times.map((item, index) => {
                            return (
                                <button
                                    onClick={() => {
                                        const newList = times.map((item_) => {
                                            if (item_.title === item.title)
                                                return { ...item_, selected: !item.selected };
                                            else
                                                return { ...item_, selected: false }; // set others false
                                        });
                                        setTimes(newList)
                                    }}
                                    className={item.selected ? 'tab-item2-sel' : 'tab-item2'}>
                                    {item.title}
                                </button>
                            )
                        })}
                    </div>

                    <div style={{ marginTop: 20, padding: 15, border: '1px solid lightgray', borderRadius: 10, display: 'flex', flexDirection: 'column', backgroundColor: '#fafafa' }}>
                        {services.map((item, index) => {
                            if (!item.cost || item.count == 0) {
                                return null
                            }
                            return (
                                <div style={{ fontSize: 16 }}>
                                    {item.title} = {item.count} x {item.cost} = RM{item.count * item.cost}
                                </div>
                            )
                        })}
                        {getTotalCost() > 0 && <div style={{ width: '100%', height: 1, backgroundColor: 'lightgray', marginTop: 10, marginBottom: 8 }} />}
                        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                            Total Cost = RM{getTotalCost()}
                        </div>
                    </div>

                    <button
                        onClick={saveInfo}
                        className='login-btn'
                        style={{ marginTop: 20, fontSize: 17, width: '100%' }}>
                        Next
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default AirconQuote; 
import React from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import Api from './api/api'

const VendorTerms = () => {
    const history = useHistory()

    return (
        <div className='main'>

            <div className='login'>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}

                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    Payment Terms for Vendors
                </div>

                <div className="form" style={{ marginTop: 20 }}>

                    <div style={{ fontWeight: 'bold' }}>Pricing:</div>
                    <div style={{ fontSize: 16 }}>
                        For each job completed, a service charge of 18% to 23% will apply on the total agreed-upon amount.
                    </div>
                    <div style={{ fontWeight: 'bold', marginTop: 15 }}>Payment Process:</div>
                    <div style={{ fontSize: 16 }}>
                        Once a job is successfully completed, we will deduct the applicable service charge (18% to 23%) from the total amount and release the remaining balance to the vendor promptly.
                    </div>

                </div>

            </div>
        </div>
    );
}

export default VendorTerms; 
import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, FormFeedback, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import showToast from './util/toast'
import { Actions } from './redux/Actions'
import { useDispatch } from 'react-redux';

const Signup = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    let type = localStorage.getItem('userType') === 'vendor' ? 'vendor' : 'customer'
    if (location.state)
        type = location.state.type
    console.log('type: ' + type);

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const [terms, setTerms] = useState(false)

    const [userType, setUserType] = useState(type);

    const [error, setError] = useState('');

    const [loader, setLoader] = useState(false);

    const signupUser = () => {
        if (!fname || !lname || !email || !password || !cpassword) {
            setError('All fields are required');
            return
        }
        if (!(/^\S+@\S+\.\S+$/.test(email))) {
            setError('Invalid email');
            return
        }
        if (password.length < 6) {
            setError('Password must be atleast 6 letters')
            return
        }
        if (cpassword !== password) {
            setError('Passwords do not match')
            return
        }
        if (userType === 'vendor' && !terms){
            setError('You did not accept the terms.')
            return
        }

        setLoader(true)
        Api.createUser(fname, lname, email, '', userType, '', password,
            (response) => {
                setLoader(false)
                console.log('signup: '+JSON.stringify(response));
                if (response.status === 'success') {
                    localStorage.setItem('user', JSON.stringify(response.user))
                    dispatch(Actions.setUser(response.user))
                    if (userType === 'customer')
                        history.push('/customer-dashboard')
                    else
                        history.push('/edit-vendor-profile')
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Request failed')
            });
    };

    return (
        <div className='main'>

            <div className='login'>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}
                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    Create a New Account
                </div>

                <div className="form">
                    <FormGroup
                        style={{ marginTop: 20 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='First Name'
                            value={fname}
                            onChange={(event) => setFname(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='Last Name'
                            value={lname}
                            onChange={(event) => setLname(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={(event) => setPassword(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='password'
                            placeholder='Confirm Password'
                            value={cpassword}
                            onChange={(event) => setCpassword(event.target.value)} />
                    </FormGroup>

                    {/* <MyError showError={showError} error={error} /> */}

                    <div style={{ marginTop: 15, display: 'flex', flexDirection: 'column', alignSelf: 'center', fontSize: 18, alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: 20 }}>Signup as a</div>
                            <input
                                id="customer"
                                name="fav_language"
                                type="radio"
                                value="customer"
                                checked={userType === "customer"}
                                onChange={(e) => {
                                    setUserType(e.target.value)
                                    localStorage.setItem('userType', 'customer')
                                }} />
                            <label style={{ marginLeft: 10 }} for="customer">Employer</label>
                            <input
                                style={{ marginLeft: 20 }}
                                id="vendor"
                                name="fav_language"
                                type="radio"
                                value="vendor"
                                checked={userType === "vendor"}
                                onChange={(e) => {
                                    setUserType(e.target.value)
                                    localStorage.setItem('userType', 'vendor')
                                }} />
                            <label style={{ marginLeft: 10 }} for="vendor">Service Provider</label>
                        </div>
                    </div>

                    {error &&
                        <div style={{ color: 'red', textAlign: 'center', marginTop: 10 }}>
                            {error}
                        </div>
                    }

                    {userType === 'vendor' &&
                        <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
                            <input
                                style={{ width: 20, height: 20 }}
                                type="checkbox"
                                checked={terms}
                                name="terms"
                                onChange={(e) => {
                                    setTerms(e.target.checked)
                                }} />
                            <div style={{ marginLeft: 10, fontSize: 16 }}>
                                I agree with <a href='/vendor-terms'>Terms & Conditions</a>.
                            </div>
                        </div>
                    }

                    <button
                        className='login-btn'
                        style={{ marginTop: 15 }}
                        onClick={signupUser}>
                        Signup
                    </button>

                    <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: 16 }}>Already a registered user?</div>
                        <button
                            onClick={() => {
                                history.push('/login')
                            }}
                            style={{ fontSize: 16, marginLeft: 0, border: '0px', backgroundColor: 'transparent', color: Colors.theme1 }}>
                            Please login here
                        </button>
                    </div>

                    <div style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: 16 }}>View our Privacy Policy</div>
                        <button
                            onClick={() => {
                                //
                            }}
                            style={{ fontSize: 16, marginLeft: 0, border: '0px', backgroundColor: 'transparent', color: Colors.theme1 }}>
                            here
                        </button>
                    </div>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default Signup; 
import React, { useState } from 'react';
import '../../App.css';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import MyLoader from '.././util/loader'
import MyError from '.././util/error'
import Api from '.././api/api'
import showToast from '.././util/toast'
import { Actions } from '.././redux/Actions'
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux';

const EditProfile = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    let user = useSelector(state => state.MyReducer.user);
    if(user == null && localStorage.getItem('user') != null){
        user = JSON.parse(localStorage.getItem('user'))
    }

    const [fname, setFname] = useState(user.first_name)
    const [lname, setLname] = useState(user.last_name)
    const [email, setEmail] = useState(user.email)
    const [phone, setPhone] = useState(user.phone)
    const [company, setCompany] = useState(user.company)
    const [location, setLocation] = useState(user.location)
    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')

    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const saveChanges = () => {
        if (password !== '') {
            if (password.length < 6) {
                setError('Password must be atleast 6 letters')
                return
            }
            if (cpassword !== password) {
                setError('Passwords do not match')
                return
            }
            if (oldPassword === '') {
                setError('Old password required')
                return
            }
        }
        setError('')
        setLoader(true)
        Api.updateProfile(fname, lname, phone, company, location, oldPassword, password,
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    localStorage.setItem('user', JSON.stringify(response.user))
                    dispatch(Actions.setUser(response.user))
                    showToast('success', 'Profile updated!')
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Request failed')
            });

    };

    return (
        <div className='main'>

            <div className='login' style={{overflow:'scroll'}}>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}
                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    Edit Profile
                </div>

                <div className="form">
                    <FormGroup
                        style={{ marginTop: 20 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='First Name'
                            value={fname}
                            onChange={(event) => setFname(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='Last Name'
                            value={lname}
                            onChange={(event) => setLname(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            disabled
                            style={{ fontSize: 18 }}
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </FormGroup>
                    <FormGroup style={{ marginTop: 10 }}>
                        <PhoneInput
                            inputStyle={{ height: 45, fontSize: 16, width: '100%' }}
                            country={'my'}
                            value={phone}
                            onChange={phone => setPhone(phone)}
                        />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            disabled
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='Company (Optional)'
                            value={company}
                            onChange={(event) => setCompany(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            disabled
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='Location (Optional)'
                            value={location}
                            onChange={(event) => setLocation(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='password'
                            placeholder='New Password'
                            value={password}
                            onChange={(event) => setPassword(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='password'
                            placeholder='Confirm New Password'
                            value={cpassword}
                            onChange={(event) => setCpassword(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='password'
                            placeholder='Current Password'
                            value={oldPassword}
                            onChange={(event) => setOldPassword(event.target.value)} />
                    </FormGroup>

                    {error &&
                        <div style={{ color: 'red', textAlign: 'center', marginTop: 10 }}>
                            {error}
                        </div>
                    }

                    <button
                        className='login-btn'
                        style={{ marginTop: 15 }}
                        onClick={saveChanges}>
                        Save Changes
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default EditProfile;
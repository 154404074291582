import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, FormFeedback, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import showToast from './util/toast'
import { Actions } from './redux/Actions'
import { useDispatch } from 'react-redux';

const ContactForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')

    const [error, setError] = useState('');

    const [loader, setLoader] = useState(false);

    const submitQuery = () => {
        if (!name || !email || !msg) {
            setError('All fields are required');
            return
        }
        if (!(/^\S+@\S+\.\S+$/.test(email))) {
            setError('Invalid email');
            return
        }

        setLoader(true)
        Api.submitQuery(name, email, msg,
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    showToast('success', 'Query sent! Admin will respond via email.')
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Some network issue! Please try later')
            });
    };

    return (
        <div className='main'>

            <div className='login'>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}
                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    Contact Us
                </div>

                <div className="form">
                    <FormGroup
                        style={{ marginTop: 20 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='Name'
                            value={name}
                            onChange={(event) => setName(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='textarea'
                            placeholder='Enter Message ...'
                            value={msg}
                            onChange={(event) => setMsg(event.target.value)} />
                    </FormGroup>

                    {/* <MyError showError={showError} error={error} /> */}

                    {error &&
                        <div style={{ color: 'red', textAlign: 'center', marginTop: 10 }}>
                            {error}
                        </div>
                    }

                    <button
                        className='login-btn'
                        style={{ marginTop: 15 }}
                        onClick={submitQuery}>
                        Submit
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default ContactForm; 
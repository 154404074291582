import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Colors from '../colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import logo from '../../assets/pros_logo.png';
import pros_banner from '../../assets/pros_banner.png';
import plumber from '../../assets/plumber.png';
import paint from '../../assets/paint.png';
import aircon from '../../assets/aircon.png';
import mover from '../../assets/mover.png';
import worker from '../../assets/worker.jpg';
import search_icon from '../../assets/search_icon.png';
import heart from '../../assets/heart.png';
import heart_fill from '../../assets/heart_fill.png';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';

const Vendors = () => {
    const history = useHistory()
    const [loader, setLoader] = useState(false);

    const { category } = useParams()

    const [cats, setCats] = useState([
        { id: 1, title: 'Plumber', icon: plumber },
        { id: 2, title: 'House Painting', icon: paint },
        { id: 3, title: 'Aircon Servicing', icon: aircon },
        { id: 4, title: 'Domestic Mover', icon: mover },
    ])
    const [states, setStates] = useState([
        { id: 1, title: 'State 1' },
        { id: 2, title: 'State 2' },
    ])
    const [cities, setCities] = useState([
        { id: 1, title: 'City 1' },
        { id: 2, title: 'City 2' },
    ])
    const [ratings, setRatings] = useState([
        { id: 1, title: '5 stars only' },
        { id: 2, title: '4 stars and above' },
        { id: 3, title: 'All' },
    ])
    const [pageResults, setPageResults] = useState([
        { id: 1, title: '10' },
        { id: 2, title: '25' },
        { id: 3, title: '50' },
    ])

    const [vendors, setVendors] = useState([{}, {}, {}, {}, {}, {}])

    const [catSel, setCatSel] = useState(cats[0].id);
    const [stateSel, setStateSel] = useState('');
    const [citySel, setCitySel] = useState('');
    const [ratingSel, setRatingSel] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [pageResultsSel, setPageResultsSel] = useState(pageResults[0].id);

    return (
        <div style={{backgroundColor:Colors.background}}>

            <div style={{ backgroundColor: 'white', marginTop: 0, paddingBottom: 20, paddingTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>
                    <div className='my-title'>
                        The Best {category}s in Malaysia
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 0, paddingBottom: 10, paddingTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>

                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel style={{ fontSize: 18 }} id="demo-select-small">Service</InputLabel>
                            <Select
                                style={{ fontSize: 18 }}
                                labelId="demo-select-small"
                                id="demo-simple-select"
                                value={catSel}
                                label="Service"
                                onChange={(event) => {
                                    setCatSel(event.target.value)
                                }}>
                                {cats.map((item, index) => {
                                    return (
                                        <MenuItem value={item.id}>{item.title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel style={{ fontSize: 18 }} id="demo-select-small">State</InputLabel>
                            <Select
                                style={{ fontSize: 18 }}
                                labelId="demo-select-small"
                                id="demo-simple-select"
                                value={stateSel}
                                label="State"
                                onChange={(event) => {
                                    setStateSel(event.target.value)
                                }}>
                                {states.map((item, index) => {
                                    return (
                                        <MenuItem value={item.id}>{item.title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel style={{ fontSize: 18 }} id="demo-select-small">City</InputLabel>
                            <Select
                                style={{ fontSize: 18 }}
                                labelId="demo-select-small"
                                id="demo-simple-select"
                                value={citySel}
                                label="City"
                                onChange={(event) => {
                                    setCitySel(event.target.value)
                                }}>
                                {cities.map((item, index) => {
                                    return (
                                        <MenuItem value={item.id}>{item.title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel style={{ fontSize: 18 }} id="demo-select-small">Rating</InputLabel>
                            <Select
                                style={{ fontSize: 18 }}
                                labelId="demo-select-small"
                                id="demo-simple-select"
                                value={ratingSel}
                                label="Rating"
                                onChange={(event) => {
                                    setRatingSel(event.target.value)
                                }}>
                                {ratings.map((item, index) => {
                                    return (
                                        <MenuItem value={item.id}>{item.title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormGroup
                            style={{ display: 'flex', backgroundColor: 'white', borderRadius: 5, border: '1px solid lightgray', alignItems: 'center' }}>
                            <img
                                style={{ width: 25, height: 25, marginLeft: 10 }}
                                src={search_icon} />
                            <Input
                                style={{ fontSize: 18, borderRadius: 5, borderWidth: 0, paddingLeft: 5 }}
                                type='text'
                                placeholder='Search Pro By Name'
                                value={searchTxt}
                                onChange={(event) => setSearchTxt(event.target.value)} />
                        </FormGroup>

                        <div
                            className='vendor-results'
                            style={{ display: 'flex', flex: 1 }}>
                            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                                <InputLabel style={{ fontSize: 18 }} id="demo-select-small">Results per page</InputLabel>
                                <Select
                                    style={{ fontSize: 18 }}
                                    labelId="demo-select-small"
                                    id="demo-simple-select"
                                    value={pageResultsSel}
                                    label="Results per page"
                                    onChange={(event) => {
                                        setPageResultsSel(event.target.value)
                                    }}>
                                    {pageResults.map((item, index) => {
                                        return (
                                            <MenuItem value={item.id}>{item.title}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                    </div>

                </div>
            </div>

            <div style={{ backgroundColor: 'white', marginTop: 0, paddingBottom: 20, paddingTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>
                    <div style={{ fontSize: 14, color: 'gray' }}>
                        1-10 of 500
                    </div>

                    {vendors.map((item, index) => {
                        return (
                            <div>
                                <div 
                                    className='vendor-item'
                                    style={{ display: 'flex', flexWrap:'wrap', marginTop: 10 }}>
                                    <img
                                        className='vendor-img'
                                        style={{ borderRadius: 10, marginLeft: 0, marginTop: 0 }}
                                        src={worker} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='vendor-info'>
                                            <img
                                                className='vendor-logo'
                                                style={{ marginLeft: 0, marginTop: 0 }}
                                                src={worker} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontSize: 17, marginLeft: 10, fontWeight: 'bold' }}>
                                                    Aka Services
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <Rating
                                                        style={{ marginLeft: 8 }}
                                                        size='small'
                                                        name="simple-controlled"
                                                        value={5}
                                                        onChange={(event, newValue) => {
                                                            // setValue(newValue);
                                                        }}
                                                    />
                                                    <p style={{ fontSize: 16, marginTop: 0 }}>5</p>
                                                    <p style={{ marginLeft: 5, fontSize: 16, marginTop: 0, color: 'gray' }}>(20 Reviews)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ fontSize: 17, marginTop: 15 }}>
                                            "He did a great job!"
                                        </div>
                                        <div style={{ fontSize: 17, marginTop: 8, color: 'gray' }}>
                                            Ali Ahmed, 20 Nov 2022, Selangor
                                        </div>
                                        <button
                                            onClick={()=>{
                                                history.push('/vendor/1234')
                                            }}
                                            className='login-btn vendor-view-profile'
                                            style={{ width: 200, height: 40, fontSize: 17, marginTop: 15, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                                            View Profile
                                        </button>
                                    </div>

                                    <div 
                                        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                                        className='vendor-btns'>
                                        <button
                                            className='login-btn'
                                            style={{ marginTop: 0, width: 200, fontSize: 17 }}>
                                            Book this Vendor
                                        </button>
                                        <button
                                            onClick={() => {
                                                //
                                            }}
                                            className='save-btn'
                                            style={{ borderWidth: 0, backgroundColor: 'transparent', marginTop: 8, display: 'flex', width: 200, alignItems: 'center', justifyContent: 'center' }}>
                                            <img
                                                style={{ width: 30, height: 30 }}
                                                src={heart} />
                                            <div style={{ fontSize: 16, marginLeft: 5, color:Colors.gray, fontWeight:'bold' }}>
                                                Save
                                            </div>
                                        </button>
                                    </div>
                                    
                                </div>
                                <div style={{ width: '100%', height: 1, backgroundColor: 'lightgray', marginTop: 20, marginBottom: 20 }} />
                            </div>
                        )
                    })}

                </div>

            </div>

        </div>
    );
}

export default Vendors; 
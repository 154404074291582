import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';

const MoverSection6 = (props) => {
    const history = useHistory()
    let moverJobInfo = { ...props.moverJobInfo }

    const [other, setOther] = useState('');

    const [options, setOptions] = useState([
        { title: 'Packing and unpacking', checked: false },
        { title: 'Large furniture or items', checked: false },
        { title: 'Furniture disassembly and assembly', checked: false },
        { title: 'Storage', checked: false },
    ])

    useEffect(() => {
        let a = moverJobInfo.questions[props.index].a
        console.log('a: ' + a);
        if (a !== '' && a != null) {
            const arr = options.map((item) => {
                if (a.includes(item.title)) {
                    return { ...item, checked: true };
                }
                else {
                    return item;
                }
            });
            setOptions(arr)
        }
        let other = localStorage.getItem('other')
        setOther(other)
    }, [])

    const updateAns = (propsIndex, options, other) => {
        let values = ''
        for (let i = 0; i < options.length; i++) {
            if (options[i].checked) {
                values = values + options[i].title + ', '
            }
        }
        if (other !== '')
            values = values + other + ', '
        values = values.substring(0, values.length - 2)

        let jobInfo = {
            ...moverJobInfo,
            questions: {
                ...moverJobInfo.questions,
                [propsIndex]: {
                    ...moverJobInfo.questions[propsIndex],
                    a: values
                }
            }
        }
        props.dispatch(props.actions.setMoverJobInfo(jobInfo))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index].q} (optional)
            </div>
            <div
                style={{ color: 'gray', fontSize: 14, alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Additional charges may apply.
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10 }}>

                {options.map((item, index) => {
                    return (
                        <div key={index.toString()} style={{ display: 'flex', marginTop: (index == 0) ? 0 : 15, fontSize: 16, alignItems: 'center' }}>
                            <input
                                style={{ width: 20, height: 20 }}
                                type="checkbox"
                                checked={item.checked}
                                id={index.toString()}
                                value={index.toString()}
                                name="fav_language"
                                onChange={(e) => {
                                    let arr = options.map((item_, i) => {
                                        if (i == index) {
                                            return { ...item_, checked: e.target.checked };
                                        }
                                        else {
                                            return item_;
                                        }
                                    });
                                    setOptions(arr)
                                    updateAns(props.index, arr, other)
                                }} />
                            <label style={{ marginLeft: 10 }} for={index.toString()}>
                                {item.title}
                            </label>
                        </div>
                    )
                })}

                <div style={{ display: 'flex', marginTop: 0, fontSize: 16, alignItems: 'center' }}>
                    <input
                        style={{ width: 20, height: 20 }}
                        type='checkbox'
                        id="option5"
                        name="fav_language"
                        value="option5" />
                    <FormGroup style={{ marginLeft: 10, marginTop: 15 }}>
                        <Input
                            style={{ fontSize: 18, flex: 1, minWidth: 300 }}
                            type='text'
                            placeholder='Other'
                            value={other}
                            onChange={(event) => {
                                console.log('onChange: ' + event.target.value);
                                localStorage.setItem('other', event.target.value)
                                setOther(event.target.value)
                                updateAns(props.index, options, event.target.value)
                            }} />
                    </FormGroup>
                </div>

            </div>

        </div>
    )
}

export default MoverSection6; 
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Colors from '../colors'
import plumber from '../../assets/plumber.png';
import paint from '../../assets/paint.png';
import aircon from '../../assets/aircon.png';
import mover from '../../assets/mover.png';
import worker from '../../assets/worker.jpg';
import marker from '../../assets/marker.png';
import calendar from '../../assets/calendar.png';
import arrow_right from '../../assets/arrow_right.png';
import bag from '../../assets/bag.png';
import Card from '@mui/material/Card';
import Api from '../api/api'
import showToast from '../util/toast';
import MyLoader from '../util/loader';

import { useDispatch, useSelector } from 'react-redux';

const CustomerDashboard = () => {
    const history = useHistory()
    const [loader, setLoader] = useState(false);

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const acceptedIndex = useSelector(state => state.MyReducer.acceptedIndex);
    console.log('acceptedIndex: ' + acceptedIndex);

    const flag = false;

    const { category } = useParams()

    const [cats, setCats] = useState([
        { id: 1, title: 'Plumber', icon: plumber },
        { id: 2, title: 'House Painting', icon: paint },
        { id: 3, title: 'Aircon Servicing', icon: aircon },
        { id: 4, title: 'Domestic Mover', icon: mover },
    ])

    const [jobs, setJobs] = useState([
        // {
        //     title: 'Mobile Apps Developer',
        //     location: 'Sialkot, Pakistan',
        //     preferred_date: '12 Nov 2024 (Morning Time)',
        //     status: 'Open'
        // },
        // {
        //     title: 'Web Designer & Frontend Developer',
        //     location: 'Lahore, Punjab',
        //     preferred_date: '12 Nov 2024 (Morning Time)',
        //     status: 'Open'
        // }
    ]) 
    const [filter, setFilter] = useState('All'); // Default filter
    const [filteredJobs, setFilteredJobs] = useState(jobs);

    const handleFilterChange = (e) => {
        const selectedStatus = e.target.value;
        setFilter(selectedStatus);
        if (selectedStatus === 'All') {
            setFilteredJobs(jobs);
        } else {
            const filtered = jobs.filter((job) => job.status.toLowerCase() === selectedStatus.toLowerCase());
            setFilteredJobs(filtered);
        }
    }

    useEffect(() => {
        loadJobs()
    }, [])

    const loadJobs = () => {
        let customerJobsKey = 'customerJobs' + user.id
        let cacheJobs = localStorage.getItem(customerJobsKey)
        let showLoader = true
        if (cacheJobs != null) {
            setJobs(JSON.parse(cacheJobs))
            setFilteredJobs(JSON.parse(cacheJobs));
            showLoader = false
        }
        if (showLoader) setLoader(true)
        Api.myJobs(user.id,
            (response) => {
                // console.log('jobs: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    if (response.jobs != null) {
                        setJobs(response.jobs)
                        setFilteredJobs(response.jobs);
                        localStorage.setItem(customerJobsKey, JSON.stringify(response.jobs))
                    }
                }
                setLoader(false)
            },
            (error) => {
                setLoader(false)
            })
    }

    const getIcon = (job) => {
        let icon = bag
        return icon
    };

    const getJobStatus = (job) => {
        return job.status
    };

    const getJobType = (job) => {
        return 'Test'
    };

    return (
        <div style={{ backgroundColor: Colors.background, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 20 }}>

            <div
                className='home-panel'
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>

                <div style={{ width: '100%', paddingBottom: 20, paddingTop: 0, display: 'flex', justifyContent: 'center' }}>

                    <div
                        className='hide-on-mobile'
                        style={{ flex: 1, minWidth: 200, backgroundColor: 'white', padding: 0 }}>

                        <div style={{ padding: 0, fontSize: 18, width: '100%' }}>
                            <button
                                onClick={() => {
                                    // history.push('/dashboard')
                                }}
                                style={{ display: 'flex', alignItems: 'center', padding: 10, width: '100%', textAlign: 'start' }}
                                className='hover-effect1'>
                                <div style={{ marginLeft: 5 }}>
                                    My Jobs
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}>
                                    <img
                                        style={{ width: 20, height: 20, objectFit: 'contain' }}
                                        src={arrow_right} />
                                </div>
                            </button>

                            <div className='my-line' style={{ marginTop: 0, marginBottom: 0, backgroundColor: Colors.background }} />

                            <button
                                onClick={() => {
                                    history.push('/favorites')
                                }}
                                className='hover-effect1'
                                style={{ display: 'flex', alignItems: 'center', padding: 10, width: '100%', textAlign: 'start' }}>
                                <div style={{ marginLeft: 5 }}>
                                    My Favorites
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}>
                                    <img
                                        style={{ width: 20, height: 20, objectFit: 'contain' }}
                                        src={arrow_right} />
                                </div>
                            </button>

                            <div className='my-line' style={{ marginTop: 0, marginBottom: 0, backgroundColor: Colors.background }} />

                            <button
                                onClick={() => {
                                    history.push('/post-job')
                                }}
                                className='hover-effect1'
                                style={{ display: 'flex', alignItems: 'center', padding: 10, width: '100%', textAlign: 'start' }}>
                                <div style={{ marginLeft: 5 }}>
                                    Post a Job
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}>
                                    <img
                                        style={{ width: 20, height: 20, objectFit: 'contain' }}
                                        src={arrow_right} />
                                </div>
                            </button>

                            <div className='my-line' style={{ marginTop: 0, marginBottom: 0, backgroundColor: Colors.background }} />

                            <button
                                onClick={() => {
                                    history.push('/edit-profile')
                                }}
                                className='hover-effect1'
                                style={{ display: 'flex', alignItems: 'center', padding: 10, width: '100%', textAlign: 'start' }}>
                                <div style={{ marginLeft: 5 }}>
                                    Edit Profile
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}>
                                    <img
                                        style={{ width: 20, height: 20, objectFit: 'contain' }}
                                        src={arrow_right} />
                                </div>
                            </button>

                            <div className='my-line' style={{ marginTop: 0, marginBottom: 0, backgroundColor: Colors.background }} />

                            <div style={{ color: 'gray', marginLeft: 18, marginTop: 15, fontSize: 16 }}>
                                Contact Us
                            </div>

                            <div style={{ color: 'gray', fontSize: 14, marginTop: 5, marginLeft: 18, color: Colors.theme1 }}>
                                <span style={{ color: 'black' }}>Email:</span> ddsurg@ddsurgical.com
                            </div>
                            {/* <div style={{ color: 'gray', fontSize: 14, marginLeft: 18, color: Colors.theme1 }}>
                                <span style={{ color: 'black' }}>Phone:</span> +61202222222
                            </div> */}
                        </div>


                    </div>

                    <div
                        style={{ flex: 3, minWidth: 275, alignSelf: 'center', padding: 20, backgroundColor: 'white', height: '90vh', overflow: 'scroll', marginLeft: 20 }}>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                            <div className='my-title' style={{ fontSize: 24, }}>
                                My Jobs
                            </div>
                            <select id="filter" value={filter} onChange={handleFilterChange} style={{ margin: '10px' }}>
                                <option value="All">All</option>
                                <option value="Open">Open</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </div>

                        {jobs.length == 0 &&
                            <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ color: 'gray', fontSize: 18 }}>
                                    No Jobs Posted
                                </div>
                                <button
                                    onClick={() => {
                                        history.push('/post-job')
                                    }}
                                    style={{ marginTop: 10, border: '1px solid ' + Colors.theme1, backgroundColor: 'transparent', width: 200, height: 45, borderRadius: 5 }}>
                                    <div style={{ color: Colors.theme1, fontSize: 16, marginLeft: 5 }}>
                                        Post a New Job
                                    </div>
                                </button>
                            </div>
                        }

                        {filteredJobs.map((item, index) => {
                            return (
                                <Card
                                    key={index.toString()}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/project-details',
                                            state: { job: item }
                                        })
                                    }}
                                    sx={{ marginBottom: 2, backgroundColor: Colors.theme3 }}>

                                    <button
                                        className='card-hover'
                                        style={{ border: '0px', width: '100%', padding: 0, display: 'flex', flexDirection: 'column' }}>

                                        <div
                                            className='job-item'
                                            style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%', marginBottom: -19, alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>

                                            <div style={{ padding: 20, marginRight: 15, marginLeft: 10, marginTop: 2, borderRadius: 50, backgroundColor: 'white', marginBottom: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img
                                                    style={{ width: 45, height: 45 }}
                                                    src={getIcon(item)} />
                                            </div>

                                            <div
                                                className='margin-left-mobile'
                                                style={{ display: 'flex', minWidth: 300, flexDirection: 'column', flex: 3, marginTop: 0, paddingTop: 10, paddingBottom: 10 }}>
                                                <div className='vendor-info'>
                                                    <div style={{ fontSize: 18, marginLeft: 0, fontWeight: 'bold' }}>
                                                        {item.title}
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: -6, marginTop: 5 }}>
                                                    <img
                                                        style={{ width: 30, height: 30, objectFit: 'contain' }}
                                                        src={marker} />
                                                    <div style={{ fontSize: 16, marginLeft: 5, color: 'gray', textAlign: 'start' }}>
                                                        Destination: {item.location}, {item.country}
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', flex: 1, alignItems: 'center', marginTop: 10 }}>
                                                    <img
                                                        style={{ width: 20, height: 20, objectFit: 'contain' }}
                                                        src={calendar} />
                                                    <div style={{ fontSize: 16, marginLeft: 10, color: 'gray' }}>
                                                        Work Date: {item.preferred_date}
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 15 }}
                                                className='vendor-btns'>
                                                {
                                                    <div style={{ color: 'gray', fontSize: 16, fontWeight: 'bold', marginLeft: 5, backgroundColor: 'white', borderRadius: 25, padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                                                        {getJobStatus(item)}
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <div style={{ marginTop: 20, padding: 15, backgroundColor: 'white', width: '100%' }}>

                                            <div
                                                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                                                className='vendor-btns'>
                                                <button
                                                    style={{ border: '1px solid ' + Colors.theme1, backgroundColor: 'transparent', width: 150, height: 35, borderRadius: 5 }}>
                                                    <div style={{ color: Colors.theme1, fontSize: 16, marginLeft: 5 }}>
                                                        VIEW DETAILS
                                                    </div>
                                                </button>
                                            </div>

                                        </div>

                                    </button>

                                </Card>
                            )

                        })}

                    </div>

                </div>

            </div>

            <MyLoader loader={loader} marginTop={-10} />

        </div >
    );
}

export default CustomerDashboard; 
export const plumberQuestions = [
    'What is the service do you need?',
    'What is the problem you are facing?',
    'What is the item that needs to be done?',
    'Where do you need the service?',
    'What type of building that need plumbing service?',
]
export const paintingQuestions = [
    'I want to paint my',
    'What is the measurement of the floor space (sqft)?',
    'Do you need us to provide paint?'
]
export const airconQuestions = [
    'What aircon services do you need?',
    'What type of building is your aircon located at?',
]
export const moverQuestions = [
    'You are moving from?',
    'You are moving to?',
    'Which service do you need?',
    'How many boxes do you need to move?',
    'How much approximately do they weigh?',
    'What other services do you need?',
    'What type of property does the mover have to pick up from?',
    'What type of property does the mover have to move to?',
    'Any more details?',
    'What is your budget for this job?',
]

export const statesList = [
    { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
    { value: 'Selangor', label: 'Selangor' },
    { value: 'Terengganu', label: 'Terengganu' },
    { value: 'Sarawak', label: 'Sarawak' },
    { value: 'Sabah', label: 'Sabah' },
    { value: 'Perlis', label: 'Perlis' },
    { value: 'Perak', label: 'Perak' },
    { value: 'Penang', label: 'Penang' },
    { value: 'Pahang', label: 'Pahang' },
    { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
    { value: 'Melaka', label: 'Melaka' },
    { value: 'Kelantan', label: 'Kelantan' },
    { value: 'Kedah', label: 'Kedah' },
    { value: 'Johor', label: 'Johor' }
]

export const citiesList = [
    { value: 'Kluang', label: 'Kluang' },
    { value: 'Kota Tinggi', label: 'Kota Tinggi' },
    { value: 'Mersing', label: 'Mersing' },
    { value: 'Muar', label: 'Muar' },
    { value: 'Pontian', label: 'Pontian' },
]
import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';

const MoverSection3 = (props) => {
    const history = useHistory()
    let moverJobInfo = { ...props.moverJobInfo }

    const [movingFrom, setMovingFrom] = useState('');
    const [movingTo, setMovingTo] = useState('');

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setMovingFrom(moverJobInfo.questions[props.index1].a)
        setMovingTo(moverJobInfo.questions[props.index2].a)
    }, [])

    const updateAns = (index, value) => {
        let jobInfo = {
            ...moverJobInfo,
            questions: {
                ...moverJobInfo.questions,
                [index]: {
                    ...moverJobInfo.questions[index],
                    a: value
                }
            }
        }
        props.dispatch(props.actions.setMoverJobInfo(jobInfo))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index1].q}
            </div>
            <div
                className='col'
                style={{ flex: 1, marginTop: 10 }}>
                <FormGroup>
                    <Input
                        style={{ fontSize: 18 }}
                        type='text'
                        placeholder=''
                        value={movingFrom}
                        onChange={(event) => {
                            setMovingFrom(event.target.value)
                            updateAns(props.index1, event.target.value)
                        }} />
                </FormGroup>
            </div>

            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 20, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index2].q}
            </div>
            <div
                className='col'
                style={{ flex: 1, marginTop: 10 }}>
                <FormGroup>
                    <Input
                        style={{ fontSize: 18 }}
                        type='text'
                        placeholder=''
                        value={movingTo}
                        onChange={(event) => {
                            setMovingTo(event.target.value)
                            updateAns(props.index2, event.target.value)
                        }} />
                </FormGroup>
            </div>
        </div>
    )
}

export default MoverSection3; 
import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { useLocation, useHistory } from 'react-router-dom';
import * as Colors from '.././colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from '.././util/loader'
import MyError from '.././util/error'
import MyStorage from '.././util/storage'
import Api from '.././api/api'
import image from '../../assets/image.png';
import cross from '../../assets/cross1.png';
import { showOption } from './option'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import showToast from '.././util/toast'
import { Actions } from '.././redux/Actions'
import { useDispatch, useSelector } from 'react-redux';
import { statesList } from '.././constants/constants'

var files = []

const QuoteContactDetails = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();

    const jobType = location.state.jobType
    const job = location.state.job
    // console.log('job: ' + JSON.stringify(job));

    const [phone, setPhone] = useState('');
    const [selectedStates_, setSelectedStates_] = useState();
    const [address, setAddress] = useState('');

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        let user = localStorage.getItem('user')
        if (user != null)
            user = JSON.parse(user)

        let quoteContactDetails = localStorage.getItem('quoteContactDetails')
        if (user != null) {
            if (quoteContactDetails != null) {
                let obj = JSON.parse(quoteContactDetails)
                if (user.phone != null && user.phone !== '') {
                    setPhone(user.phone)
                }
                else {
                    setPhone(obj.phone)
                }
                setSelectedStates_({
                    value: obj.state,
                    label: obj.state,
                })
                setAddress(obj.address)
            }
        }
        else {
            console.log('user null');
        }
    }, []);

    const saveChanges = () => {
        console.log('selectedStates_: ' + JSON.stringify(selectedStates_));
        if (!phone || !address) {
            showToast('error', 'All fields are required');
            return
        }
        if (phone.length < 10) {
            showToast('error', 'Invalid phone');
            return
        }
        job.phone = phone
        job.state = selectedStates_.value
        job.address = address

        // store state
        let quoteContactDetails = {
            phone: phone,
            state: selectedStates_.value,
            address: address,
        }
        localStorage.setItem('quoteContactDetails', JSON.stringify(quoteContactDetails))

        history.push({
            pathname: '/quote-confirmation',
            state: { job: job, jobType: jobType }
        })
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1, paddingBottom: 30 }}>

            <div className='login quote-top' style={{ paddingLeft: 0, paddingRight: 0, marginTop: 30 }}>

                <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>

                    <div className='home-label' style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 20 }}>
                        Contact & Location Details
                    </div>

                    {/* <div
                        className='row'
                        style={{}}>
                        <div
                            className='col'
                            style={{ flex: 1 }}>
                            <div
                                className='option-label'
                                style={{ marginTop: 20 }}>
                                <span style={{ color: 'red' }}>*</span> First Name
                            </div>
                            <FormGroup>
                                <Input
                                    style={{ fontSize: 16, borderRadius: 5, height: 45, marginTop: 5 }}
                                    type='text'
                                    placeholder=''
                                    value={fname}
                                    onChange={(event) => setFname(event.target.value)} />
                            </FormGroup>
                        </div>
                        <div
                            className='col'
                            style={{ flex: 1 }}>
                            <div
                                className='option-label'
                                style={{ marginTop: 20 }}>
                                <span style={{ color: 'red' }}>*</span> Last Name
                            </div>
                            <FormGroup>
                                <Input
                                    style={{ fontSize: 16, borderRadius: 5, height: 45, marginTop: 5 }}
                                    type='text'
                                    placeholder=''
                                    value={lname}
                                    onChange={(event) => setLname(event.target.value)} />
                            </FormGroup>
                        </div>
                    </div>

                    <div
                        className='col'
                        style={{ flex: 1, marginTop: 10 }}>
                        <div
                            className='option-label'
                            style={{ marginTop: 0 }}>
                            <span style={{ color: 'red' }}></span> Email
                        </div>
                        <FormGroup>
                            <Input
                                disabled
                                style={{ fontSize: 16, borderRadius: 5, height: 45, marginTop: 5 }}
                                type='email'
                                placeholder=''
                                value={email}
                                onChange={(event) => setEmail(event.target.value)} />
                        </FormGroup>
                    </div> */}

                    <div
                        className='col'
                        style={{ flex: 1, marginTop: 10 }}>
                        <div
                            className='option-label'
                            style={{ marginTop: 0 }}>
                            <span style={{ color: 'red' }}>*</span> Phone Number
                        </div>
                        <FormGroup style={{ marginTop: 5 }}>
                            <PhoneInput
                                inputStyle={{ height: 45, fontSize: 16, width: '100%' }}
                                country={'my'}
                                value={phone}
                                onChange={phone => setPhone(phone)}
                            />
                        </FormGroup>
                    </div>

                    <div
                        className='col'
                        style={{ flex: 1, marginTop: 10 }}>
                        <div
                            className='option-label'
                            style={{ marginTop: 0 }}>
                            <span style={{ color: 'red' }}>*</span> State
                        </div>
                        <FormGroup style={{ marginTop: 5 }}>
                            <Select
                                options={statesList}
                                placeholder=""
                                style={{ fontSize: 12 }}
                                styles={{ fontSize: 12, inputStyle: { fontSize: 12 } }}
                                value={selectedStates_}
                                onChange={(data) => {
                                    setSelectedStates_(data);
                                }}
                                isSearchable={true}
                                isMulti={false} />
                        </FormGroup>
                    </div>

                    <div
                        className='col'
                        style={{ flex: 1, marginTop: 10 }}>
                        <div
                            className='option-label'
                            style={{ marginTop: 0 }}>
                            <span style={{ color: 'red' }}>*</span> Address
                        </div>
                        <FormGroup style={{ marginTop: 5 }}>
                            <Input
                                type='textarea'
                                placeholder=''
                                value={address}
                                onChange={(event) => {
                                    setAddress(event.target.value)
                                }} />
                        </FormGroup>
                    </div>

                    <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: 16, color: 'gray' }}>Please note by submitting your request, you are agreeing to our</div>
                        <button
                            onClick={() => {
                                // history.push('/login')
                            }}
                            style={{ fontSize: 16, marginLeft: 0, border: '0px', backgroundColor: 'transparent', color: Colors.theme1 }}>
                            Terms of Use
                        </button>
                    </div>

                    <button
                        onClick={saveChanges}
                        className='login-btn'
                        style={{ marginTop: 20, fontSize: 17, width: '100%' }}>
                        Next
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default QuoteContactDetails; 
export const Actions = {
    setAcceptedIndex,
    setProfilePercentage,
    seQuotedJobs,
    setActiveJobs,
    setUser,
    setMoverJobInfo,
    setBusinessProfile,
};

function setBusinessProfile(data) {
    return {
        type: 'setBusinessProfile',
        payload: data
    }
}

function setMoverJobInfo(data) {
    return {
        type: 'setMoverJobInfo',
        payload: data
    }
}

function setUser(data) {
    return {
        type: 'setUser',
        payload: data
    }
}

function setActiveJobs(data) {
    return {
        type: 'setActiveJobs',
        payload: data
    }
}

function seQuotedJobs(data) {
    return {
        type: 'seQuotedJobs',
        payload: data
    }
}

function setProfilePercentage(data) {
    return {
        type: 'setProfilePercentage',
        payload: data
    }
}

function setAcceptedIndex(data) {
    return {
        type: 'setAcceptedIndex',
        payload: data
    }
}
import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { useHistory } from 'react-router-dom';
import MyLoader from '../util/loader'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import MoverSection1 from './mover/mover-section1'
import MoverSection2 from './mover/mover-section2-unused'
import MoverSection3 from './mover/mover-section3'
import MoverSection4 from './mover/mover-section4'
import MoverSection5 from './mover/mover-section5'
import MoverSection6 from './mover/mover-section6'
import MoverSection7 from './mover/mover-section7'
import MoverSection8 from './mover/mover-section8'
import MoverSection9 from './mover/mover-section9'
import MoverSection10 from './mover/mover-section10'
import MoverSection11 from './mover/mover-section11'
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/Actions'
import showToast from '../util/toast';

const MoverQuote = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const moverJobInfo = useSelector(state => state.MyReducer.moverJobInfo);

    const [section, setSection] = useState(1);

    const showSection = () => {
        switch (section) {
            case 1:
                return <MoverSection1 dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 2:
                return <MoverSection3 index1={0} index2={1} dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 3:
                return <MoverSection4 index={2} dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 4:
                return <MoverSection5 index1={3} index2={4} dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 5:
                return <MoverSection6 index={5} dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 6:
                return <MoverSection7 index1={6} index2={7} dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 7:
                return <MoverSection8 dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 8:
                return <MoverSection9 index={8} dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 9:
                return <MoverSection10 index={9} dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
            case 10:
                return <MoverSection11 dispatch={dispatch} actions={Actions} moverJobInfo={moverJobInfo} />
        }
    };

    const moveToNext = () => {
        console.log('updated: ' + JSON.stringify(moverJobInfo));
        let sec = section

        let flag = true

        if (sec == 1) {
            if (moverJobInfo.state === '' || moverJobInfo.address === '') {
                flag = false
            }
        }
        else if (sec == 2) {
            if (moverJobInfo.questions[0].a === '' || moverJobInfo.questions[1].a === '') {
                flag = false
            }
        }
        else if (sec == 3) {
            if (moverJobInfo.questions[2].a === '') {
                flag = false
            }
        }
        else if (sec == 4) {
            if (moverJobInfo.questions[3].a === '' || moverJobInfo.questions[4].a === '') {
                flag = false
            }
        }
        else if (sec == 5) {
            // optional
        }
        else if (sec == 6) {
            if (moverJobInfo.questions[6].a === '' || moverJobInfo.questions[7].a === '') {
                flag = false
            }
        }
        else if (sec == 7) {
            if (moverJobInfo.preferredDate === ''
                || moverJobInfo.timesSelected === ''
                || moverJobInfo.aPreferredDate === ''
                || moverJobInfo.aTimesSelected === '') {
                flag = false
            }
            let d1 = moment(moverJobInfo.preferredDate, 'DD MMM YYYY')
            let d2 = moment(moverJobInfo.aPreferredDate, 'DD MMM YYYY')
            if (d1 >= d2) {
                showToast('error', 'Alternative date should be greater than preferred')
                return
            }
        }
        else if (sec == 8) {
            if (moverJobInfo.questions[8].a === '') {
                flag = false
            }
        }
        else if (sec == 9) {
            if (moverJobInfo.questions[9].a === '') {
                flag = false
            }
        }
        else if (sec == 10) {
            let quoteContactDetails = localStorage.getItem('quoteContactDetails')
            let flag = false
            if (quoteContactDetails != null) {
                let obj = JSON.parse(quoteContactDetails)
                if (obj.phone !== '') {
                    flag = true
                }
            }
        }

        if (!flag) {
            showToast('error', 'Missing Info')
            return
        }

        if (sec == 10) {
            // convert questions object to array
            let questions = []
            for (let i = 0; i <= 9; i++) {
                questions.push(moverJobInfo.questions[''+i])
            }
            moverJobInfo.questions = questions
            history.push({
                pathname: '/quote-confirmation',
                state: { job: moverJobInfo, jobType: 'mover' }
            })
            return
        }

        sec++
        setSection(sec)
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1, paddingBottom: 30, height: '100vh' }}>

            <div className='login quote-top' style={{ paddingLeft: 0, paddingRight: 0, marginTop: 30 }}>

                <div className='home-label' style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 20 }}>
                    Book Moving Service
                </div>

                <div className='my-line' />

                <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>

                    {showSection()}

                    <div style={{ marginTop: 20, display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>

                        <button
                            onClick={() => {
                                if (section == 1) {
                                    return
                                }
                                let sec = section
                                sec--
                                setSection(sec)
                            }}
                            className='login-btn'
                            style={{ visibility: section > 1 ? 'visible' : 'hidden', fontSize: 17, minWidth: 150, backgroundColor: 'gray' }}>
                            {'<'} Back
                        </button>

                        <button
                            onClick={moveToNext}
                            className='login-btn'
                            style={{ fontSize: 17, minWidth: 150, marginLeft: 20 }}>
                            {section == 11 ? 'Book Now' : 'Next >'}
                        </button>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default MoverQuote; 
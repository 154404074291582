
export const showOption = (text, list, updateList, chooseMore, hideLabel=false, detail='') => {
    return (
        <div>

            {hideLabel ?
                null
                :
                <div className='option-label'>
                    <span style={{ color: 'red' }}>*</span> {text}
                </div>
            }

            {chooseMore && <span style={{ color: 'gray', fontSize: 14, marginLeft: 5 }}>You can choose more than 1</span>}

            {detail !== '' && <span style={{ color: 'gray', fontSize: 14, marginLeft: 5 }}>{detail}</span>}

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {list.map((item, index) => {
                    return (
                        <button
                            onClick={() => {
                                const newList = list.map((item_) => {
                                    if (item_.title === item.title)
                                        return { ...item_, selected: !item.selected };
                                    else
                                        return chooseMore ? item_ : { ...item_, selected: false }; // set others false
                                });
                                updateList(newList)
                            }}
                            className={item.selected ? 'tab-item1-sel' : 'tab-item1'}>
                            {item.title}
                        </button>
                    )
                })}
            </div>

        </div>
    )
};
import React, { useState, useEffect } from 'react';
import '../App.css';
import { Redirect, useHistory } from 'react-router-dom';
import logo from '../assets/pros_logo.png';
import menu_icon from '../assets/menu_icon.png';
import pros_header from '../assets/pros_header.png';
import bell from '../assets/bell.png';
import user1 from '../assets/profile_white.png';
import arrow from '../assets/arrow_down.png';
import menu from '../assets/menu.png';
import cross from '../assets/cross1.png';
import back from '../assets/back2.png';
import * as Colors from './colors'
import Api from './api/api'

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from './redux/Actions'
import moment from 'moment';

const Header = ({ toggleNav }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(state => state.MyReducer.user);

  const [notifications, setNotifications] = useState([])
  const [unread, setUnread] = useState(0)
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [selected, setSelected] = useState(-1)

  const section1 = ['Find a Job']
  const section2 = ['Business Profile', 'Open Jobs', 'Quoted Jobs', 'Active Jobs']
  const section3 = ['Post a Job', 'My Jobs', 'My Favorites']
  const section4 = user != null ? ['Edit Profile', 'Logout'] : ['Login', 'Contact Us']

  useEffect(() => {
    let u = localStorage.getItem('user')
    if (u != null) {
      let user = JSON.parse(u)
      dispatch(Actions.setUser(user))
      loadNotifications(user)
    }
    else {
      console.log('logout');
      // history.replace('/login')
    }
  }, []);

  // notifications menu
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);

  const bellClick = (event) => {
    setAnchorEl1(event.currentTarget);
    setUnread(0)
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const loadNotifications = (user) => {
    console.log('loadNotifications: ' + user.id);
    Api.getNotifications(user.id,
      (response) => {
        console.log('notifications: ' + JSON.stringify(response));
        if (response.status === 'success') {
          setNotifications(response.notifications)

          let count = 0
          for (let i = 0; i < response.notifications.length; i++) {
            const model = response.notifications[i];
            if (model.status == 0)
              count++
          }
          console.log('unread: ' + count);
          setUnread(count)
        }
      },
      (error) => {
        console.log(error);
      });
  };

  const joinAsAPro = () => {
    if (user == null)
      history.push({
        pathname: '/signup',
        state: { type: 'vendor' }
      })
    else {
      history.push('/edit-vendor-profile')

      // update customer to vendor
      let userNew = { ...user }
      userNew.user_type = 'vendor'
      dispatch(Actions.setUser(userNew))
      localStorage.setItem('user', JSON.stringify(userNew))

      Api.updateCustomer(user.id,
        (response) => {
          console.log('update customer to vendor');
          console.log(JSON.stringify(response));
        },
        (error) => {
          console.log(error);
        });
    }
  };

  // drawar
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <button
        onClick={toggleDrawer('right', true)}
        style={{ border: '0px', backgroundColor: 'transparent', marginTop: 10, marginBottom: 10 }}>
        <img
          style={{ width: 28, height: 28 }}
          src={back} />
      </button>

      <Divider />

      {user != null &&
        <div>
          <div style={{ padding: 10, fontSize: 16, fontWeight: 'bold', color: 'gray', backgroundColor: '#f3f3f3' }}>
            As a Pro
          </div>

          {user.user_type === 'customer' ?
            <List style={{ marginTop: -5 }}>
              {section1.map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    style={{ padding: 5, paddingLeft: 20 }}
                    onClick={() => {
                      if (text === 'Find a Job') {
                        joinAsAPro()
                      }
                    }}>
                    <ListItemText primary={<div style={{ fontSize: 16, color: 'gray' }}>{text}</div>} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            :
            <List style={{ marginTop: -5 }}>
              {section2.map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    style={{ padding: 5, paddingLeft: 20 }}
                    onClick={() => {
                      // if (text === 'Dashboard') {
                      //   history.push('/customer-dashboard')
                      // }
                      if (text === 'Open Jobs') {
                        history.push('/vendor-dashboard')
                      }
                      else if (text === 'Quoted Jobs') {
                        history.push('/vendor-dashboard')
                      }
                      else if (text === 'Active Jobs') {
                        history.push('/vendor-dashboard')
                      }
                      else if (text === 'Business Profile') {
                        history.push('/edit-vendor-profile')
                      }
                    }}>
                    <ListItemText primary={<div style={{ fontSize: 16, color: 'gray' }}>{text}</div>} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          }

        </div>
      }

      {user != null &&
        <div>
          <div style={{ padding: 10, fontSize: 16, fontWeight: 'bold', color: 'gray', backgroundColor: '#f3f3f3', marginTop: -5 }}>
            As a Customer
          </div>
          <List style={{ marginTop: -5 }}>
            {section3.map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  style={{ padding: 5, paddingLeft: 20 }}
                  onClick={() => {
                    // if (text === 'Dashboard') {
                    //   history.push('/customer-dashboard')
                    // }
                    if (text === 'Post a Job') {
                      history.push('/post-job')
                    }
                    if (text === 'My Jobs') {
                      history.push('/customer-dashboard')
                    }
                    else if (text === 'My Favorites') {
                      history.push('/favorites')
                    }
                  }}>
                  <ListItemText primary={<div style={{ fontSize: 16, color: 'gray' }}>{text}</div>} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      }

      <Divider />

      <List style={{ marginTop: -5 }}>
        {section4.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              style={{ padding: 5, paddingLeft: 20 }}
              onClick={() => {
                if (text === 'Login') {
                  history.push('/login')
                }
                if (text === 'Contact Us') {
                  history.push('/contact-form')
                }
                if (text === 'Edit Profile') {
                  history.push('/edit-profile')
                }
                if (text === 'Logout') {
                  localStorage.removeItem('user')
                  dispatch(Actions.setUser(null))
                  history.replace('/login')
                }
              }}>
              <ListItemText
                primary={<div style={{ fontSize: 16, color: 'gray' }}>{text}</div>} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <nav className="navigation" style={{}}>

      <img
        src={pros_header}
        style={{ position: 'absolute', top: 0, zIndex: 1, width: '100%', height: 60, objectFit: 'cover' }} />

      <a href="/" className="brand-name" style={{ zIndex: 3 }}>
        <img
          onClick={() => {
            history.push('/')
          }}
          src={logo}
          style={{ width: 100, marginTop:40, objectFit: 'contain' }} />
      </a>

      <div
        style={{ zIndex: 11 }}
        className='navigation-menu'>
        <ul>
          <li>
            <a href="/post-job">Post a Job</a>
          </li>
          {/* <li>
            <a href="/login">Login</a>
          </li> */}
          <li className='join-as'>
            <button
              onClick={joinAsAPro}>
              Find a Job
            </button>
          </li>
          {user == null &&
            <li>
              <a href="/login">Login</a>
            </li>
          }

        </ul>
      </div>

      <div
        style={{ zIndex: 11, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        className='title-buttons'>

        {user !== null &&
          <button
            className='hover-effect'
            onClick={bellClick}
            style={{ backgroundColor: 'transparent', border: '0px', marginLeft: 10 }}>
            <img
              style={{ width: 28, height: 28, objectFit: 'contain' }}
              src={bell} />
            {unread > 0 &&
              <div style={{ position: 'absolute', top: 12, marginLeft: 20, backgroundColor: 'red', width: 10, height: 10, borderRadius: 5 }} />
            }
          </button>
        }
        <Menu
          id="basic-menu1"
          anchorEl={anchorEl1}
          open={open1}
          onClose={handleClose1}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {notifications.map((item, index) => {
            return (
              <MenuItem onClick={handleClose1}>
                <div
                  onClick={() => {
                    console.log('click');
                    // if (user != null) {
                    //   if (user.user_type === 'vendor') {
                    //     history.push({
                    //       pathname: '/vendor-project-details',
                    //       state: {  // location state
                    //         leftPanel: item.job.status === 'open' ? '' : 'quote',
                    //         job: item.job,
                    //       },
                    //     });
                    //   }
                    //   else {
                    //     history.push({
                    //       pathname: '/project-details',
                    //       state: { job: item.job }
                    //     })
                    //   }
                    // }
                  }}
                  style={{ padding: 0 }}>
                  <div style={{ marginTop: 0, color: 'black', fontSize: 14, fontWeight: 'bold' }}>
                    {item.title}
                  </div>
                  <div style={{ marginTop: 3, color: 'gray', fontSize: 14 }}>
                    {item.detail}
                  </div>
                  <div style={{ marginTop: 3, color: Colors.theme1, fontSize: 12, fontWeight: 'bold' }}>
                    {moment(item.created_at).format('hh:mm a, DD MMM')}
                  </div>
                  {index < notifications.length - 1 && <div className='my-line' style={{ marginBottom: 0 }} />}
                </div>
              </MenuItem>
            )
          })}
        </Menu>

        {user !== null &&
          <button
            className='hover-effect'
            onClick={() => {
              history.push('/edit-profile')
            }}
            style={{ border: '0px', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 12 }}>
            <img
              style={{ width: 28, height: 28, objectFit: 'contain' }}
              src={user1} />
            <div style={{ color: 'white', fontSize: 14, fontWeight: 'bold', marginLeft: 5 }}>
              {user.first_name}
            </div>
          </button>
        }

        <button
          onClick={toggleDrawer('right', true)}
          style={{ backgroundColor: 'transparent', border: '0px', marginLeft: 10 }}
          className='hover-effect'>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              style={{ width: 30, height: 30, objectFit: 'contain' }}
              src={menu} />
          </div>
        </button>

        <div style={{ zIndex: 100 }}>
          {['right'].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>

      </div>

    </nav>
  );
}

export default Header;

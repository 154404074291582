import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const MoverSection8 = (props) => {
    const history = useHistory()
    let moverJobInfo = { ...props.moverJobInfo }

    const [other, setOther] = useState('');

    const [preferredDate, setPreferredDate] = useState(new Date());
    const [altDate, setAltDate] = useState(new Date());
    const times = [
        { value: '1', label: 'Choose Time', selected: false },
        { value: '2', label: 'Morning (09:00 - 11:00)', selected: false },
        { value: '3', label: 'Lunch Time (11:00 - 13:00)', selected: false },
        { value: '4', label: 'Early Afternoon (13:00 - 15:00)', selected: false },
        { value: '5', label: 'Late Afternoon (15:00 - 17:00)', selected: false },
    ]
    const [selectedPrefTimes, setSelectedPrefTimes] = useState();
    const [selectedAltTimes, setSelectedAltTimes] = useState();

    useEffect(() => {
        setPreferredDate(moment(moverJobInfo.preferredDate, 'DD MMM YYYY').toDate())
        setAltDate(moment(moverJobInfo.aPreferredDate, 'DD MMM YYYY').toDate())
        setSelectedPrefTimes({ value: moverJobInfo.timesSelected, label: moverJobInfo.timesSelected })
        setSelectedAltTimes({ value: moverJobInfo.aTimesSelected, label: moverJobInfo.aTimesSelected })
    }, [])

    const updateAns = (key, value) => {
        let jobInfo = {
            ...moverJobInfo,
            [key]: value,
        }
        props.dispatch(props.actions.setMoverJobInfo(jobInfo))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                When do you need the service?
            </div>
            <div
                style={{ color: 'gray', fontSize: 14, alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Please choose at least 2 available dates for the service provider to come.
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10 }}>

                <div
                    className='option-label'
                    style={{ alignSelf: 'center', marginTop: 10, textAlign: 'center' }}>
                    Preferred Date & Time
                </div>
                <div style={{ marginTop: 10 }}>
                    <DatePicker
                        selected={preferredDate}
                        onChange={(date) => {
                            setPreferredDate(date)
                            let dateStr = moment(date).format('DD MMM YYYY')
                            updateAns('preferredDate', dateStr)
                        }}
                        customInput={
                            <div style={{ color: preferredDate == null ? 'gray' : 'black', fontSize: 18, borderRadius: 5, border: '1px solid lightgray', padding: 10 }}>
                                {preferredDate == null ? 'Choose Date' : moment(preferredDate).format('DD MMM YYYY')}
                            </div>
                        } />
                    <div style={{ marginTop: 5 }}>
                        <Select
                            options={times}
                            placeholder="Choose Time"
                            style={{ fontSize: 12, height: 45 }}
                            styles={{
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    fontSize: 18,
                                }),
                            }}
                            value={selectedPrefTimes}
                            onChange={(data) => {
                                setSelectedPrefTimes(data);
                                updateAns('timesSelected', data.label)
                            }}
                            isSearchable={false}
                            isMulti={false} />
                    </div>
                </div>

                <div
                    className='option-label'
                    style={{ alignSelf: 'center', marginTop: 10, textAlign: 'center' }}>
                    Alternative Date & Time
                </div>
                <div style={{ marginTop: 10 }}>
                    <DatePicker
                        selected={altDate}
                        onChange={(date) => {
                            setAltDate(date)
                            let dateStr = moment(date).format('DD MMM YYYY')
                            updateAns('aPreferredDate', dateStr)
                        }}
                        customInput={
                            <div style={{ color: altDate == null ? 'gray' : 'black', fontSize: 18, borderRadius: 5, border: '1px solid lightgray', padding: 10 }}>
                                {altDate == null ? 'Choose Date' : moment(altDate).format('DD MMM YYYY')}
                            </div>
                        } />
                    <div style={{ marginTop: 5 }}>
                        <Select
                            options={times}
                            placeholder="Choose Time"
                            style={{ fontSize: 12, height: 45 }}
                            styles={{
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    fontSize: 18,
                                }),
                            }}
                            value={selectedAltTimes}
                            onChange={(data) => {
                                setSelectedAltTimes(data);
                                updateAns('aTimesSelected', data.label)
                            }}
                            isSearchable={false}
                            isMulti={false} />
                    </div>

                </div>


            </div>


        </div>
    )
}

export default MoverSection8; 
import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import * as Colors from '../../colors'

const ProfileSection6 = (props) => {
    const history = useHistory()
    let businessProfile = { ...props.businessProfile }

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const [awards, setAwards] = useState([]);

    const checkValid = (key) => {
        return businessProfile[key] !== undefined && businessProfile[key] != null && businessProfile[key] !== '' && businessProfile[key] !== 'null'
    };

    useEffect(() => {
        if (checkValid('credentials')) {
            let arr = businessProfile.credentials.split(',')
            let temp = []
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                if (item === '')
                    continue
                temp.push(
                    { link: item.split('::')[0], detail: item.split('::')[1] }
                )
            }
            setAwards(temp)
        }
    }, [props])

    const updateValue = (arr) => {
        let credentials = ''
        for (let i = 0; i < arr.length; i++) {
            credentials = credentials + arr[i].link + '::' + arr[i].detail + ','
        }
        credentials = credentials.substring(0, credentials.length - 1)
        businessProfile['credentials'] = credentials
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Credentials & Awards (Optional)
            </div>

            <div style={{ fontSize: 14, marginTop: 5, color: 'gray', textAlign: 'center', alignSelf: 'center' }}>
                Enter the description and URL of any credentials, awards, or articles that you have been featured in. The more links you have, the more confidence consumers will have in your business.
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>

                <div>
                    {awards.map((item, index) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, alignItems: 'center', alignItems: 'stretch' }}>
                                    <div style={{ display: 'flex', }}>
                                        <div style={{ width: 100, display: 'flex', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, paddingLeft: 10, paddingRight: 10, marginRight: -1, fontSize: 16, color: 'black', backgroundColor: 'white', border: '1px solid lightgray', height: 45, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            Link
                                        </div>
                                        <div
                                            style={{ flex: 1, borderTopRightRadius: 5, borderBottomRightRadius: 5, fontSize: 16, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: Colors.theme1, textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                                            <input
                                                type='text'
                                                placeholder='e.g. https://www.google.com'
                                                style={{ fontSize: 16, border: '0px', width: '100%', height: '100%', paddingLeft: 15, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                                                value={item.link}
                                                onChange={(event) => {
                                                    const arr = awards.map((item_, index_) => {
                                                        if (index === index_) {
                                                            return { ...item_, link: event.target.value };
                                                        }
                                                        else {
                                                            return item_;
                                                        }
                                                    });
                                                    setAwards(arr)
                                                    updateValue(arr)
                                                }} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <div style={{ width: 100, display: 'flex', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, paddingLeft: 10, paddingRight: 10, marginRight: -1, fontSize: 16, color: 'black', backgroundColor: 'white', border: '1px solid lightgray', height: 45, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            Detail
                                        </div>
                                        <div
                                            style={{ flex: 1, fontSize: 16, borderTopRightRadius: 5, borderBottomRightRadius: 5, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: Colors.theme1, textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                                            <input
                                                type='textarea'
                                                placeholder='Enter detail...'
                                                style={{ fontSize: 16, border: '0px', width: '100%', height: '100%', paddingLeft: 15, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                                                value={item.detail}
                                                onChange={(event) => {
                                                    const arr = awards.map((item_, index_) => {
                                                        if (index === index_) {
                                                            return { ...item_, detail: event.target.value };
                                                        }
                                                        else {
                                                            return item_;
                                                        }
                                                    });
                                                    setAwards(arr)
                                                    updateValue(arr)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                    <button
                                        onClick={() => {
                                            const temp = awards.filter((item, j) => {
                                                return index !== j; //false will delete
                                            });
                                            setAwards(temp)
                                            updateValue(temp)
                                        }}
                                        style={{ border: '0px', fontSize: 14, backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>
                                        X
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <button
                    onClick={() => {
                        let temp = [...awards]
                        temp.push({
                            link: '',
                            detail: ''
                        })
                        setAwards(temp)
                    }}
                    className='login-btn vendor-view-profile'
                    style={{ height: 45, fontSize: 17, marginTop: 15, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                    Add Award
                </button>

            </div>

        </div>
    )
}

export default ProfileSection6; 
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Colors from '../colors'
import heart from '../../assets/heart.png';
import Rating from '@mui/material/Rating';
import MyLoader from '../util/loader'
import Api, { photosUrl } from '../api/api'
import { useSelector } from 'react-redux';
import avatar from '../../assets/avatar.png';

const Favorites = () => {
    const history = useHistory()
    const [loader, setLoader] = useState(false);

    const { category } = useParams()

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const [vendors, setVendors] = useState([])

    useEffect(() => {
        loadVendors()
    }, [])

    const loadVendors = () => {
        setLoader(true)
        console.log('id: ' + user.id);
        Api.favoriteVendors(user.id,
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    setVendors(response.vendors)
                }
            },
            (error) => {
                setLoader(false)
            });
    };

    const checkValid = (val) => {
        return val !== undefined && val != null && val !== '' && val !== 'null'
    };

    return (
        <div style={{ backgroundColor: Colors.background }}>

            <div style={{ backgroundColor: 'white', marginTop: 0, paddingBottom: 20, paddingTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>
                    <div className='my-title'>
                        My Favorite vendors
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: 'white', marginTop: 0, paddingBottom: 20, paddingTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>

                    {vendors.length == 0 &&
                        <div style={{ display: 'flex', height: '50vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ color: 'gray', fontSize: 18 }}>
                                No Favorites Found
                            </div>
                        </div>
                    }

                    {vendors.map((item, index) => {
                        return (
                            <div>
                                <div
                                    className='vendor-item'
                                    style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>

                                    <img
                                        className='vendor-img'
                                        style={{ borderRadius: 10, marginLeft: 0, marginTop: 0, width: 120, height: 120 }}
                                        src={checkValid(item.vendor.photo) ? photosUrl + '/logos/' + item.vendor.photo : avatar} />

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='vendor-info'>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontSize: 17, marginLeft: 0, fontWeight: 'bold' }}>
                                                    {item.vendor.first_name} {item.vendor.last_name}
                                                </div>
                                                <div style={{ display: 'flex', marginTop: 5 }}>
                                                    <Rating
                                                        readOnly
                                                        style={{ marginLeft: -2 }}
                                                        size='small'
                                                        name="simple-controlled"
                                                        value={item.rating != null ? item.rating : 0}
                                                        onChange={(event, newValue) => {
                                                            // setValue(newValue);
                                                        }}
                                                    />
                                                    <p style={{ fontSize: 16, marginTop: 0 }}>{item.vendor.rating}</p>
                                                    <p style={{ marginLeft: 5, fontSize: 16, marginTop: 0, color: 'gray' }}>({item.review_count} Reviews)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                history.push('/vendor/' + item.vendor.id)
                                            }}
                                            className='login-btn vendor-view-profile'
                                            style={{ width: 200, height: 40, fontSize: 17, marginTop: 0, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                                            View Profile
                                        </button>
                                        {/* <div style={{ fontSize: 17, marginTop: 15 }}>
                                            "He did a great job!"
                                        </div>
                                        <div style={{ fontSize: 17, marginTop: 8, color: 'gray' }}>
                                            Ali Ahmed, 20 Nov 2022, Selangor
                                        </div> */}
                                        {/* <button
                                            onClick={() => {
                                                history.push('/vendor/1234')
                                            }}
                                            className='login-btn vendor-view-profile'
                                            style={{ width: 200, height: 40, fontSize: 17, marginTop: 15, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                                            View Profile
                                        </button> */}
                                    </div>

                                    <div
                                        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                                        className='vendor-btns'>

                                        {/* <button
                                            className='login-btn'
                                            style={{ marginTop: 0, width: 200, fontSize: 17 }}>
                                            Book this Vendor
                                        </button> */}
                                        {/* <button
                                            onClick={() => {
                                                //
                                            }}
                                            className='save-btn'
                                            style={{ borderWidth: 0, backgroundColor: 'transparent', marginTop: 8, display: 'flex', width: 200, alignItems: 'center', justifyContent: 'center' }}>
                                            <img
                                                style={{ width: 30, height: 30 }}
                                                src={heart} />
                                            <div style={{ fontSize: 16, marginLeft: 5, color: Colors.gray, fontWeight: 'bold' }}>
                                                Save
                                            </div>
                                        </button> */}
                                    </div>

                                </div>
                                <div style={{ width: '100%', height: 1, backgroundColor: 'lightgray', marginTop: 20, marginBottom: 20 }} />
                            </div>
                        )
                    })}

                </div>

            </div>

            <MyLoader loader={loader} marginTop={-10} />

        </div>
    );
}

export default Favorites; 
import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const MoverSection4 = (props) => {
    const history = useHistory()
    let moverJobInfo = { ...props.moverJobInfo }

    const options = [
        '1 tonne lorry (10 feet) with 2 movers', 
        '3 tonne lorry (14 feet) with 3 movers',
        '5 tonne lorry (17 feet) with 4 movers',
        '8 tonne lorry (20 feet) with 5 movers'
    ]
    const [checked, setChecked] = useState(-1)

    useEffect(() => {
        let a = moverJobInfo.questions[props.index].a
        for (let i = 0; i < options.length; i++) {
            if(options[i] === a){
                setChecked(i)
            }
        }
    }, [])

    const updateAns = (index, value) => {
        console.log('value: ' + value);
        let jobInfo = {
            ...moverJobInfo,
            questions: {
                ...moverJobInfo.questions,
                [index]: {
                    ...moverJobInfo.questions[index],
                    a: value
                }
            }
        }
        props.dispatch(props.actions.setMoverJobInfo(jobInfo))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index].q}
            </div>
            <div
                className='col'
                style={{ flex: 1, marginTop: 20 }}>

                {options.map((item, index) => {
                    return (
                        <div key={index.toString()} style={{ display: 'flex', marginTop: index == 0 ? 0 : 15, fontSize: 16, alignItems: 'center' }}>
                            <input
                                style={{ width: 20, height: 20 }}
                                type="radio"
                                checked={checked == index}
                                id={index.toString()}
                                value={index.toString()}
                                name="fav_language"
                                onChange={(e) => {
                                    setChecked(index)
                                    updateAns(props.index, item)
                                }} />
                            <label style={{ marginLeft: 10 }} for={index.toString()}>
                                {item}
                            </label>
                        </div>
                    )
                })}
                
            </div>
        </div>
    )
}

export default MoverSection4; 
import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Colors from '../colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import attach from '../../assets/attach.png';
import Rating from '@mui/material/Rating';
import mover1 from '../../assets/mover1.png';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/Actions';
import avatar from '../../assets/avatar.png';
import Api, { chatsUrl } from '../api/api'
import showToast from '../util/toast';
import { photosUrl } from '../api/api'
import ScrollableFeed from 'react-scrollable-feed'
var FileSaver = require('file-saver');

const ChatView = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false);

    const messagesEndRef = useRef(null)

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const job = props.job
    // console.log('job1: ' + JSON.stringify(job));
    const quote = props.quote
    let vendor = null
    if (quote != null) {
        vendor = quote.vendor
    }

    const [phone, setPhone] = useState('')
    const [logo, setLogo] = useState('')

    const acceptedQid = props.acceptedQid
    const accepted = props.accepted

    const { category } = useParams()

    const [chats, setChats] = useState([])

    const [msg, setMsg] = useState('')
    const addFile = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);

    const onFilesSelect = (event) => {
        let file = event.target.files[0]
        let url = URL.createObjectURL(file)
        setSelectedFile(file)
        setSelectedFileUrl(url)
        // upload file
        sendFileMessage(file, url)
    };

    const checkValid = (val) => {
        return val !== undefined && val != null && val !== '' && val !== 'null'
    };

    useEffect(() => {
        loadChat(true)
        const intervalId = setInterval(() => {
            loadChat(false)
        }, 3000);
        return () => clearInterval(intervalId);
    }, [props])

    useEffect(() => {
        loadVendorBusinessProfile()
    }, [props])

    // to fetch phone number or other details
    const loadVendorBusinessProfile = () => {
        console.log('loadVendorBusinessProfile');
        let vendor_id = ''
        if (vendor != null) {
            vendor_id = vendor.id
        }
        else {
            vendor_id = user.id
        }
        Api.getBusinessProfile(vendor_id,
            (response) => {
                // console.log('getBusinessProfile: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // localStorage.setItem('user', JSON.stringify(response.user))
                    if (response.profile != null) {
                        let phone = response.profile.phone
                        let logo = response.profile.logo
                        // console.log('phone: ' + phone);
                        setPhone(phone)
                        setLogo(logo)
                    }
                }
            },
            (error) => {
                console.log(error);
            });
    };

    const scrollToBottom = () => {
        // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const loadChat = (scroll) => {
        console.log('loadChat');
        let vendor_id = ''
        let job_id = ''
        if (vendor != null) {
            vendor_id = vendor.id
            job_id = job.job_id // in case of vender
        }
        else {
            vendor_id = user.id
            job_id = job.id // in case of customer
        }

        console.log('loadChat: ' + vendor_id);

        Api.getChatMessages(job_id, job.customer_id, vendor_id, user.user_type,
            (response) => {
                // console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    if (response.chat_messages != null) {
                        setChats(response.chat_messages)
                        if (scroll) {
                            setTimeout(() => {
                                scrollToBottom()
                            }, 50);
                        }
                    }
                }
            },
            (error) => {
                console.log(error);
            });
    };

    const acceptQuote = () => {
        Api.acceptQuote(quote.id,
            (response) => {
                console.log(JSON.stringify(response));
                // send notification
                let vendor_id = ''
                let job_id = ''
                if (vendor != null) {
                    vendor_id = vendor.id
                    job_id = job.job_id // in case of vender
                }
                else {
                    vendor_id = user.id
                    job_id = job.id // in case of customer
                }
                let title = 'Congratulations'
                let msg = 'Your quote is accepted by the client.'
                Api.sendNotification(vendor_id, job_id, title, msg, 'alert', null, null);
            },
            (error) => {

            });
    };

    const sendMessage = () => {
        // console.log('job: ' + JSON.stringify(job));
        if (msg === '')
            return
        let model = {
            message: msg,
            sender: user.user_type,
            attached_file: '',
        }
        let a = [...chats]
        a.push(model)
        setChats(a)
        setMsg('')

        let vendor_id = ''
        let job_id = ''
        if (vendor != null) {
            vendor_id = vendor.id
            job_id = job.job_id // in case of vender
        }
        else {
            vendor_id = user.id
            job_id = job.id // in case of customer
        }

        Api.chatMessage(job_id, job.customer_id, vendor_id, msg, model.sender, null,
            (response) => {
                console.log(JSON.stringify(response));
                setMsg('')
            },
            (error) => {
                //
            });
    };

    const sendFileMessage = (file, fileUrl) => {
        if (file == null)
            return
        if (file == undefined)
            return

        // console.log('job: ' + JSON.stringify(job));
        let model = {
            message: '',
            sender: user.user_type,
            attached_file: fileUrl
        }
        chats.push(model)
        setChats(chats)

        let vendor_id = ''
        let job_id = ''
        if (vendor != null) {
            vendor_id = vendor.id
            job_id = job.job_id // in case of vender
        }
        else {
            vendor_id = user.id
            job_id = job.id // in case of customer
        }

        Api.chatMessage(job_id, job.customer_id, vendor_id, msg, model.sender, file,
            (response) => {
                console.log(JSON.stringify(response));
                // setMsg('')
            },
            (error) => {
                //
            });
    };

    return (
        <div style={{ backgroundColor: 'white', height: '100%', flex: 3, minWidth: 400, display: 'flex', flexDirection: 'column' }}
            className='padding-right-mobile'>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>

                {props.showVendor &&
                    <div
                        style={{ display: 'flex', backgroundColor: '#fafafa', border: '1px solid ' + Colors.background, flexDirection: 'column', alignItems: 'center', padding: 15, paddingLeft: 15, paddingRight: 15, width: '100%', textAlign: 'start' }}>
                        <div className='vendor-info' style={{ alignSelf: 'flex-start', alignItems: 'center', display: 'flex', width: '100%' }}>

                            <img
                                className='vendor-logo-big'
                                style={{ marginLeft: 0, width: 100, height: 100, borderRadius: 50, border: '1px solid lightgray', objectFit: 'cover' }}
                                src={checkValid(vendor.photo) ? photosUrl + '/logos/' + vendor.photo : avatar} />

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 10 }}>
                                <div style={{ fontSize: 17, marginTop: 5, marginLeft: 15, }}>
                                    {vendor.first_name} {vendor.last_name}
                                </div>
                                <div style={{ display: 'flex', height: 28, marginTop: 5 }}>
                                    <Rating
                                        readOnly
                                        style={{ marginLeft: 13, marginTop: 1 }}
                                        size='small'
                                        name="simple-controlled"
                                        value={quote.rating}
                                        onChange={(event, newValue) => {
                                            // setValue(newValue);
                                        }}
                                    />
                                    <div style={{ marginLeft: 5, fontSize: 16, color: 'gray' }}>({quote.no_of_reviews} Reviews)</div>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            history.push('/vendor/' + vendor.id)
                                        }}
                                        style={{ padding: 0, marginTop: -5, marginLeft: 13, border: '0px', backgroundColor: 'transparent', borderRadius: 5 }}>
                                        <div style={{ color: Colors.theme1, fontSize: 16, marginLeft: 5 }}>
                                            View Profile
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', alignSelf: 'flex-start', marginTop: 18 }}>
                                <div style={{ fontSize: 17, fontWeight: 'bold' }}>
                                    ${quote.price}
                                </div>
                            </div>

                        </div>
                        <div style={{ marginTop: 10, alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ fontSize: 15, color: Colors.theme1, fontWeight: 'bold' }}>
                                <span style={{ color: 'black', fontWeight: 'normal' }}>Phone:</span> +939323232
                            </div>
                            <div style={{ fontSize: 15, color: Colors.theme1, fontWeight: 'bold' }}>
                                <span style={{ color: 'black', fontWeight: 'normal' }}>Email:</span> {vendor.email}
                            </div>
                        </div>

                        {
                            accepted ?
                                <div style={{ alignSelf: 'flex-start', marginTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <button
                                        className='login-btn'
                                        style={{ color: 'black', width: 200, backgroundColor: Colors.theme2 }}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/rate-vendor',
                                                state: { vendor: vendor }
                                            })
                                        }}>
                                        Rate this Pro
                                    </button>
                                    <div style={{ marginLeft: 10, color: 'green' }}>
                                        Accepted
                                    </div>

                                </div>
                                :
                                acceptedQid === '' ?
                                    <div style={{ alignSelf: 'flex-start' }}>
                                        <button
                                            className='login-btn'
                                            style={{ marginTop: 10, color: 'black', width: 200, backgroundColor: Colors.theme2 }}
                                            onClick={() => {
                                                props.onAccept()
                                                acceptQuote()
                                                // dispatch(Actions.setAcceptedIndex(0))
                                            }}>
                                            Accept this Pro
                                        </button>
                                    </div>
                                    :
                                    null
                        }
                    </div>
                }

                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 30, height: 50, backgroundColor: '#fafafa', borderRadius: 3 }}>
                    <div style={{ color: 'black', fontSize: 18 }}>
                        Chat
                    </div>
                </div>

                {chats.length == 0 &&
                    <div style={{ marginTop: 30, fontSize: 18, color: 'gray', alignSelf: 'center' }}>
                        No Chat History
                    </div>
                }

                <div
                    ref={messagesEndRef}
                    style={{ display: 'flex', maxHeight: user.user_type === 'vendor' ? 400 : 200, overflow: 'scroll', flexDirection: 'column', flex: 1, marginTop: 20 }}
                    className='my-scroll'>
                    <ScrollableFeed>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}>
                            {chats.map((item, index) => {
                                let style = { marginBottom: 10, fontSize: 16, backgroundColor: Colors.background, color: 'black', padding: 10, borderRadius: 5, alignSelf: 'flex-start' }
                                if (user.user_type === item.sender) {
                                    style = { marginBottom: 10, fontSize: 16, backgroundColor: '#E0F2F7', color: 'black', padding: 10, borderRadius: 5, alignSelf: 'flex-end', textAlign: 'flex-end' }
                                }
                                
                                if(item.attached_file !== null && item.attached_file !== ''){
                                    console.log(''+chatsUrl + item.attached_file);
                                }
                                
                                return (
                                    <>
                                        {(item.attached_file !== null && item.attached_file !== '') ?
                                            <button
                                                key={index.toString()}
                                                style={style}
                                                onClick={() => {
                                                    console.log('download');
                                                    if (typeof item.attached_file === 'string') {
                                                        window.open(chatsUrl + item.attached_file, '_blank')
                                                        // FileSaver.saveAs(chatsUrl + item.attached_file, item.attached_file)
                                                    }
                                                    else {
                                                        FileSaver.saveAs(item.attached_file, item.attached_file.name)
                                                    }
                                                }}>

                                                {typeof item.attached_file === 'string' ?
                                                    <img src={chatsUrl + item.attached_file} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                                    :
                                                    <img src={item.attached_file} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                                }

                                            </button>
                                            :
                                            null
                                        }

                                        {item.message !== '' && item.message !== null &&
                                            <div key={index.toString()} style={style}>
                                                {item.message}
                                            </div>
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </ScrollableFeed>
                </div>

                <div style={{ display: 'flex', backgroundColor: '#fAFAFA', marginTop: 20, alignItems: 'center', padding: 10 }}>

                    <button
                        onClick={() => {
                            addFile.current.click();
                        }}
                        style={{ border: '0px', backgroundColor: 'transparent', padding: 0 }}>
                        <img
                            style={{ width: 30, height: 30 }}
                            src={attach} />
                        <input
                            type='file'
                            style={{ display: 'none' }}
                            ref={addFile}
                            onChange={(event) => {
                                onFilesSelect(event)
                            }} />
                    </button>

                    <div style={{ flex: 1, marginLeft: 5 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='text'
                            placeholder='Enter Message...'
                            value={msg}
                            onChange={(event) => setMsg(event.target.value)} />
                    </div>
                    <div>
                        <button
                            className='login-btn'
                            style={{ marginTop: 0, width: 100, marginLeft: 10 }}
                            onClick={sendMessage}>
                            Send
                        </button>
                    </div>
                </div>

            </div>

        </div >
    );
}

export default ChatView; 
import React from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';

const Privacy = () => {
    const history = useHistory();

    return (
        <div className=''>
            <div className="form" style={{ textAlign: 'left', padding:30 }}>
                <h2 style={{marginTop:40}}>Privacy Policy</h2>
                <p><strong>Effective Date:</strong> Nov 2024</p>
                <p>
                    CariWorks (“we,” “our,” or “us”) values your privacy. This Privacy Policy explains how we
                    collect, use, and protect the information you provide when using our website,
                    https://cariworks.com (the “Website”), and its services.
                </p>
                <h3>1. Information We Collect</h3>
                <ul>
                    <li><strong>Personal Information:</strong> Includes name, email address, phone number,
                        payment information, and other details provided during registration or profile creation.</li>
                    <li><strong>Non-Personal Information:</strong> Includes browser type, device information,
                        IP address, and browsing activity on our Website.</li>
                    <li><strong>Cookies and Similar Technologies:</strong> We use cookies to enhance user
                        experience and collect information about your interactions with the Website.</li>
                </ul>
                <h3>2. How We Use Your Information</h3>
                <ul>
                    <li>Facilitating job postings and applications.</li>
                    <li>Providing customer support and resolving disputes.</li>
                    <li>Improving our Website and services.</li>
                    <li>Sending transactional emails and promotional communications.</li>
                    <li>Complying with legal obligations.</li>
                </ul>
                <h3>3. Sharing of Information</h3>
                <p>
                    We do not sell, rent, or share your personal information with third parties, except:
                </p>
                <ul>
                    <li>With service providers necessary for payment processing or Website operations.</li>
                    <li>To comply with legal obligations, enforce our Terms of Use, or protect our rights.</li>
                </ul>
                <h3>4. Data Security</h3>
                <p>
                    We implement industry-standard measures to protect your data. However, no system is
                    completely secure, and we cannot guarantee the security of your information.
                </p>
                <h3>5. Your Rights</h3>
                <ul>
                    <li>Access, update, or delete your personal information.</li>
                    <li>Opt-out of receiving marketing communications.</li>
                </ul>
                <h3>6. Third-Party Links</h3>
                <p>
                    Our Website may contain links to third-party websites. We are not responsible for their
                    privacy practices or content.
                </p>
                <h3>7. Changes to This Privacy Policy</h3>
                <p>
                    We may update this Privacy Policy from time to time. Changes will be posted on this page
                    with an updated “Effective Date.”
                </p>
                <h3>8. Contact Us</h3>
                <p>
                    If you have questions or concerns about this Privacy Policy, contact us at ddsurg@ddsurgical.com.
                </p>

            </div>
        </div>
    )
}

export default Privacy
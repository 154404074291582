import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useHistory } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Rating from '@mui/material/Rating';
import search from '../assets/search.png';
import banner from '../assets/banner.png';
import worker from '../assets/worker.jpg';
import industry from '../assets/industry.jpg';
import { useSelector } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import { categories, catEmojis, categories1, countries } from './util/cats'

const Home = () => {
    const history = useHistory()

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const [catId, setCatId] = useState('');
    const cats = categories1
    const [selectedCat, setSelectedCat] = useState(cats[0])
    const [selectedCountry, setSelectedCountry] = useState('Select Country')
    const imgRef = useRef(null);
    const [imgHeight, setImgHeight] = useState(0);
    const [jobTitle, setJobTitle] = useState('');

    useEffect(() => {
        if (imgRef.current) {
            setImgHeight(imgRef.current.offsetHeight);
        }
        const handleResize = () => {
            if (imgRef.current) {
                setImgHeight(imgRef.current.offsetHeight);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showCatTab = (cat, emoji) => {
        return (
            <button
                onClick={() => {
                    // history.push('/vendors/' + cat.title)
                }}
                className='cat-tab' style={{ border: '1px solid green', color: 'black', backgroundColor: 'white', border: '0px', marginLeft: 10, marginRight: 10, marginTop: 10, alignItems: 'center', justifyContent: 'center' }}>
                {/* <img
                    src={cat.icon}
                    style={{ width: 60, height: 60, objectFit: 'contain', alignSelf: 'center' }} /> */}
                <div style={{ marginLeft: 10, alignSelf: 'center', fontSize: 18 }}>
                    {emoji} {cat}
                </div>
            </button>
        )
    }

    const showPros = (type, pros) => {
        return (
            <div
                className='home-panel'
                style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', flexWrap: "wrap", marginTop: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='home-label'>
                        {type}
                    </div>
                    <a
                        style={{ marginTop: 20, marginLeft: 20, fontSize: 22, textDecoration: 'none' }}
                        href='/'>
                        View All
                    </a>
                </div>
                <div style={{ marginTop: 0, marginLeft: 20, fontSize: 15 }}>
                    Get quotes and quality workmanship from local professionals
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                    {pros.map((item, index) => {
                        return (
                            <div className='pro-item' style={{ marginLeft: 10, marginRight: 10, marginTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    src={'https://picsum.photos/seed/picsum/200/300'}
                                    style={{}} />
                                <div style={{ display: 'flex', marginTop: 5, height: 35 }}>
                                    <Rating
                                        name="simple-controlled"
                                        value={5}
                                        onChange={(event, newValue) => {
                                            // setValue(newValue);
                                        }}
                                    />
                                    <p style={{ fontSize: 18, marginTop: 2 }}>4.8</p>
                                    <p style={{ marginLeft: 5, fontSize: 18, marginTop: 2, color: 'gray' }}>(20 Reviews)</p>
                                </div>
                                <div style={{ fontSize: 20, marginTop: -5 }}>
                                    Service Title
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    };

    const showStep = (step, stepTxt) => {
        return (
            <div className='step-parent' style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                <div className='step'>
                    <div>{step}</div>
                </div>
                <div className='step-txt'>
                    {stepTxt}
                </div>
            </div>
        )
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1 }}>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: imgHeight == 0 ? '100%' : imgHeight,
                }}>
                <img
                    ref={imgRef}
                    style={{ width: '100%', objectFit: 'contain' }}
                    src={banner}
                    alt="Banner"
                />
                <button
                    className='join-now-btn'
                    style={{
                        position: 'absolute',
                        bottom: window.innerWidth > 800 ? 50 : 20,
                        height: window.innerWidth > 800 ? 80 : 50,
                        width: window.innerWidth > 800 ? 450 : 300,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        border: '1px solid white',
                        backgroundColor: '#132030',
                        fontSize: window.innerWidth > 800 ? 26 : 18,
                        fontWeight: 'bold',
                        color: 'white',
                        borderRadius: 50,
                    }}
                    onClick={() => {
                        history.push({
                            pathname: '/signup',
                            state: { type: 'customer' },
                        });
                    }}>
                    JOIN NOW
                </button>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                    style={{ width: '100%', objectFit: 'contain' }}
                    src={banner} />
                <button
                    style={{ position: 'absolute', height: 80, width: 500, border: '1px solid white', alignSelf: 'center', backgroundColor: '#132030', fontSize: 26, fontWeight: 'bold', color: 'white', borderRadius: 50, }}
                    onClick={() => {
                        history.push({
                            pathname: '/signup',
                            state: { type: 'vendor' }
                        })
                    }}>
                    JOIN  NOW
                </button>
            </div> */}


            <div
                className='home-panel'
                style={{ display: 'flex', flexDirection: 'column', paddingBottom: 15, paddingBottom: 30, marginBottom: 50, backgroundColor: 'white', alignSelf: 'center', borderRadius: 10, marginTop: 30, zIndex: 10 }}>

                <div className='home-label' id='our-services'>
                    <img src={search} style={{ width: 25, height: 25, marginTop: -3, marginRight: 10 }} />
                    Search Jobs
                </div>
                {/* <div style={{ marginTop: 0, marginLeft: 20, fontSize: 15 }}>
                    Choose a service to get a quoation.
                </div> */}

                <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: 15, marginRight: 15, marginTop: 10 }}>
                    <div style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>
                        <input
                            style={{ flex: 1, minWidth: 400, fontSize: 18, height: 45, paddingLeft: 10, color: 'black', borderRadius: 5, border: '1px solid lightgray' }}
                            type='email'
                            placeholder='Job Title'
                            value={jobTitle}
                            onChange={(event) => setJobTitle(event.target.value)} />
                    </div>
                    <div class="select_box" style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>
                        <select
                            className='picker'
                            value={selectedCountry}
                            onChange={(e) => {
                                setSelectedCountry(e.target.value)
                            }}>
                            {countries.map((item, index) => {
                                return (
                                    <option
                                        value={item}>
                                        {item}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <button
                        className='search-btn'
                        style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}
                        onClick={() => {
                            console.log('job_type1: ' + selectedCat);
                            console.log('state1: ' + selectedCountry);
                            history.push({
                                pathname: '/search-jobs',
                                state: {
                                    job_type: selectedCat,
                                    jobTitle: jobTitle,
                                    location: selectedCountry,
                                }
                            })
                        }}>
                        Search
                    </button>
                </div>
            </div>

            {/* <div
                className='home-panel'
                style={{ display: 'flex', flexDirection: 'column', paddingBottom: 15, backgroundColor: 'white', alignSelf: 'center', borderRadius: 10, marginTop: 0, zIndex: 10 }}>

                <div className='home-label'>
                    Latest Promotion
                </div>

                <img
                    onClick={() => {
                        //
                    }}
                    src={'https://picsum.photos/seed/picsum/200/300'}
                    style={{ width: '96%', height: 150, marginTop: 10, objectFit: 'cover', alignSelf: 'center' }} />
            </div> */}

            <div style={{ width: '100%', marginTop: -30, paddingBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>
                    <div className='home-label' style={{ alignSelf: 'center' }}>
                        Get Work Done for Any Industry!
                    </div>

                    <div className='join-panel'>
                        <div
                            style={{ alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'center', justifyContent: 'center' }}>
                            {categories.map((item, index) => {
                                if (index >= 12)
                                    return null
                                return showCatTab(item, catEmojis[index])
                            })}
                        </div>
                        <img
                            style={{ width: '100%', borderRadius: 10, marginTop: 20, objectFit: 'contain' }}
                            src={industry} />
                    </div>

                    <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button
                            className='search-btn1'
                            style={{ marginTop: 0, fontSize: window.innerWidth < 800 ? 18 : 22, fontWeight: 'bold', alignSelf: 'center', height: window.innerWidth < 800 ? 60 : 80, width: window.innerWidth < 800 ? 350 : 500, borderRadius: 5 }}
                            onClick={() => {
                                history.push({
                                    pathname: '/post-job'
                                })
                            }}>
                            Post a Job Now
                        </button>
                    </div>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: 'white', marginTop: 20, paddingBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>
                    <div className='home-label'>
                        How to Use Cariworks!
                    </div>
                    <div
                        style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                        {showStep(1, 'Choose the service you need and fill in the request form')}
                        {showStep(2, 'Pro will contact you. Book an appointment immediately, or get quotations first')}
                        {showStep(3, 'When your Job is done, Give a rating & review to the Pro')}
                    </div>
                </div>
            </div>

            {/* {showPros('Plumber', plumberPros)} */}
            {/* {showPros('House Painting', paintPros)} */}
            {/* {showPros('Aircon Servicing', airconPros)} */}
            {/* {showPros('Domestic Mover', moverPros)} */}

            <div style={{ width: '100%', marginTop: 20, paddingBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center' }}>

                    <div className='join-panel'>
                        <div>
                            <div className='home-label'>
                                Have any skill to provide services?
                            </div>
                            <div style={{ fontSize: 22, marginLeft: 20, marginTop: 10 }}>
                                We have customers waiting for you.
                            </div>
                            <div style={{ fontSize: 22, marginLeft: 20 }}>
                                Join our network and grow your business starting today.
                            </div>
                            <button
                                className='search-btn'
                                style={{ marginTop: 20, height: 50, marginLeft: 20, width: 250 }}
                                onClick={() => {
                                    history.push({
                                        pathname: '/signup',
                                        state: { type: 'vendor' }
                                    })
                                }}>
                                Join Now
                            </button>
                        </div>
                        <img
                            className='join-panel-img'
                            style={{ objectFit: 'contain' }}
                            src={worker} />
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Home;
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Colors from '../colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from '../util/loader'
import Api from '../api/api'
import logo from '../../assets/pros_logo.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import pros_banner from '../../assets/pros_banner.png';

const QuotePopup = (props) => {
    const history = useHistory()

    const [cats, setCats] = useState([
        { id: 1, title: 'Plumber' },
        { id: 2, title: 'House Painting' },
        { id: 3, title: 'Aircon Servicing' },
        { id: 4, title: 'Domestic Mover' },
    ])
    const [selectedCat, setSelectedCat] = useState(cats[0].id)

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const submit = () => {

    };

    return (
        <div
            className='sticky'
            style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgray', borderRadius: 10, padding: 20 }}>
            <div style={{}}>
                Hire Now
            </div>
            <div style={{ color: 'gray', fontSize: 16, marginTop: 10 }}>
                Do you want to hire this PRO for your job?
                {/* Want our Pros to give their price estimate? Please choose one of the services below: */}
                {/* {props.type === 'vendor' ?
                    'Want this Pro to give his/her price estimate? Please choose one of their services below:'
                    :
                    'Want our Pros to give their price estimate? Please choose one of the services below:'
                } */}
            </div>
            {/* <div class="select_box1" style={{ marginTop: 10 }}>
                <select
                    className='picker'
                    onChange={(e) => {
                        let val = e.target.value
                        setSelectedCat(val)
                    }}>
                    {cats.map((item, index) => {
                        return (
                            <option value={item.id.toString()}>{item.title}</option>
                        )
                    })}
                </select>
            </div> */}
            <button
                onClick={() => {
                    history.push('/post-job')
                }}
                className='login-btn'
                style={{ marginTop: 10, fontSize: 17, width: '100%' }}>
                Accept this PRO
            </button>
        </div>
    )
}

export default QuotePopup; 
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useHistory } from 'react-router-dom';
import * as Colors from '../colors'
import MyLoader from '../util/loader'
import "react-datepicker/dist/react-datepicker.css"; 
import ProfileSection1 from './profile/profile-section1'
import ProfileSection2 from './profile/profile-section2'
import ProfileSection3 from './profile/profile-section3'
import ProfileSection4 from './profile/profile-section4'
import ProfileSection5 from './profile/profile-section5'
import ProfileSection6 from './profile/profile-section6'
import ProfileSection7 from './profile/profile-section7'
import { Actions } from '../redux/Actions'
import { useDispatch, useSelector } from 'react-redux';
import Api from '../api/api'
import showToast from '../util/toast'
import GLOBAL from '../constants/global.js';

var oldFileNames = ''
var sendEmail = true // when profile completes

const EditVendorProfile = () => {
    const history = useHistory()
    const dispatch = useDispatch();

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }
    // console.log('user: ' + JSON.stringify(user));

    const businessProfile = useSelector(state => state.MyReducer.businessProfile);
    const profilePercentage = useSelector(state => state.MyReducer.profilePercentage);

    const [section, setSection] = useState(1);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        loadBusinessProfile()
    }, [])

    const checkValid = (profile, key) => {
        let val = profile[key] + ''
        return val !== undefined && val != null && val !== '' && val !== 'null'
    };

    const getProfilePercentage = (profile) => {
        let profilePercentage = 20
        if (profile.business_name && checkValid(profile, 'business_name')) {
            profilePercentage = profilePercentage + 20
        }
        if (profile.services && checkValid(profile, 'services')) {
            profilePercentage = profilePercentage + 20
        }
        if (profile.phone && checkValid(profile, 'phone')) {
            profilePercentage = profilePercentage + 20
        }
        if (profile.id_number && checkValid(profile, 'id_number')) {
            profilePercentage = profilePercentage + 10
        }
        if (profile.field && checkValid(profile, 'field')) {
            profilePercentage = profilePercentage + 10
        }

        return profilePercentage
    };

    const loadBusinessProfile = () => {
        console.log('userId: ' + user.id);
        Api.getBusinessProfile(user.id,
            (response) => {
                setLoader(false)
                console.log('getBusinessProfile: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // localStorage.setItem('user', JSON.stringify(response.user))
                    if (response.profile != null) {
                        let profile = response.profile
                        profile.email = user.email
                        oldFileNames = profile.documents
                        // profile.no_of_employees = profile.no_of_employees == null ? '' : profile.no_of_employees
                        dispatch(Actions.setBusinessProfile(profile))

                        let profilePercentage = getProfilePercentage(profile)
                        if (profilePercentage >= 100) {
                            sendEmail = false // profile already complete
                        }
                        dispatch(Actions.setProfilePercentage(profilePercentage))
                    }
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                setLoader(false)
                console.log(error);
            });
    };

    const showSection = () => {
        switch (section) {
            case 1:
                return <ProfileSection1 dispatch={dispatch} actions={Actions} businessProfile={businessProfile} />
            case 2:
                return <ProfileSection2 dispatch={dispatch} actions={Actions} businessProfile={businessProfile} />
            case 3:
                return <ProfileSection3 dispatch={dispatch} actions={Actions} businessProfile={businessProfile} />
            case 4:
                return <ProfileSection4 dispatch={dispatch} actions={Actions} businessProfile={businessProfile} />
            case 5:
                return <ProfileSection5 dispatch={dispatch} actions={Actions} businessProfile={businessProfile} />
            case 6:
                return <ProfileSection6 dispatch={dispatch} actions={Actions} businessProfile={businessProfile} />
            case 7:
                return <ProfileSection7 dispatch={dispatch} actions={Actions} businessProfile={businessProfile} />
        }
    }

    const saveChanges = () => {
        // update profile info
        // setLoader(true)

        let profile = { ...businessProfile }

        profile.logo = GLOBAL.businessLogoCache
        profile.documents = GLOBAL.businessDocsCache
        profile.profile_complete = '0'

        profile.delete_files = GLOBAL.delete_files

        console.log('update businessProfile: ' + JSON.stringify(profile));

        Api.updateBusinessProfile(user.id, profile,
            (response) => {
                setLoader(false)
                if (response.status === 'success') {
                    showToast('success', 'Profile Updated!')

                    let profile_ = response['profile'] ? response['profile'] : response['$profile']
                    console.log('updated profile: ' + JSON.stringify(profile_));
                    let profilePercentage = getProfilePercentage(profile_)
                    dispatch(Actions.setProfilePercentage(profilePercentage))
                    console.log('profilePercentage: ' + profilePercentage);
                    // do not send email if it was already complete
                    if (profilePercentage == 100 && sendEmail) {
                        console.log('send email');
                        if (localStorage.getItem(user.id + 'profile_email_sent') === '1') {
                            console.log('aready sent');
                            sendEmail = false
                        }
                        else {
                            profile.profile_complete = '1'
                            Api.updateBusinessProfile(user.id, profile, null, null)
                            localStorage.setItem(user.id + 'profile_email_sent', '1')
                        }
                    }

                    // reset
                    if (GLOBAL.businessLogoCache.length > 0) {
                        GLOBAL.businessLogoCache = []
                        // update profile photo of user
                        Api.updateProfilePhoto(user.first_name, user.last_name, user.phone, profile_.logo, null, null)
                    }
                    if (GLOBAL.businessDocsCache.length > 0) {
                        GLOBAL.businessDocsCache = []
                    }
                    GLOBAL.delete_files = ''

                    if (section == 7) {
                        history.replace('/vendor-dashboard')
                    }

                    // localStorage.setItem('user', JSON.stringify(response.user))
                    // dispatch(Actions.setUser(response.user))
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                setLoader(false)
                console.log(error);
            });
    };

    const saveAndNext = () => {
        console.log('profile: ' + JSON.stringify(businessProfile));

        if (section == 1) {
            if (businessProfile.business_name === ''
                || businessProfile.detail === '') {
                showToast('error', 'Please fill the form')
                return
            }
        }
        else if (section == 2) {
            if (!checkValid(businessProfile, 'services')
                || !checkValid(businessProfile, 'skills')) {
                showToast('error', 'Please fill the form')
                return
            }
        }
        else if (section == 3) {
            if (!checkValid(businessProfile, 'phone')
                || !checkValid(businessProfile, 'address')) {
                if (businessProfile.phone.length < 10) {
                    showToast('error', 'Invalid phone');
                    return
                }
                showToast('error', 'Please fill the form')
                return
            }
            else {
                console.log('ERROR');
            }
        }
        else if (section == 4) {
            if (!checkValid(businessProfile, 'id_number')) {
                showToast('error', 'Please fill the form')
                return
            }
        }
        else if (section == 5) {
            if (!checkValid(businessProfile, 'field')
                || !checkValid(businessProfile, 'degree')) {
                showToast('error', 'Please fill the form')
                return
            }
        }

        saveChanges()

        // if (section < 5)
        //     dispatch(Actions.setProfilePercentage(profilePercentage + 15))
        // else if (section == 5)
        //     dispatch(Actions.setProfilePercentage(profilePercentage + 10))
        // saveChanges()

        if (section == 7) {
            return
        }
        let sec = section
        sec++
        setSection(sec)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1, paddingBottom: 30, height: '100%' }}>

            <div className='login quote-top' style={{ paddingLeft: 0, paddingRight: 0, marginTop: 30 }}>

                <div className='home-label' style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 20 }}>
                    Edit Business Profile
                </div>
                <div style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 16 }}>
                    Your profile is {profilePercentage}% complete
                </div>

                <div className='my-line' style={{ marginBottom: 0 }} />

                <div>
                    <div style={{ height: 5, backgroundColor: Colors.theme2, width: profilePercentage + '%' }} />
                </div>

                <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>

                    {showSection()}

                    <div
                        style={{ marginTop: 20, display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}
                        className='edit-profile-buttons'>

                        {/* {section > 1 && */}
                        <button
                            onClick={() => {
                                if (section == 1) {
                                    return
                                }
                                let sec = section
                                sec--
                                setSection(sec)
                            }}
                            className='login-btn'
                            style={{ visibility: section > 1 ? 'visible' : 'hidden', fontSize: 17, minWidth: 150, backgroundColor: 'gray' }}>
                            {'<'} Back
                        </button>

                        <button
                            onClick={saveAndNext}
                            className='login-btn'
                            style={{ fontSize: 17, minWidth: 200, }}>
                            {section == 7 ? 'Save & Exit' : 'Save Changes'}
                        </button>

                        {section < 7 &&
                            <button
                                onClick={saveAndNext}
                                className='login-btn'
                                style={{ fontSize: 17, minWidth: 150, backgroundColor: 'gray' }}>
                                {'Next >'}
                            </button>
                        }

                    </div>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    )
}

export default EditVendorProfile; 
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import logo from '../../assets/pros_logo.png';
import { useSelector } from 'react-redux';
import * as Colors from '../colors'
import Rating from '@mui/material/Rating';
import Api from '../api/api'
import MyLoader from '../util/loader'
import showToast from '../util/toast'
import avatar from '../../assets/avatar.png';

const RateVendor = () => {
    const history = useHistory()
    const location = useLocation()

    let user = useSelector(state => state.MyReducer.user);
    if(user == null && localStorage.getItem('user') != null){
        user = JSON.parse(localStorage.getItem('user'))
    }

    const vendor = location.state.vendor

    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const submitRating = () => {
        if (rating === 0 || review === '') {
            showToast('error', 'Please rate the vendor')
            return
        }

        setLoader(true)
        Api.addReview(user.id, vendor.id, rating, review, '',
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    showToast('success', 'Thanks for rating our PRO!')
                    history.goBack()
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Request failed!')
            });
    };

    return (
        <div className='main' style={{ overflow: 'scroll' }}>

            <div className='login' style={{ width: '50%', backgroundColor: 'transparent' }}>
                <div
                    className='choose-service' //
                    style={{ color: 'gray', textAlign: 'center' }}>
                    Rate Vendor
                </div>

                <div
                    style={{ alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'center', justifyContent: 'center', overflow: 'scroll', paddingBottom: 10, width:'100%' }}>

                    <div
                        style={{ display: 'flex', backgroundColor: '#fafafa', border: '1px solid ' + Colors.background, flexDirection: 'column', alignItems: 'center', padding: 15, paddingLeft: 15, paddingRight: 15, width: '100%' }}>

                        <div className='vendor-info' style={{ flexDirection: 'column', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}>
                            <img
                                className='vendor-logo-big'
                                style={{ marginLeft: 0, width: 100, height: 100 }}
                                src={avatar} />
                            <div style={{ fontSize: 17, marginTop: 5, marginLeft: 15, }}>
                                {vendor.first_name} {vendor.last_name}
                            </div>
                            <div style={{ display: 'flex', height: 28, marginTop: 5, alignSelf: 'center' }}>
                                <Rating
                                    style={{ marginLeft: 13, marginTop: 1 }}
                                    size='small'
                                    name="simple-controlled"
                                    value={rating}
                                    onChange={(event, newValue) => {
                                        setRating(newValue);
                                    }}
                                />
                            </div>
                        </div>

                        <FormGroup style={{ marginTop: 10, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', width:'100%' }}>
                            <Input
                                style={{ fontSize: 16, width: '100%', height:120 }}
                                type='textarea'
                                placeholder={'Enter review...'}
                                value={review}
                                onChange={(event) => {
                                    setReview(event.target.value)
                                }} />
                        </FormGroup>

                        <button
                            className='login-btn'
                            style={{ color: 'white', marginTop: 20, width: 200, backgroundColor: Colors.theme2, alignSelf: 'center' }}
                            onClick={submitRating}>
                            Submit
                        </button>

                    </div>
                </div>

            </div>

        </div>
    );
}

export default RateVendor; 
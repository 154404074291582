import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const MoverSection7 = (props) => {
    const history = useHistory()
    let moverJobInfo = { ...props.moverJobInfo }

    const options = [
        'Landed property',
        'High rise (with stairs)',
        'High rise (with elevator)'
    ]
    const [checked1, setChecked1] = useState(-1)
    const [checked2, setChecked2] = useState(-1)

    useEffect(() => {
        let a1 = moverJobInfo.questions[props.index1].a
        for (let i = 0; i < options.length; i++) {
            if(options[i] === a1){
                setChecked1(i)
            }
        }

        let a2 = moverJobInfo.questions[props.index2].a
        for (let i = 0; i < options.length; i++) {
            if(options[i] === a2){
                setChecked2(i+3)
            }
        }
    }, [])

    const updateAns = (index, value) => {
        console.log('value: ' + value);
        let jobInfo = {
            ...moverJobInfo,
            questions: {
                ...moverJobInfo.questions,
                [index]: {
                    ...moverJobInfo.questions[index],
                    a: value
                }
            }
        }
        props.dispatch(props.actions.setMoverJobInfo(jobInfo))
    };

    return (
        <div>

            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index1].q}
            </div>
            <div
                className='col'
                style={{ flex: 1, marginTop: 10 }}>
                {options.map((item, index) => {
                    return (
                        <div key={index.toString()} style={{ display: 'flex', marginTop: index == 0 ? 0 : 15, fontSize: 16, alignItems: 'center' }}>
                            <input
                                style={{ width: 20, height: 20 }}
                                type="radio"
                                checked={checked1 == index}
                                id={index.toString()}
                                value={index.toString()}
                                name="fav_language"
                                onChange={(e) => {
                                    updateAns(props.index1, item)
                                    setChecked1(index)
                                }} />
                            <label style={{ marginLeft: 10 }} for={index.toString()}>
                                {item}
                            </label>
                        </div>
                    )
                })}
            </div>

            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 15, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index2].q}
            </div>
            <div
                className='col'
                style={{ flex: 1, marginTop: 0 }}>
                {options.map((item, i) => {
                    let index = i+3
                    return (
                        <div key={index.toString()} style={{ display: 'flex', marginTop: index == 0 ? 0 : 15, fontSize: 16, alignItems: 'center' }}>
                            <input
                                style={{ width: 20, height: 20 }}
                                type="radio"
                                checked={checked2 == index}
                                id={index.toString()}
                                value={index.toString()}
                                name="fav_language1"
                                onChange={(e) => {
                                    updateAns(props.index2, item)
                                    setChecked2(index)
                                }} />
                            <label style={{ marginLeft: 10 }} for={index.toString()}>
                                {item}
                            </label>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default MoverSection7; 
import React, { useState, useEffect } from 'react';
import './App.css';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
// import background from './assets/home-background.png';

import Header from './cmp/header';
import Footer from './cmp/footer';
import Home from './cmp/home';
import Login from './cmp/login';
import Signup from './cmp/signup';
import ForgotPassword from './cmp/forgot_password';
import ContactUs from './cmp/contact-us';
import Terms from './cmp/terms';
import Privacy from './cmp/privacy';
import HowCustomers from './cmp/how-customers';
import HowPros from './cmp/how-pros';
import ContactForm from './cmp/contact-form';

// CUSTOMER
import Vendors from './cmp/customer/vendors';
import VendorProfile from './cmp/customer/vendor-profile';
import PlumberQuote from './cmp/customer/plumber-quote';
import AirconQuote from './cmp/customer/aircon-quote';
import MoverQuote from './cmp/customer/mover-quote';
import QuoteContactDetails from './cmp/customer/quote-contact-details';
import QuoteConfirmation from './cmp/customer/quote-confirmation';
import ChooseService from './cmp/customer/choose-service';
import EditProfile from './cmp/customer/edit-profile';
import CustomerDashboard from './cmp/customer/customer-dashboard';
import ProjectDetails from './cmp/customer/project-details';
import Favorites from './cmp/customer/favorites';
import RateVendor from './cmp/customer/rate-vendor';
import ConfirmJob from './cmp/customer/confirm-job';
import Payment from './cmp/customer/payment';

// VENDOR
import EditVendorProfile from './cmp/vendor/edit-vendor-profile';
import VendorDashboard from './cmp/vendor/vendor-dashboard';
import VendorProjectDetails from './cmp/vendor/vendor-project-details';
import SearchJobs from './cmp/vendor/search-jobs';
import VendorTerms from './cmp/vendor-terms';
import AboutUs from './cmp/about-us';

import RootStore from './cmp/redux/RootStore';
import { Provider } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import PostJob from './cmp/customer/post-job';

const HomeContainer = () => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

      <div style={{ width: '100%', height: '100vh' }}>
        <Header />
        <div style={{ marginTop: 60 }}>
          <Route path='/' exact component={Home} />
          <Route path='/login' exact component={Login} />
          <Route path='/signup' exact component={Signup} />
          <Route path='/reset-password' exact component={ForgotPassword} />
          <Route path='/contact-us' exact component={ContactUs} />
          <Route path='/terms' exact component={Terms} />
          <Route path='/privacy' exact component={Privacy} />
          <Route path='/how-customers' exact component={HowCustomers} />
          <Route path='/how-pros' exact component={HowPros} />
          <Route path='/contact-form' exact component={ContactForm} />
          <Route path='/vendor/:id' exact component={VendorProfile} />
          <Route path='/vendors/:category' exact component={Vendors} />
          <Route path='/plumber-quote' exact component={PlumberQuote} />
          <Route path='/post-job' exact component={PostJob} />
          <Route path='/confirm-job' exact component={ConfirmJob} />
          <Route path='/payment' exact component={Payment} />
          <Route path='/aircon-quote' exact component={AirconQuote} />
          <Route path='/mover-quote' exact component={MoverQuote} />
          <Route path='/quote-contact-details' exact component={QuoteContactDetails} />
          <Route path='/quote-confirmation' exact component={QuoteConfirmation} />
          {/* <Route path='/choose-service' exact component={ChooseService} /> */}
          <Route path='/edit-profile' exact component={EditProfile} />
          <Route path='/customer-dashboard' exact component={CustomerDashboard} />
          <Route path='/project-details' exact component={ProjectDetails} />
          <Route path='/favorites' exact component={Favorites} />
          <Route path='/rate-vendor' exact component={RateVendor} />
          <Route path='/edit-vendor-profile' exact component={EditVendorProfile} />
          <Route path='/vendor-dashboard' exact component={VendorDashboard} />
          <Route path='/vendor-project-details' exact component={VendorProjectDetails} />
          <Route path='/search-jobs' exact component={SearchJobs} />
          <Route path='/vendor-terms' exact component={VendorTerms} />
          <Route path='/about-us' exact component={AboutUs} />
        </div>

        <Footer />
      </div>
    </div>
  )
}

class App extends React.Component {

  componentDidMount = () => {
    console.log('MOUNT');
  };

  render() {
    return (
      <Provider store={RootStore}>
        <Router>
          <div>
            <Switch>
              {/* <Route exact path="/(admin)" component={LoginContainer} /> */}
              {/* <Route exact path="/admin/*" component={AdminHomeContainer} /> */}
              <Route component={HomeContainer} />
            </Switch>
            <ToastContainer />
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App;
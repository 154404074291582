import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

let years = []
for (let index = 0; index <= 30; index++) {
    let year = 2022 - index;
    years.push(year)
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const fieldsOfEducation = [
    "Accounting",
    "Aerospace Engineering",
    "Agricultural Science",
    "Anthropology",
    "Architecture",
    "Art History",
    "Biochemistry",
    "Biology",
    "Biomedical Engineering",
    "Business Administration",
    "Chemical Engineering",
    "Chemistry",
    "Civil Engineering",
    "Computer Science",
    "Criminology",
    "Dentistry",
    "Design",
    "Economics",
    "Education",
    "Electrical Engineering",
    "Environmental Science",
    "Finance",
    "Fine Arts",
    "Geography",
    "Geology",
    "Graphic Design",
    "History",
    "Human Resources",
    "Industrial Engineering",
    "Information Technology",
    "International Relations",
    "Journalism",
    "Law",
    "Linguistics",
    "Literature",
    "Marketing",
    "Mathematics",
    "Mechanical Engineering",
    "Medicine",
    "Microbiology",
    "Music",
    "Nursing",
    "Pharmacy",
    "Philosophy",
    "Physics",
    "Political Science",
    "Psychology",
    "Public Administration",
    "Public Health",
    "Sociology",
    "Software Engineering",
    "Statistics",
    "Theater",
    "Urban Planning",
    "Veterinary Medicine",
    "Zoology"
]

const ProfileSection5 = (props) => {
    const history = useHistory()
    let businessProfile = { ...props.businessProfile }

    const [degree, setDegree] = useState('');
    const [field, setField] = React.useState([]);

    const onChangeField = (event) => {
        const {
            target: { value },
        } = event;
        setField(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        updateValue('field', value)
    };

    const checkValid = (key) => {
        return businessProfile[key] !== undefined && businessProfile[key] != null && businessProfile[key] !== '' && businessProfile[key] !== 'null'
    };

    useEffect(() => {
        if (checkValid('degree'))
            setDegree(businessProfile.degree)
        if (checkValid('field'))
            setField(
                // On autofill we get a stringified value.
                typeof businessProfile.field === 'string' ? businessProfile.field.split(',') : businessProfile.field,
            )
    }, [props])

    const updateValue = (key, value) => {
        businessProfile[key] = value
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    return (
        <div>

            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Education
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: 20 }}>
                <FormControl sx={{ m: 0, minWidth: 150 }} size="small">
                    <InputLabel id="demo-multiple-name-label">Field of Education</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={field}
                        label='Field (i.e Accounting)'
                        onChange={onChangeField}
                        MenuProps={MenuProps}>
                        {fieldsOfEducation.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <TextField
                    id="outlined-basic"
                    label="Degree"
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    value={degree}
                    size='small'
                    onChange={(event) => {
                        setDegree(event.target.value)
                        updateValue('degree', event.target.value)
                    }} />
            </div>

        </div>
    )
}

export default ProfileSection5;
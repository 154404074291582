
import { encode } from "base-64";

// const baseUrl = 'https://obscure-dusk-99086-5c4805b82656.herokuapp.com/http://54.169.33.241/apis/public/api'
// const baseUrlImg = 'https://obscure-dusk-99086-5c4805b82656.herokuapp.com/http://54.169.33.241/apis/public'

// const baseUrl = 'http://107.180.41.157/cariwork_api/public/api'
// const baseUrl = '/api'
// const baseUrlImg = '/'
const baseUrl = 'https://cariworks.com/cariwork_api/public/api'
const baseUrlImg = 'https://cariworks.com/cariwork_api/public/'

export const jobPhotosUrl = baseUrlImg + 'images/jobPhotos/'
export const photosUrl = baseUrlImg + 'images/'
export const documentsUrl = baseUrlImg + 'images/documents/'
export const chatsUrl = baseUrlImg + 'chat/files/'
// export const chatsUrl = 'https://obscure-dusk-99086-5c4805b82656.herokuapp.com/http://54.169.33.241/images/chat/'

const customerProfile = baseUrl + '/customerProfile';
const createUser = baseUrl + '/createUser';
const loginUser = baseUrl + '/loginUser';
const forgotPassword = baseUrl + '/forgotPassword';
const updateProfile = baseUrl + '/updateProfile';
const postNewJob = baseUrl + '/postNewJob';
const myJobs = baseUrl + '/myJobs';
const cancelJob = baseUrl + '/cancelJob';
const acceptQuote = baseUrl + '/acceptQuote';
const vendorProfile = baseUrl + '/vendorProfile';
const addVendorToFavorite = baseUrl + '/addVendorToFavorite';
const favoriteVendors = baseUrl + '/favoriteVendors';
const getBusinessProfile = baseUrl + '/getBusinessProfile';
const updateBusinessProfile = baseUrl + '/updateBusinessProfile';
const searchJobs = baseUrl + '/searchJobs';
const appliedJobs = baseUrl + '/appliedJobs';
const getNotifications = baseUrl + '/getNotifications';
const updateCustomer = baseUrl + '/updateCustomer'; // to vendor
const createJobQuote = baseUrl + '/createJobQuote';
const addReview = baseUrl + '/addReview';
const chatMessage = baseUrl + '/chatMessage';
const submitQuery = baseUrl + '/submitQuery';
const getChatMessages = baseUrl + '/getChatMessages';
const sendNotification = baseUrl + '/sendNotification';
const getCariData = baseUrl + '/getCariData';

const ContentType = 'multipart/form-data';
const headers = {
    'Content-Type': ContentType
}

const getAccessToken = () => {
    let user = localStorage.getItem('user')
    if (user != null) {
        let access_token = JSON.parse(user).access_token
        console.log('access_token: ' + access_token);
        return access_token
    }
};

const getUserId = () => {
    let user = localStorage.getItem('user')
    if (user != null) {
        let id = JSON.parse(user).id
        console.log('id: ' + id);
        return id
    }
}

const Api = {
    getCariData: async function (onSuccess, onError) {
        let params = new FormData()
        params.append('test', '')
        apiCall(getCariData, params, 'POST', headers, onSuccess, onError)
    },

    stripeToken: async function (number, exp_month, exp_year, cvc, onSuccess, onError) {
        Api.getCariData(
            (res) => {
                let url = 'https://api.stripe.com/v1/tokens'
                let headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + res.token
                }
                let params = {
                    'card[number]': number,
                    'card[exp_month]': exp_month,
                    'card[exp_year]': exp_year,
                    'card[cvc]': cvc
                }
                var formBody = [];
                for (var property in params) {
                    var encodedKey = encodeURIComponent(property);
                    var encodedValue = encodeURIComponent(params[property]);
                    formBody.push(encodedKey + "=" + encodedValue);
                }
                formBody = formBody.join("&");
                console.log('formBody: ' + formBody);
                // remove all white spaces
                formBody = formBody.replace(/%20/g, "");
                apiCallPayment(url, formBody, 'POST', headers, onSuccess, onError)
            },
            () => {
                //
            })
    },

    stripePayment: async function (tokenId, amount, onSuccess, onError) {
        Api.getCariData(
            (res) => {
                let url = 'https://api.stripe.com/v1/charges'
                let headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + res.token1
                }
                let params = {
                    'source': tokenId,
                    'amount': amount * 100,
                    'currency': 'USD',
                }
                var formBody = [];
                for (var property in params) {
                    var encodedKey = encodeURIComponent(property);
                    var encodedValue = encodeURIComponent(params[property]);
                    formBody.push(encodedKey + "=" + encodedValue);
                }
                formBody = formBody.join("&");
                console.log('formBody: ' + formBody);
                apiCallPayment(url, formBody, 'POST', headers, onSuccess, onError)
            },
            () => {
                //
            })
    },

    payViaPayPal: (username, password, price, quantity, onSuccess, onError) => {
        let url = 'https://api.paypal.com/v1/payments/payment'
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encode(username + ':' + password)
        }
        let params = {
            "intent": "sale",
            "payer": {
                "payment_method": "paypal"
            },
            "transactions": [
                {
                    "amount": {
                        "total": '' + (price * quantity),
                        "currency": "USD"
                    },
                    "description": "Cariworks Payment.",
                    "item_list": {
                        "items": [
                            {
                                "name": "Cariworks Payment",
                                "quantity": '1',
                                "price": '' + (price * quantity),
                                "currency": "USD"
                            }
                        ],
                    }
                }
            ],
            "redirect_urls": {
                "return_url": "https://caribrunch.com/register",
                "cancel_url": "https://caribrunch.com/register"
            }
        }

        console.log('params: ' + JSON.stringify(params));

        apiCallPayment(url, JSON.stringify(params), 'POST', headers, onSuccess, onError)
    },

    sendNotification: async function (user_id, job_id, title, detail, type, onSuccess, onError) {
        let params = new FormData()
        params.append('user_id', user_id)
        params.append('job_id', job_id)
        params.append('title', title)
        params.append('detail', detail)
        params.append('type', type)
        apiCall(sendNotification, params, 'POST', headers, onSuccess, onError)
    },

    getChatMessages: async function (job_id, customer_id, vendor_id, sender, onSuccess, onError) {
        let params = new FormData()
        // console.log('job_id:' + job_id);
        // console.log('customer_id:' + customer_id);
        console.log('vendor_id:' + vendor_id);
        // console.log('sender:' + sender);

        params.append('job_id', job_id)
        params.append('customer_id', customer_id)
        params.append('vendor_id', vendor_id)
        params.append('sender', sender)
        apiCall(getChatMessages, params, 'POST', headers, onSuccess, onError)
    },

    submitQuery: async function (name, email, msg, onSuccess, onError) {
        let params = new FormData()
        params.append('name', name)
        params.append('email', email)
        params.append('msg', msg)
        apiCall(submitQuery, params, 'POST', headers, onSuccess, onError)
    },

    chatMessage: async function (job_id, customer_id, vendor_id, message, sender, attached_file, onSuccess, onError) {
        let params = new FormData()
        params.append('job_id', job_id)
        params.append('customer_id', customer_id)
        params.append('vendor_id', vendor_id)
        params.append('message', message)
        params.append('sender', sender)
        if (attached_file != null)
            params.append('attached_file', attached_file)
        apiCall(chatMessage, params, 'POST', headers, onSuccess, onError)
    },

    addReview: async function (customer_id, vendor_id, rating, review, tags, onSuccess, onError) {
        let params = new FormData()
        params.append('customer_id', customer_id)
        params.append('vendor_id', vendor_id)
        params.append('rating', rating)
        params.append('review', review)
        params.append('tags', tags)
        apiCall(addReview, params, 'POST', headers, onSuccess, onError)
    },

    getBusinessProfile: async function (vendor_id, onSuccess, onError) {
        let params = new FormData()
        params.append('vendor_id', vendor_id)
        apiCall(getBusinessProfile, params, 'POST', headers, onSuccess, onError)
    },

    createJobQuote: async function (job_id, vendor_id, price_type, price, proposal, onSuccess, onError) {
        let params = new FormData()
        console.log('job_id:' + job_id);
        console.log('vendor_id:' + vendor_id);
        console.log('price_type:' + price_type);
        console.log('price:' + price);
        params.append('job_id', job_id)
        params.append('vendor_id', vendor_id)
        params.append('price_type', price_type)
        params.append('price', price === '' ? '0' : price)
        params.append('proposal', proposal)
        apiCall(createJobQuote, params, 'POST', headers, onSuccess, onError)
    },

    updateCustomer: async function (customer_id, onSuccess, onError) {
        let params = new FormData()
        params.append('customer_id', customer_id)
        params.append('account_status', 'pending')
        apiCall(updateCustomer, params, 'POST', headers, onSuccess, onError)
    },

    getNotifications: async function (user_id, onSuccess, onError) {
        let params = new FormData()
        params.append('user_id', user_id)
        apiCall(getNotifications, params, 'POST', headers, onSuccess, onError)
    },

    appliedJobs: async function (vendor_id, onSuccess, onError) {
        let params = new FormData()
        params.append('vendor_id', vendor_id)
        apiCall(appliedJobs, params, 'POST', headers, onSuccess, onError)
    },

    searchJobs: async function (vid, job_type, state, onSuccess, onError) {
        let params = new FormData()
        params.append('vendor_id', vid)
        params.append('job_type', job_type)
        params.append('state', state)
        apiCall(searchJobs, params, 'POST', headers, onSuccess, onError)
    },

    updateBusinessProfile: async function (vendor_id, businessProfile, onSuccess, onError) {
        let params = new FormData()
        params.append('vendor_id', vendor_id)
        params.append('business_name', businessProfile.business_name)
        params.append('detail', businessProfile.detail)
        params.append('services', businessProfile.services)
        params.append('skills', businessProfile.skills)
        params.append('email', businessProfile.email)
        params.append('phone', businessProfile.phone)
        params.append('whatsapp', businessProfile.whatsapp)
        params.append('fax', businessProfile.fax)
        params.append('address', businessProfile.address)
        params.append('allow_notifications', businessProfile.allow_notifications)
        params.append('id_number', businessProfile.id_number)
        params.append('field', businessProfile.field)
        params.append('degree', businessProfile.degree)
        params.append('credentials', businessProfile.credentials)
        params.append('links', businessProfile.links)
        params.append('profile_complete', businessProfile.profile_complete)

        // new
        if (businessProfile.delete_files != undefined && businessProfile.delete_files != null && businessProfile.delete_files !== '')
            params.append('delete_files', businessProfile.delete_files)

        if (businessProfile.documents.length > 0) {
            for (let i = 0; i < businessProfile.documents.length; i++) {
                params.append('documents[]', businessProfile.documents[i])
            }
        }
        if (businessProfile.logo.length > 0)
            params.append('logo', businessProfile.logo[0])

        apiCall(updateBusinessProfile, params, 'POST', headers, onSuccess, onError)
    },

    favoriteVendors: async function (customer_id, onSuccess, onError) {
        let params = new FormData()
        params.append('customer_id', customer_id)
        apiCall(favoriteVendors, params, 'POST', headers, onSuccess, onError)
    },

    addVendorToFavorite: async function (customer_id, vendor_id, onSuccess, onError) {
        let params = new FormData()
        params.append('customer_id', customer_id)
        params.append('vendor_id', vendor_id)
        apiCall(addVendorToFavorite, params, 'POST', headers, onSuccess, onError)
    },

    vendorProfile: async function (id, customer_id, onSuccess, onError) {
        let params = new FormData()
        params.append('id', id)
        params.append('customer_id', customer_id)
        apiCall(vendorProfile, params, 'POST', headers, onSuccess, onError)
    },

    acceptQuote: async function (quote_id, onSuccess, onError) {
        let params = new FormData()
        params.append('quote_id', quote_id)
        apiCall(acceptQuote, params, 'POST', headers, onSuccess, onError)
    },

    cancelJob: async function (job_id, customer_id, onSuccess, onError) {
        let params = new FormData()
        params.append('job_id', job_id)
        params.append('customer_id', customer_id)
        apiCall(cancelJob, params, 'POST', headers, onSuccess, onError)
    },

    myJobs: async function (customer_id, onSuccess, onError) {
        let params = new FormData()
        params.append('customer_id', customer_id)
        apiCall(myJobs, params, 'POST', headers, onSuccess, onError)
    },

    postNewJob: async function (job, onSuccess, onError) {
        console.log('api postNewJob');

        let params = new FormData()
        params.append('access_token', getAccessToken())
        params.append('title', job.title)
        params.append('detail', job.detail)
        params.append('salary', job.salary)
        params.append('country', job.country)
        params.append('location', job.location)
        params.append('jobType', job.jobType)

        let skills = ''
        for (let i = 0; i < job.skills.length; i++) {
            skills = skills + job.skills[i] + ','
        }
        skills = skills.substring(0, skills.length - 1)
        console.log('skills: ' + skills);

        params.append('skills', job.skills)
        params.append('preferred_date', job.preferredDate)
        params.append('preferred_time', job.timesSelected)
        for (let i = 0; i < job.photos.length; i++) {
            params.append('photos[]', job.photos[i])
        }
        apiCall(postNewJob, params, 'POST', headers, onSuccess, onError)
    },

    createUser: async function (first_name, last_name, email, phone, user_type, social_id, password, onSuccess, onError) {
        let params = new FormData()
        params.append('first_name', first_name)
        params.append('last_name', last_name)
        params.append('email', email)
        params.append('phone', phone)
        params.append('company', '')
        params.append('location', '')
        params.append('user_type', user_type)
        params.append('social_id', social_id)
        params.append('password', password)
        params.append('account_status', user_type === 'customer' ? 'active' : 'pending')
        params.append('photo', '')
        apiCall(createUser, params, 'POST', headers, onSuccess, onError)
    },
    
    forgotPassword: async function (email, onSuccess, onError) {
        let params = new FormData()
        params.append('email', email)
        apiCall(forgotPassword, params, 'POST', headers, onSuccess, onError)
    },
    customerProfile: async function (customer_id, onSuccess, onError) {
        let params = new FormData()
        params.append('access_token', getAccessToken())
        params.append('customer_id', customer_id)
        apiCall(customerProfile, params, 'POST', headers, onSuccess, onError)
    },
    loginUser: async function (email, password, social_id, onSuccess, onError) {
        let params = new FormData()
        params.append('email', email)
        params.append('password', password)
        params.append('social_id', social_id)
        apiCall(loginUser, params, 'POST', headers, onSuccess, onError)
    },
    updateProfile: async function (first_name, last_name, phone, company, location, old_password, new_password, onSuccess, onError) {
        let params = new FormData()
        params.append('user_id', getUserId())
        params.append('first_name', first_name)
        params.append('last_name', last_name)
        params.append('phone', phone)
        params.append('company', company)
        params.append('location', location)
        params.append('old_password', old_password)
        params.append('new_password', new_password)
        params.append('photo', '')
        apiCall(updateProfile, params, 'POST', headers, onSuccess, onError)
    },
    updateProfilePhoto: async function (first_name, last_name, phone, photo, onSuccess, onError) {
        let params = new FormData()
        params.append('user_id', getUserId())
        params.append('first_name', first_name)
        params.append('last_name', last_name)
        params.append('phone', phone)
        params.append('photo', photo) // string
        params.append('old_password', '')
        params.append('new_password', '')
        apiCall(updateProfile, params, 'POST', headers, onSuccess, onError)
    },
}

const apiCallTxt = (url, params, method, headers, onSuccess, onError) => {
    console.log('url: ' + url);
    fetch(url, {
        method: method,
        redirect: 'follow',
        body: params
    })
        .then(response => response.text())
        .then(result => {
            console.log(JSON.stringify(result));
            onSuccess(result);
        })
        .catch(err => {
            // console.log(err)
            onError(err);
        });
};

const apiCallPayment = (url, params, method, headers, onSuccess, onError) => {
    // console.log('url: ' + url);
    fetch(url, {
        method: method,
        headers: headers,
        body: params
    })
        .then(response => response.json())
        .then(result => {
            // console.log(JSON.stringify(result));
            onSuccess(result);
        })
        .catch(err => {
            console.log(err)
            onError(err);
        });
};

const apiCall = (url, params, method, headers, onSuccess, onError) => {

    // https://apiproxy-nspjnq4koq-uc.a.run.app
    // fetch('https://apiproxy-nspjnq4koq-uc.a.run.app/apiProxy?api_url=' + url, {
    //     method: method,
    //     body: params
    // })
    //     .then(response => response.json())
    //     .then(result => {
    //         // console.log(JSON.stringify(result));
    //         if (onSuccess != null)
    //             onSuccess(result);
    //     })
    //     .catch(err => {
    //         // console.log(err)
    //         if (onError != null)
    //             onError(err);
    //     });

    console.log('url: ' + url);
    fetch(url, {
        method: method,
        body: params
    })
        .then(response => response.json())
        .then(result => {
            // console.log(JSON.stringify(result));
            if (onSuccess != null)
                onSuccess(result);
        })
        .catch(err => {
            // console.log(err)
            if (onError != null)
                onError(err);
        });
};

export default Api;

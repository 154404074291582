import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as Colors from '../../colors'
import * as Constants from '../../constants/constants'
import { useStyles } from '../../util/picker';

const ProfileSection2 = (props) => {
    const history = useHistory()

    const [services, setServices] = useState([])
    const [skills, setSkills] = useState([])

    let businessProfile = { ...props.businessProfile }

    const checkValid = (key) => {
        return businessProfile[key] !== undefined && businessProfile[key] != null && businessProfile[key] !== '' && businessProfile[key] !== 'null'
    };

    useEffect(() => {
        if (checkValid('services')) {
            let arr = businessProfile.services.split(',')
            let temp = []
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                if (item === '')
                    continue
                temp.push(item)
            }
            setServices(temp)
        }
        if (checkValid('skills')) {
            let arr = businessProfile.skills.split(',')
            let temp = []
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                if (item === '')
                    continue
                temp.push(item)
            }
            setSkills(temp)
        }
    }, [props])

    const updateServices = (arr) => {
        let services = ''
        for (let i = 0; i < arr.length; i++) {
            services = services + arr[i] + ','
        }
        services = services.substring(0, services.length - 1)
        businessProfile['services'] = services
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    const updateSkills = (arr) => {
        let skills = ''
        for (let i = 0; i < arr.length; i++) {
            skills = skills + arr[i] + ','
        }
        skills = skills.substring(0, skills.length - 1)
        businessProfile['skills'] = skills
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Job Details
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 5, display: 'flex', flexDirection: 'column' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: 15 }}>
                    <div style={{ fontSize: 16 }}>Services Provided</div>
                    {services.map((item, index) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', marginTop: 5, height: 45, alignItems: 'center' }}>
                                    <div
                                        style={{ flex: 1, borderTopRightRadius: 5, borderBottomRightRadius: 5, fontSize: 16, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: Colors.theme1, textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                                        <input
                                            type='text'
                                            placeholder=''
                                            style={{ fontSize: 16, border: '0px', width: '100%', height: '100%', paddingLeft: 15, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                                            value={item}
                                            onChange={(e) => {
                                                const list = services.map((item_) => {
                                                    if (item === item_) {
                                                        return e.target.value;
                                                    }
                                                    else {
                                                        return item_;
                                                    }
                                                });
                                                setServices(list)
                                                updateServices(list)
                                            }} />
                                    </div>
                                    <div style={{ width: 30, display: 'flex', justifyContent: 'flex-end' }}>
                                        <button
                                            onClick={() => {
                                                const temp = services.filter((item, j) => {
                                                    return index !== j; //false will delete
                                                });
                                                setServices(temp)
                                            }}
                                            style={{ border: '0px', fontSize: 14, backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>
                                            X
                                        </button>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                    <button
                        onClick={() => {
                            let a = [...services]
                            a.push('')
                            setServices(a)
                        }}
                        className='login-btn vendor-view-profile'
                        style={{ height: 45, fontSize: 17, marginTop: 10, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                        Add
                    </button>
                </div>
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: 15 }}>
                    <div style={{ fontSize: 16 }}>Skill Set</div>
                    {skills.map((item, index) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', marginTop: 5, height: 45, alignItems: 'center' }}>
                                    <div
                                        style={{ flex: 1, borderTopRightRadius: 5, borderBottomRightRadius: 5, fontSize: 16, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: Colors.theme1, textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                                        <input
                                            type='text'
                                            placeholder=''
                                            style={{ fontSize: 16, border: '0px', width: '100%', height: '100%', paddingLeft: 15, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                                            value={item}
                                            onChange={(e) => {
                                                const list = skills.map((item_) => {
                                                    if (item === item_) {
                                                        return e.target.value;
                                                    }
                                                    else {
                                                        return item_;
                                                    }
                                                });
                                                setSkills(list)
                                                updateSkills(list)
                                            }} />
                                    </div>
                                    <div style={{ width: 30, display: 'flex', justifyContent: 'flex-end' }}>
                                        <button
                                            onClick={() => {
                                                const temp = skills.filter((item, j) => {
                                                    return index !== j; //false will delete
                                                });
                                                setSkills(temp)
                                            }}
                                            style={{ border: '0px', fontSize: 14, backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>
                                            X
                                        </button>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                    <button
                        onClick={() => {
                            let a = [...skills]
                            a.push('')
                            setSkills(a)
                        }}
                        className='login-btn vendor-view-profile'
                        style={{ height: 45, fontSize: 17, marginTop: 10, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                        Add
                    </button>
                </div>
            </div>

        </div>
    )
}

export default ProfileSection2; 
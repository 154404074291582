import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';

const MoverSection9 = (props) => {
    const history = useHistory()
    let moverJobInfo = { ...props.moverJobInfo }

    const [txt, setTxt] = useState('');

    useEffect(() => {
        let a = moverJobInfo.questions[props.index].a
        setTxt(a)
    }, [])

    const updateAns = (index, value) => {
        console.log('value: ' + value);
        let jobInfo = {
            ...moverJobInfo,
            questions: {
                ...moverJobInfo.questions,
                [index]: {
                    ...moverJobInfo.questions[index],
                    a: value
                }
            }
        }
        props.dispatch(props.actions.setMoverJobInfo(jobInfo))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index].q} This helps the vendor give a better estimate.
            </div>
            <div
                className='col'
                style={{ flex: 1, marginTop: 15 }}>
                <FormGroup>
                    <Input
                        style={{ fontSize: 18 }}
                        type='textarea'
                        placeholder=''
                        value={txt}
                        onChange={(event) => {
                            setTxt(event.target.value)
                            updateAns(props.index, event.target.value)
                        }} />
                </FormGroup>
            </div>

        </div>
    )
}

export default MoverSection9; 
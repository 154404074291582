import React, { useState, useRef, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as Colors from '../../colors'
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import GLOBAL from '../../constants/global.js';
import { photosUrl } from '../../api/api'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Plumber',
    'Aircon',
    'House Paint',
    'Domestic Mover'
]

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ProfileSection1 = (props) => {
    const history = useHistory()

    const businessProfile = { ...props.businessProfile }
    console.log('section1: ' + JSON.stringify(businessProfile));

    const [bname, setBname] = useState(businessProfile.business_name);
    const [detail, setDetail] = useState('');

    const addFile = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [logoUrl, setLogoUrl] = useState('');

    console.log('logoUrl: ' + logoUrl);

    const checkValid = (key) => {
        return businessProfile[key] !== undefined && businessProfile[key] != null && businessProfile[key] !== '' && businessProfile[key] !== 'null'
    };

    useEffect(() => {
        if (checkValid('business_name'))
            setBname(businessProfile.business_name)
        if (checkValid('detail'))
            setDetail(businessProfile.detail)
        if (checkValid('services'))
            setServices(
                typeof businessProfile.services === 'string' ? businessProfile.services.split(',') : businessProfile.services,
            );
        if (checkValid('logo'))
            setLogoUrl(businessProfile.logo)
    }, [props])

    const onFilesSelect = (event) => {
        let files = Array.from(event.target.files)
        setSelectedFiles(files)
        GLOBAL.businessLogoCache = files
        setLogoUrl(URL.createObjectURL(files[0]))
    };

    const theme = useTheme();
    const [services, setServices] = React.useState([]);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setServices(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        updateValue('services', value)
    };

    const updateValue = (key, value) => {
        businessProfile[key] = value
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Basic Information
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>

                <TextField
                    id="outlined-basic"
                    label="Job Title"
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    value={bname}
                    size='small'
                    onChange={(event) => {
                        setBname(event.target.value)
                        updateValue('business_name', event.target.value)
                    }} />

                <div style={{ display: 'flex', width: '100%', marginTop: 10, height: 45, alignItems: 'center' }}>
                    <div style={{ display: 'flex', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, paddingLeft: 10, paddingRight: 10, marginRight: -1, fontSize: 18, color: 'gray', backgroundColor: 'white', border: '1px solid lightgray', height: 45, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        Profile Photo
                    </div>
                    <button
                        onClick={() => {
                            addFile.current.click();
                        }}
                        style={{ flex: 1, borderTopRightRadius: 5, fontSize: 16, borderBottomRightRadius: 5, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: Colors.theme1 }}>
                        {selectedFiles.length > 0 ?
                            'File Choosen (Click to change)'
                            :
                            'Choose File'
                        }
                    </button>
                    <input
                        type='file'
                        multiple="multiple"
                        style={{ display: 'none' }}
                        ref={addFile}
                        onChange={(event) => {
                            onFilesSelect(event)
                        }} />
                </div>

                {logoUrl.includes('http') ?
                    <img src={logoUrl} style={{ width: 100, height: 100, backgroundColor: 'gray' }} />
                    :
                    <img src={photosUrl + 'logos/' + logoUrl} style={{ width: 100, height: 100, backgroundColor: 'gray' }} />
                }

                <TextField
                    style={{ marginTop: 15 }}
                    id="outlined-textarea"
                    label="Bio"
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={detail}
                    onChange={(event) => {
                        setDetail(event.target.value)
                        updateValue('detail', event.target.value)
                    }} />

                {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: 15 }}>
                    <FormControl sx={{ m: 0, minWidth: 150 }} size="small">
                        <InputLabel id="demo-multiple-name-label">Service</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={services}
                            label='Choose'
                            onChange={handleChange}
                            MenuProps={MenuProps}>
                            {names.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, services, theme)}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div> */}

            </div>

        </div>
    )
}

export default ProfileSection1; 
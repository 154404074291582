import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useHistory, useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import mover1 from '../../assets/mover1.png';
import mover2 from '../../assets/mover2.png';
import mover3 from '../../assets/mover3.png';
import avatar from '../../assets/avatar.png';
import arrow_right from '../../assets/arrow_right.png';
import * as Colors from '../colors'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { photosUrl } from '../api/api'

const QuotesView = (props) => {
    const history = useHistory()
    const [loader, setLoader] = useState(false);

    const job = props.job
    const acceptedQid = props.acceptedQid

    const { category } = useParams()

    const checkValid = (val) => {
        return val !== undefined && val != null && val !== '' && val !== 'null'
    };

    let quotes_ = job.quotes
    let arr = []
    let firstQuoteId = ''
    for (let i = 0; i < quotes_.length; i++) {
        const quote = quotes_[i];
        if (i == 0)
            firstQuoteId = quote.id
        arr.push({
            id: quote.id,
            title: quote.vendor.first_name + ' ' + quote.vendor.last_name,
            icon: checkValid(quote.vendor.photo) ? photosUrl + '/logos/' + quote.vendor.photo : avatar,
            reviews: quote.no_of_reviews,
            rating: quote.rating,
            price: '$' + quote.price,
            time: moment(quote.created_at).format('DD-MM-YYYY h:m a'),
        })
    }
    const [quotes, setQuotes] = useState(arr)
    const [selected, setSelected] = useState(acceptedQid === '' ? firstQuoteId : acceptedQid)

    useEffect(() => {
        //
    }, [])

    const showItem = (list, item, index, accepted, selected) => {
        return (
            <button
                onClick={() => {
                    setSelected(item.id)
                    props.quoteSelected(item.id)
                }}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10, width: '100%', textAlign: 'start', backgroundColor: accepted ? Colors.theme3 : '#fafafa' }}
                className={selected === item.id ? 'hover-effect2-sel' : 'hover-effect2'}>
                {accepted ?
                    <div style={{ alignSelf: 'flex-start', color: 'green' }}>
                        Accepted
                    </div>
                    :
                    null
                }
                <div className='vendor-info' style={{ alignSelf: 'flex-start' }}>

                    <img
                        className='vendor-logo-big'
                        style={{ marginLeft: 0, marginTop: 8, borderRadius: 50, border: '1px solid lightgray', backgroundColor:'white' }}
                        src={item.icon} />

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ fontSize: 15, marginTop: 5, marginLeft: 10, }}>
                            {item.title}
                        </div>
                        <div style={{ display: 'flex', height: 28 }}>
                            <Rating
                                readOnly
                                style={{ marginLeft: 8, marginTop: 5 }}
                                size='small'
                                name="simple-controlled"
                                value={item.rating}
                                onChange={(event, newValue) => {
                                    // setValue(newValue);
                                }}
                            />
                        </div>
                        <p style={{ marginLeft: 8, fontSize: 14, color: 'gray' }}>({item.reviews} Reviews)</p>
                    </div>
                    <img
                        style={{ alignSelf: 'center', width: 30, height: 30, marginLeft: 20 }}
                        src={arrow_right} />
                </div>
                <div style={{ marginTop: -10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ fontSize: 15, }}>
                        {item.price}
                    </div>
                    <div style={{ fontSize: 12, color: 'gray' }}>
                        {item.time}
                    </div>
                </div>
                <div className='my-line' style={{ marginTop: 10, marginBottom: -10 }} />
            </button>
        )
    };

    return (
        <div style={{ display: 'flex', flex: 1, minWidth: 270, backgroundColor: '#fafafa', padding: 0, marginRight: 20, border: '1px solid #f2f2f2' }}>

            <div style={{ padding: 0, fontSize: 18, width: '100%' }}>
                <div style={{ padding: 10, fontSize: 16, color: 'gray' }}>
                    Quotes Recieved({quotes.length})
                </div>
                <div className='my-line' style={{ marginTop: 0, marginBottom: 0 }} />
                {/* {aQuotes.map((item, index) => {
                    return showItem(aQuotes, item, index, true)
                })} */}
                {quotes.map((item, index) => {
                    if (item.id != acceptedQid)
                        return null
                    return showItem(quotes, item, index, true, selected)
                })}
                {quotes.map((item, index) => {
                    if (item.id == acceptedQid)
                        return null
                    return showItem(quotes, item, index, false, selected)
                })}

            </div>

        </div>
    );
}

export default QuotesView; 

import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import facebook from '../assets/facebook.png';
import google from '../assets/google.png';
import showToast from './util/toast'
import GoogleLogin from 'react-google-login';
import { googleClientID, fbAppID } from './constants/credentials';
import { gapi } from 'gapi-script'
import FacebookLogin from 'react-facebook-login'
import { Actions } from './redux/Actions'
import { useDispatch } from 'react-redux';

const Login = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [loader, setLoader] = useState(false);

    const start = () => {
        gapi.client.init({
            clientId: googleClientID,
            scope: 'email',
        });
    }

    useEffect(() => {
        gapi.load('client:auth2', start);
    }, []);

    const loginUser = () => {
        if (email === '' || password === '') {
            return
        }

        setLoader(true)
        Api.loginUser(email, password, '',
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    localStorage.setItem('user', JSON.stringify(response.user))
                    dispatch(Actions.setUser(response.user))
                    if (response.user.user_type === 'customer')
                        history.push('/customer-dashboard')
                    else
                        history.push('/vendor-dashboard')
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                // setLoader(false)
                showToast('error', 'Login failed!')
            });
    };

    const signupUser = () => {
        history.push('/signup')
    };


    const socialLogin = (social_id, fname, lname, email) => {
        setLoader(true)
        Api.loginUser('', '', social_id,
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    localStorage.setItem('user', JSON.stringify(response.user))
                    dispatch(Actions.setUser(response.user))
                    history.push('/customer-dashboard')
                }
                else {
                    socialSignup(social_id, fname, lname, email)
                }
            },
            (error) => {
                console.log(error);
                // setLoader(false)
                showToast('error', 'Login failed!')
            });
    };

    const socialSignup = (social_id, fname, lname, email) => {
        console.log('socialSignup');
        if (email === '') {
            showToast('error', 'Email not found!')
            return
        }
        setLoader(true)
        Api.createUser(fname, lname, email, '', 'customer', social_id, '',
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    localStorage.setItem('user', JSON.stringify(response.user))
                    dispatch(Actions.setUser(response.user))
                    history.push('/customer-dashboard')
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Request failed')
            });
    };

    const responseGoogle = (response) => {
        console.log('profile: ' + JSON.stringify(response.profileObj));
        console.log('responseGoogle');
        let social_id = response.profileObj.googleId
        let name = response.profileObj.name.split(' ')
        let fname = name[0]
        let lname = name.length > 1 ? name[1] : ''
        let email = response.profileObj.email
        let photo = response.profileObj.imageUrl
        socialLogin(social_id, fname, lname, email)
    }

    const responseFacebook = (response) => {
        console.log('responseFacebook');
        console.log(JSON.stringify(response));
        let social_id = response.id
        let name = response.name.split(' ')
        let fname = name[0]
        let lname = name.length > 1 ? name[1] : ''
        let email = response.email
        let photo = response.picture.data.url
        socialLogin(social_id, fname, lname, email)
    }

    return (
        <div className='main'>

            <div className='login'>

                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    Login Account
                </div>

                <div className="form" style={{ marginTop: 20 }}>
                    <FormGroup>
                        <Input
                            style={{ fontSize: 18 }}
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </FormGroup>
                    <FormGroup
                        style={{ marginTop: 10 }}>
                        <Input
                            style={{ fontSize: 18 }}
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={(event) => setPassword(event.target.value)} />
                    </FormGroup>

                    <button
                        className='forgot-pass'
                        style={{}}
                        onClick={() => {
                            history.push('/reset-password')
                        }}>
                        Forgot Password?
                    </button>

                    <button
                        className='login-btn'
                        style={{}}
                        onClick={loginUser}>
                        Login
                    </button>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <GoogleLogin
                            className='google-btn'
                            style={{ backgroundColor: 'orange' }}
                            clientId={googleClientID}
                            buttonText="Login with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>

                    <div style={{ alignSelf: 'center', marginTop: 10 }}>
                        --------- or ----------
                    </div>

                    <button
                        className='hollow-btn'
                        onClick={signupUser}>
                        Sign up for New Account
                    </button>

                    {/* <button
                        onClick={() => {
                            //
                        }}
                        className='facebook-login'
                        style={{ border: '0px', padding: 10, marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                        <img
                            src={facebook}
                            style={{ width: 35, height: 40, objectFit: 'contain' }} />
                        <div>
                            <div style={{ marginLeft: 10, }}>
                                Login with Facebook
                            </div>
                        </div>
                    </button> */}

                    {/* <button
                        onClick={() => {
                            //
                        }}
                        className='google-login'
                        style={{ border: '0px', padding: 10, marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                        <img
                            src={google}
                            style={{ width: 22, height: 22, objectFit: 'contain' }} />
                        <div>
                            <div style={{ marginLeft: 10, }}>
                                Login with Google
                            </div>
                        </div>
                    </button> */}

                    {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <FacebookLogin
                            appId={fbAppID}
                            autoLoad={false}
                            fields="name,email,picture"
                            onClick={() => {
                                console.log('componentClicked');
                            }}
                            callback={responseFacebook}
                            cssClass="fb-btn"
                        />
                    </div> */}

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default Login; 
import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Constants from '../../constants/constants'
import { Form, Button, Label, Input } from 'reactstrap';

const MoverSection1 = (props) => {
    const history = useHistory() 
    let moverJobInfo = { ...props.moverJobInfo }

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const [selectedStates_, setSelectedStates_] = useState();
    const [address, setAddress] = useState('');

    useEffect(() => {
        let state = moverJobInfo.state
        let address = moverJobInfo.address
        setSelectedStates_({ id: state, label: state })
        setAddress(address)
    }, [])

    const updateState = (data) => {
        moverJobInfo.state = data.value
        props.dispatch(props.actions.setMoverJobInfo(moverJobInfo))
    };

    const updateAddress = (address) => {
        moverJobInfo.address = address
        props.dispatch(props.actions.setMoverJobInfo(moverJobInfo))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Where are you located?
            </div>
            <div
                className='col'
                style={{ flex: 1, marginTop: 10 }}>
                <div
                    className='option-label'
                    style={{ marginTop: 0 }}>
                    <span style={{ color: 'red' }}>*</span> State
                </div>
                <FormGroup style={{ marginTop: 5 }}>
                    <Select
                        options={Constants.statesList}
                        placeholder=""
                        style={{ fontSize: 12 }}
                        styles={{ fontSize: 12, inputStyle: { fontSize: 12 } }}
                        value={selectedStates_}
                        onChange={(data) => {
                            setSelectedStates_(data);
                            updateState(data)
                        }}
                        isSearchable={true}
                        isMulti={false} />
                </FormGroup>
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10 }}>
                <div
                    className='option-label'
                    style={{ marginTop: 0 }}>
                    <span style={{ color: 'red' }}>*</span> Address
                </div>
                <FormGroup style={{ marginTop: 5 }}>
                    <Input
                        style={{ fontSize: 18 }}
                        type='text'
                        placeholder=''
                        value={address}
                        onChange={(event) => {
                            setAddress(event.target.value)
                            updateAddress(event.target.value)
                        }} />
                </FormGroup>
            </div>

        </div>
    )
}

export default MoverSection1; 
import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const MoverSection10 = (props) => {
    const history = useHistory()
    let moverJobInfo = {...props.moverJobInfo}

    const [other, setOther] = useState('');

    const prices = [
        { value: '1', label: 'RM300', selected: false },
        { value: '2', label: 'RM500', selected: false },
        { value: '3', label: 'RM650', selected: false },
        { value: '4', label: 'RM850', selected: false },
        { value: '5', label: 'RM1200', selected: false },
        { value: '6', label: 'RM1300', selected: false },
        { value: '7', label: 'RM1350', selected: false },
        { value: '8', label: 'RM1450', selected: false },
        { value: '9', label: 'RM1500', selected: false },
        { value: '10', label: 'RM1650', selected: false },
        { value: '11', label: 'RM1800', selected: false },
        { value: '12', label: 'RM1950', selected: false },
    ]
    const [selectedPrice, setSelectedPrice] = useState();

    useEffect(() => {
        let a = moverJobInfo.questions[props.index].a
        for (let i = 0; i < prices.length; i++) {
            if(prices[i].label === a){
                setSelectedPrice(prices[i])
                break
            }
        }
    }, [])

    const updateAns = (index, value) => {
        console.log('value: ' + value);
        let jobInfo = {
            ...moverJobInfo,
            questions: {
                ...moverJobInfo.questions,
                [index]: {
                    ...moverJobInfo.questions[index],
                    a: value
                }
            }
        }
        props.dispatch(props.actions.setMoverJobInfo(jobInfo))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                {moverJobInfo.questions[props.index].q}
            </div>
            <div
                style={{ color: 'gray', fontSize: 14, alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Please note: If your budget is unreasonably low, you may not get any responses
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 5 }}>

                <div style={{ marginTop: 10 }}>
                    <div style={{}}>
                        <Select
                            options={prices}
                            placeholder="Choose"
                            style={{ fontSize: 12, height: 45 }}
                            styles={{
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    fontSize: 18,
                                }),
                            }}
                            value={selectedPrice}
                            onChange={(data) => {
                                setSelectedPrice(data);
                                updateAns(props.index, data.label)
                            }}
                            isSearchable={false}
                            isMulti={false} />
                    </div>
                </div>

            </div>


        </div>
    )
}

export default MoverSection10; 
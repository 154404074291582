
import moment from 'moment';

const initialState = {
    user: null,
    moverJobInfo: {
        questions: [
            { q: 'You are moving from?', a: '' },
            { q: 'You are moving to?', a: '' },
            { q: 'Which service do you need?', a: '' },
            { q: 'How many boxes do you need to move?', a: '' },
            { q: 'How much approximately do they weigh?', a: '' },
            { q: 'What other services do you need?', a: '' },
            { q: 'What type of property does the mover have to pick up from?', a: '' },
            { q: 'What type of property does the mover have to move to?', a: '' },
            { q: 'Any more details?', a: '' },
            { q: 'What is your budget for this job?', a: '' },
        ],
        timesSelected: '',
        preferredDate: moment().format('DD MMM YYYY'),
        aTimesSelected: '',
        aPreferredDate: moment().format('DD MMM YYYY'),
        details: '',
        state: '',
        address: '',
    },
    businessProfile: {
        business_name: '',
        logo: '',
        detail: '',
        services: '',
        skills: '',
        email: '',
        phone: '',
        whatsapp: '',
        fax: '',
        address: '',
        allow_notifications: '1',
        id_number: '',
        documents: '',
        field: '',
        degree: '',
        credentials: '',
        links: '',
    },
    acceptedIndex: -1,
    profilePercentage: 20,
    quotedJobs: [],
    activeJobs: [],
};

export function MyReducer(state = initialState, action) {
    // console.log('MyReducer Payload: ' + JSON.stringify(action.payload));
    switch (action.type) {
        case 'setBusinessProfile':
            return {
                ...state,
                businessProfile: action.payload
            };
        case 'setMoverJobInfo':
            return {
                ...state,
                moverJobInfo: action.payload
            };
        case 'setUser':
            return {
                ...state,
                user: action.payload
            };
        case 'setActiveJobs':
            return {
                ...state,
                activeJobs: action.payload
            };
        case 'seQuotedJobs':
            return {
                ...state,
                quotedJobs: action.payload
            };
        case 'setProfilePercentage':
            return {
                ...state,
                profilePercentage: action.payload
            };
        case 'setAcceptedIndex':
            return {
                ...state,
                acceptedIndex: action.payload
            };

        default:
            return state;
    }
}
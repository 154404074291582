import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import logo from '../assets/pros_logo.png';
import facebook from '../assets/facebook.png';
import google from '../assets/google.png';
import showToast from './util/toast'

const ContactUs = () => {
    const history = useHistory()

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    return (
        <div className='main'>

            <div className='login'>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}

                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    Contact Us
                </div>

                <div className="form" style={{ marginTop: 20 }}>

                    <div style={{ color: 'gray', fontSize: 14, marginTop: 5, marginLeft: 18, color: Colors.theme1 }}>
                        <span style={{ color: 'black' }}>Email:</span> ddsurg@ddsurgical.com
                    </div>
                    {/* <div style={{width:'100%', height:1, backgroundColor:'lightgray', marginTop:10, marginBottom:10}}/>
                    <div style={{ color: 'gray', fontSize: 14, marginLeft: 18, color: Colors.theme1 }}>
                        <span style={{ color: 'black' }}>Phone:</span> +61202222222
                    </div> */}

                </div>

            </div>
        </div>
    );
}

export default ContactUs; 
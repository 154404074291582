import React, { useState } from 'react';
import '../../App.css';
import { useHistory, useLocation } from 'react-router-dom';
import MyLoader from '.././util/loader'
import MyError from '.././util/error'
import Api from '.././api/api'
import showToast from '.././util/toast'
import { Actions } from '.././redux/Actions'
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import CreditCardInput from 'react-credit-card-input';
import lock from '../../assets/lock.png';
import GLOBAL from '../constants/global';

const Payment = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const job = location.state.job

    const [paypalUrl, setPaypalUrl] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [expiry, setExpiry] = useState('')
    const [cvc, setCvc] = useState('')

    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const payViaPayPal = () => {

    }

    const payViaCard = () => {
        if (cardNumber === '' || expiry === '' || cvc === '') {
            showToast('error', 'Please fill card info!')
            return
        }
        setLoader(true)
        Api.stripeToken(cardNumber, expiry.split('/')[0], '20' + expiry.split('/')[1], cvc,
            (response) => {
                let token = response.id
                console.log('token: ' + token);
                if (token != undefined && token != null && token !== '') {
                    Api.stripePayment(token, parseInt(job.salary + '') * 0.03,
                        (response) => {
                            setLoader(false)
                            console.log('status: ' + response.status);
                            if (response.status.toLowerCase() === 'succeeded') {
                                showToast('success', 'Payment Success!')
                                postJobNow()
                            }
                        },
                        (error) => {
                            console.log('payment error: ' + error);
                            setLoader(false)
                            showToast('error', 'Payment failed!')
                        });
                }
                else {
                    setLoader(false)
                    showToast('error', 'Payment failed!')
                }
            },
            (error) => {
                setLoader(false)
                showToast('error', 'Payment failed!')
            })
    }

    const postJobNow = () => {
        console.log('postJobNow')
        setLoader(true)
        Api.postNewJob(job,
            (response) => {
                console.log('response: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // clear caches
                    localStorage.removeItem('jobState')
                    GLOBAL.cacheFiles = []
                    showToast('success', 'Job Posted!')
                    history.replace('/customer-dashboard')
                }
                else {
                    showToast('error', response.msg)
                }
                setLoader(false)
            },
            (error) => {
                setLoader(false)
                console.log(JSON.stringify(error));
                showToast('error', 'Some issue on server side. Please try later')
            })
    }

    return (
        <div className='main'>

            <div className='login' style={{ overflow: 'scroll' }}>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={lock}
                        style={{ width: 25, height: 25, objectFit: 'contain' }} />
                    <div className='home-label' style={{ fontSize: 24, marginTop: 0, marginLeft: 10, alignSelf: 'center' }}>
                        Secure Payment
                    </div>
                </div>

                <div className="form">

                    <div style={{ display: 'flex', marginTop: 20, flexDirection: 'column', padding: 20, backgroundColor: '#f2f2f2', borderRadius: 5, alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: 14 }}>
                            Job Posting Fee = 3% of Job Budget
                        </div>
                        <div style={{ color: '#088A68', fontSize: 18 }}>
                            You need to pay = ${parseInt(job.salary + '') * 0.03}
                        </div>
                    </div>

                    <FormGroup style={{ marginTop: 15, display: 'flex', flexDirection: 'column' }}>

                        <div style={{ marginTop: 10, alignSelf: 'center', flex: 1, display: 'flex', borderRadius: 5, padding: 1, backgroundColor: 'black' }}>
                            <CreditCardInput
                                cardNumberInputProps={{ value: cardNumber, onChange: (e) => { setCardNumber(e.target.value) } }}
                                cardExpiryInputProps={{ value: expiry, onChange: (e) => { setExpiry(e.target.value) } }}
                                cardCVCInputProps={{ value: cvc, onChange: (e) => { setCvc(e.target.value) } }}
                                fieldClassName="input"
                                containerStyle={{ fontSize: window.innerWidth < 800 ? 15:18, width: '100%' }}
                                inputStyle={{ fontSize: window.innerWidth < 800 ? 15:18 }}
                            />
                        </div>

                        <button
                            onClick={payViaCard}
                            type="button"
                            className='btn-block btn-dark'
                            style={{ color: 'white', marginTop: 30, height: 50, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: '#000', borderWidth: 0, borderRadius: 5 }}>
                            Pay Now
                        </button>
                        {/* <button
                            onClick={payViaPayPal}
                            type="button"
                            className='btn-block btn-dark'
                            style={{ color: 'white', marginTop: 15, height: 50, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: '#066db4', borderWidth: 0, borderRadius: 5 }}>
                            Pay via PayPal
                        </button> */}
                    </FormGroup>

                    {error &&
                        <div style={{ color: 'red', textAlign: 'center', marginTop: 10 }}>
                            {error}
                        </div>
                    }

                    <div style={{ display: 'flex', marginTop: 15, flexDirection: 'column', padding: 20, borderRadius: 5, alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: 14, fontStyle: 'italic', textAlign: 'center' }}>
                            <span style={{ color: 'blue' }}>Note:</span> Please note the payment is not refundable. In case of any issues please contact us.
                        </div>
                    </div>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default Payment;
import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from '../colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from '../util/loader'
import MyError from '../util/error'
import MyStorage from '../util/storage'
import Api from '../api/api'
import image from '../../assets/image.png';
import cross from '../../assets/cross1.png';
import { showOption } from './option'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import GLOBAL from '../constants/global';
import showToast from '../util/toast'
import { paintingQuestions } from '../constants/constants';
import { caribbeanCities, categories1, countries } from '../util/cats';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

var files = []

const PostJob = () => {
    const history = useHistory()

    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const [salary, setSalary] = useState('');
    const [skills, setSkills] = useState([])
    const [country, setCountry] = useState('');
    const [location, setLocation] = useState('');
    const [jobType, setJobType] = useState('');
    const [times, setTimes] = useState([
        { title: 'Anytime', selected: false },
        { title: 'Morning (09:00 - 11:00)', selected: false },
        { title: 'Lunch Time (11:00 - 13:00)', selected: false },
        { title: 'Early Afternoon (13:00 - 15:00)', selected: false },
        { title: 'Late Afternoon (15:00 - 17:00)', selected: false },
    ])
    const [preferredDate, setPreferredDate] = useState(new Date());
    const addFile = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        // check login
        let u = localStorage.getItem('user')
        if (u == null) {
            history.push('/login')
        }
    }, [])

    useEffect(async () => {
        let jobState = localStorage.getItem('jobState')
        if (jobState != null) {
            jobState = JSON.parse(jobState)
            setTimes(jobState.times)
            setPreferredDate(moment(jobState.preferredDate, 'DD MMM YYYY').toDate())
            setSelectedFiles(GLOBAL.cacheFiles)
            setTitle(jobState.title)
            setDetail(jobState.detail)
            setSalary(jobState.salary)
            setSkills(jobState.skills)
            setLocation(jobState.location)
            setCountry(jobState.country)
        }
    }, []);

    const onFilesSelect = (event) => {
        files = event.target.files
        setSelectedFiles(Array.from(event.target.files))
    };

    const saveInfo = () => {

        // times
        let timesSelected = ''
        for (let i = 0; i < times.length; i++) {
            if (times[i].selected) {
                timesSelected = timesSelected + times[i].title + ','
            }
        }
        timesSelected = timesSelected.substring(0, timesSelected.length - 1)

        // date
        let preferredDateSelected = true

        if (!preferredDateSelected) {
            showToast('error', 'Invalid preferred date!')
            return
        }
        if (title === ''
            || detail === ''
            || salary === ''
            || skills.length === 0
            || country === ''
            || location === ''
            || jobType === ''
        ) {
            showToast('error', 'Please fill complete form!')
            return
        }

        // store state
        let jobState = {
            preferredDate: moment(preferredDate).format('DD MMM YYYY'),
            times: times,
            title: title,
            detail: detail,
            salary: salary,
            skills: skills,
            country: country,
            location: location,
            jobType: jobType,
        }
        localStorage.setItem('jobState', JSON.stringify(jobState))
        GLOBAL.cacheFiles = selectedFiles

        // send job details
        let jobObj = {
            timesSelected: timesSelected,
            preferredDate: moment(preferredDate).format('DD MMM YYYY'),
            photos: selectedFiles,
            title: title,
            detail: detail,
            salary: salary,
            skills: skills,
            country: country,
            location: location,
            jobType: jobType
        }
        console.log('job: ' + JSON.stringify(jobObj))

        history.push({
            pathname: '/confirm-job',
            state: { job: jobObj }
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1, paddingBottom: 30 }}>

            <div className='login quote-top' style={{ paddingLeft: 0, paddingRight: 0, marginTop: 30 }}>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}

                <div className='home-label' style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 20 }}>
                    Post New Job
                </div>

                <div className='my-line' />

                <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>

                    <div style={{ fontSize: 14 }}>
                        Fields marked <span style={{ color: 'red' }}>*</span> are required<br />
                    </div>

                    <div className='option-label'>
                        Job Title <span style={{ color: 'red' }}>*</span>
                    </div>
                    <FormGroup style={{ marginTop: 10 }}>
                        <Input
                            type='input'
                            placeholder=''
                            value={title}
                            onChange={(event) => {
                                setTitle(event.target.value)
                            }} />
                    </FormGroup>

                    <div className='option-label'>
                        Job Details <span style={{ color: 'red' }}>*</span>
                    </div>
                    <FormGroup style={{ marginTop: 10 }}>
                        <Input
                            type='textarea'
                            placeholder=''
                            value={detail}
                            onChange={(event) => {
                                setDetail(event.target.value)
                            }} />
                    </FormGroup>

                    <div className='option-label'>
                        Skills Required <span style={{ color: 'red' }}>*</span>
                    </div>

                    {skills.map((item, index) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', marginTop: 5, height: 45, alignItems: 'center' }}>
                                    <FormGroup style={{ marginTop: 10 }}>
                                        <Input
                                            type='input'
                                            style={{}}
                                            placeholder=''
                                            value={item}
                                            onChange={(event) => {
                                                const list = skills.map((item_, i) => {
                                                    if (i === index) {
                                                        return event.target.value;
                                                    }
                                                    else {
                                                        return item_;
                                                    }
                                                });
                                                setSkills(list)
                                            }} />
                                    </FormGroup>
                                    <div style={{ width: 35, marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
                                        <button
                                            onClick={() => {
                                                const list = skills.filter((item_, j) => {
                                                    return item_ !== item; //false will delete
                                                });
                                                setSkills(list)
                                            }}
                                            style={{ border: '0px', fontSize: 14, backgroundColor: 'black', color: 'white', fontWeight: 'bold', borderRadius: 20 }}>
                                            X
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <button
                        onClick={() => {
                            let s = [...skills]
                            s.push('')
                            setSkills(s)
                        }}
                        className='login-btn vendor-view-profile'
                        style={{ height: 40, fontSize: 16, paddingLeft: 20, paddingRight: 20, marginTop: 15, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                        Add Skill
                    </button>

                    <div className='option-label'>
                        Expected Salary <span style={{ color: 'red' }}>*</span>
                    </div>
                    <FormGroup style={{ marginTop: 10 }}>
                        <Input
                            type='input'
                            placeholder=''
                            value={salary}
                            onChange={(event) => {
                                setSalary(event.target.value)
                            }} />
                    </FormGroup>

                    <div className='option-label'>
                        Country <span style={{ color: 'red' }}>*</span>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Autocomplete
                            disablePortal
                            options={countries}
                            value={country}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="" />}
                            onChange={(event, newVal) => {
                                console.log('country: ' + newVal);
                                setCountry(newVal)
                            }}
                        />
                    </div>

                    <div className='option-label'>
                        City <span style={{ color: 'red' }}>*</span>
                    </div>
                    <FormGroup style={{ marginTop: 10 }}>
                        <Input
                            type='input'
                            placeholder=''
                            value={location}
                            onChange={(event) => {
                                setLocation(event.target.value)
                            }} />
                    </FormGroup>

                    <div className='option-label'>
                        Job Type <span style={{ color: 'red' }}>*</span>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Autocomplete
                            freeSolo
                            disablePortal
                            options={categories1}
                            value={jobType}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="" />}
                            onChange={(event, newVal) => {
                                console.log('jobType: ' + newVal);
                                setJobType(newVal)
                            }}
                            onInputChange={(event, newInputValue) => {
                                console.log('jobType: ' + newInputValue);
                                setJobType(newInputValue); // Update state when the input changes
                            }}
                        />
                        {/* <select
                            className='picker'
                            onChange={(e) => {
                                setJobType(e.target.value)
                                // setSelectedLocation(e.target.value)
                            }}>
                            {categories1.map((item, index) => {
                                return (
                                    <option
                                        value={item}>
                                        {item}
                                    </option>
                                )
                            })}
                        </select> */}

                    </div>

                    {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel style={{ fontSize: 18 }} id="demo-select-small">Service</InputLabel>
                        <Select
                            style={{ fontSize: 18 }}
                            labelId="demo-select-small"
                            id="demo-simple-select"
                            value={catSel}
                            label="Service"
                            onChange={(event) => {
                                setCatSel(event.target.value)
                                searchJobs(event.target.value, stateSel)
                            }}>
                            {cats.map((item, index) => {
                                return (
                                    <MenuItem value={item}>{item}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl> */}

                    <div className='option-label'>
                        Preferred Job Date <span style={{ color: 'red' }}>*</span>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <DatePicker
                            selected={preferredDate}
                            onChange={(date) => setPreferredDate(date)}
                            customInput={
                                <FormGroup>
                                    <Input
                                        minDate={moment()}
                                        style={{ fontSize: 18 }}
                                        type='text'
                                        placeholder='Choose Date'
                                        value={moment(preferredDate).format('DD MMM YYYY')}
                                        onChange={(event) => setPreferredDate(event.target.value)} />
                                </FormGroup>
                            } />
                    </div>

                    <div className='option-label'>
                        Preferred Job Time <span style={{ color: 'red' }}>*</span>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                        {times.map((item, index) => {
                            return (
                                <button
                                    onClick={() => {
                                        const newList = times.map((item_) => {
                                            if (item_.title === item.title)
                                                return { ...item_, selected: !item.selected };
                                            else
                                                return { ...item_, selected: false }; // set others false
                                        });
                                        setTimes(newList)
                                    }}
                                    className={item.selected ? 'tab-item2-sel' : 'tab-item2'}>
                                    {item.title}
                                </button>
                            )
                        })}
                    </div>

                    <div
                        className='option-label'
                        style={{ alignSelf: 'flex-start' }}>
                        Upload Files (Optional)
                    </div>
                    <button
                        onClick={() => {
                            addFile.current.click();
                        }}
                        style={{ marginTop: 0, backgroundColor: 'transparent', border: '0px', width: '100%', padding: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, borderRadius: 5, border: '1px dotted gray', borderColor: 'gray', alignItems: 'center', justifyContent: 'center', padding: 30 }}>
                            <img
                                src={image}
                                style={{ width: 40, height: 40 }} />
                            <div style={{ color: 'gray', fontSize: 16 }}>Choose File</div>
                        </div>
                    </button>
                    <input
                        type='file'
                        multiple="multiple"
                        style={{ display: 'none' }}
                        ref={addFile}
                        onChange={(event) => {
                            onFilesSelect(event)
                        }} />

                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedFiles.map((item, index) => {
                            let url = URL.createObjectURL(item)
                            return (
                                <div style={{ display: 'flex', marginTop: 10, marginRight: 20, alignItems: 'center' }}>
                                    <div style={{ color: 'blue' }}>
                                        File{(index + 1)}
                                    </div>
                                    {/* <img
                                        style={{ width: 100, height: 100, objectFit: 'contain' }}
                                        src={url} /> */}
                                    <img
                                        onClick={() => {
                                            const temp = selectedFiles.filter((item, j) => {
                                                return j != index; //false will delete
                                            });
                                            setSelectedFiles(temp)
                                        }}
                                        style={{ width: 20, height: 20, borderRadius: 20, marginLeft: 10, backgroundColor: 'white' }}
                                        src={cross} />
                                </div>
                            )
                        })}
                    </div>

                    <button
                        onClick={saveInfo}
                        className='login-btn'
                        style={{ marginTop: 20, fontSize: 17, width: '100%' }}>
                        Next
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default PostJob; 
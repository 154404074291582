import React from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import Api from './api/api'

const HowCustomers = () => {
    const history = useHistory()

    const showStep = (step, stepTxt) => {
        return (
            <div className='step-parent' style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                <div className='step' style={{backgroundColor:'white'}}>
                    <div>{step}</div>
                </div>
                <div className='step-txt'>
                    {stepTxt}
                </div>
            </div>
        )
    };

    return (
        <div className='main' style={{ overflow: 'scroll' }}>

            <div style={{ width: '100%', backgroundColor: 'white', marginTop: 20, paddingBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center', paddingTop: 20 }}>
                    <div className='home-label' style={{ alignSelf: 'center', display: 'flex', textAlign: 'center' }}>
                        How to Use Cariworks!
                    </div>
                    <div
                        style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                        {showStep(1, 'Post a Job by giving your requirements.')}
                        {showStep(2, 'Workers will contact you. Book an appointment immediately, or get quotations first')}
                        {showStep(3, 'When your Job is done, Give a rating & review to the worker.')}
                    </div>
                    <div style={{width:'100%', height:1, marginTop:20, marginBottom:20, backgroundColor:'lightgray'}}/>
                    <div style={{ display: 'flex', marginBottom:10, flexDirection:'column', alignSelf: 'stretch', height:70 }}>
                        <button
                            className='search-btn'
                            style={{ display: 'flex', alignSelf:'center', marginTop: 10, width: 300}}
                            onClick={() => {
                                history.push('/post-job')
                            }}>
                            Post a Job
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default HowCustomers; 
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Colors from '../colors'
import QuotesView from './quotes-view';
import ChatView from './chat-view';
import DetailView from './detail-view';
import Api from '../api/api';
import showToast from '../util/toast';
import { useSelector } from 'react-redux';
import MyLoader from '../util/loader';

const ProjectDetails = () => {
    const history = useHistory()
    const location = useLocation()
    const [loader, setLoader] = useState(false);

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const job = location.state.job
    // quote:
    // { 
    //     id: '3', 
    //     accepted: 0,
    //     no_of_reviews: 3,
    //     rating: 4.5,
    //     price: '50',
    //     created_at: new Date(),
    //     vendor: {
    //         first_name: 'Faisal',
    //         last_name: 'Nadeem',
    //         photo: '',
    //         email: 'saif052m@gmail.com',
    //     } 
    // },

    let acceptedQid_ = ''
    let firstQid_ = ''
    for (let i = 0; i < job.quotes.length; i++) {
        if (i == 0)
            firstQid_ = job.quotes[i].id
        if (job.quotes[i].accepted == 1) {
            acceptedQid_ = job.quotes[i].id
        }
    }
    console.log('acceptedQid: ' + acceptedQid_);

    const [tabSel, setTabSel] = useState(1)
    const [acceptedQid, setAcceptedQid] = useState(acceptedQid_)
    const [selected, setSelected] = useState(acceptedQid === '' ? firstQid_ : acceptedQid)

    useEffect(() => {
        console.log('useEffect');
    }, [acceptedQid])

    const getSelectedQuote = () => {
        let obj = null
        for (let i = 0; i < job.quotes.length; i++) {
            const quote = job.quotes[i];
            if (quote.id === selected) {
                obj = quote
            }
        }
        return obj
    };

    const onDeleteJob = () => {
        setLoader(true)
        console.log('job: ' + JSON.stringify(job));

        console.log('job_id: ' + job.id);
        console.log('user.id: ' + user.id);

        Api.cancelJob(job.id, user.id,
            (response) => {
                console.log('cancelJob: ' + JSON.stringify(response));
                setLoader(false)
                if (response.status === 'success') {
                    history.goBack()
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
            });
    }

    return (
        <div style={{ backgroundColor: Colors.background, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 40, }}>

            <div
                className='home-panel'
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>

                <div style={{ fontSize: 24, alignSelf: 'flex-start', marginBottom: 10 }}>
                    {job.title}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                    <button
                        onClick={() => {
                            setTabSel(1)
                        }}
                        className={tabSel == 1 ? 'tab-btn1-sel' : 'tab-btn1'}>
                        Quotes ({job.quotes.length})
                    </button>
                    <button
                        onClick={() => {
                            setTabSel(2)
                        }}
                        className={tabSel == 2 ? 'tab-btn2-sel' : 'tab-btn2'}>
                        Details
                    </button>
                </div>

                <div style={{ width: '100%', paddingBottom: 20, paddingTop: 20, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

                    <div
                        style={{ flex: 3, display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: 20, backgroundColor: 'white', height: '90vh', overflow: 'scroll', marginLeft: 0 }}>

                        <div>

                            {tabSel == 1 ?
                                job.quotes.length == 0 ?
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', alignSelf: 'center' }}>
                                            No Quotes Yet
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex' }}>
                                        <QuotesView
                                            job={job}
                                            acceptedQid={acceptedQid}
                                            quoteSelected={(qid) => {
                                                setSelected(qid)
                                            }} />
                                        <ChatView
                                            job={job}
                                            showVendor
                                            quote={getSelectedQuote()}
                                            acceptedQid={acceptedQid}
                                            accepted={acceptedQid == selected}
                                            onAccept={() => {
                                                console.log('accepted: ' + selected);
                                                setAcceptedQid(selected)
                                            }} />
                                    </div>
                                :
                                <DetailView job={job} />
                            }

                        </div>

                    </div>

                </div>

                {job.status.toLowerCase() !== 'cancelled' &&
                    <button
                        className='login-btn'
                        style={{ marginTop: 15, height: 50, border: '1px solid red', color: 'red', backgroundColor: 'transparent', width: 300, marginBottom: 50 }}
                        onClick={() => {
                            const confirmDelete = window.confirm('Are you sure you want to cancel this job?');
                            if (confirmDelete) {
                                onDeleteJob(); // Call the delete function if confirmed
                            }
                        }}>
                        Cancel Job
                    </button>
                }

            </div>

            <MyLoader loader={loader} marginTop={-10} />

        </div>
    );
}

export default ProjectDetails; 
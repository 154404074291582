import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import * as Colors from '../../colors'

const ProfileSection7 = (props) => {
    const history = useHistory()
    let businessProfile = { ...props.businessProfile }

    const [ssmNumber, setSsmNumber] = useState('');

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const [links, setLinks] = useState([]);

    const checkValid = (key) => {
        return businessProfile[key] !== undefined && businessProfile[key] != null && businessProfile[key] !== '' && businessProfile[key] !== 'null'
    };

    useEffect(() => {
        if (checkValid('links')) {
            let arr = businessProfile.links.split(',')
            let temp = []
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                if (item === '')
                    continue
                temp.push(item)
            }
            setLinks(temp)
        }
    }, [props])

    const updateValue = (arr) => {
        let links = ''
        for (let i = 0; i < arr.length; i++) {
            links = links + arr[i] + ','
        }
        links = links.substring(0, links.length - 1)
        businessProfile['links'] = links
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Links (Optional)
            </div>

            <div style={{ fontSize: 14, marginTop: 5, color: 'gray', textAlign: 'center', alignSelf: 'center' }}>
                Enter the full Facebook, Twitter, or website address for your business.
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>

                <div>
                    {links.map((item, index) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, alignItems: 'center', alignItems: 'stretch' }}>
                                    <div style={{ display: 'flex', }}>
                                        <div style={{ width: 100, display: 'flex', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, paddingLeft: 10, paddingRight: 10, marginRight: -1, fontSize: 16, color: 'black', backgroundColor: 'white', border: '1px solid lightgray', height: 45, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            Link
                                        </div>
                                        <div
                                            style={{ flex: 1, borderTopRightRadius: 5, borderBottomRightRadius: 5, fontSize: 16, border: '1px solid lightgray', height: 45, backgroundColor: 'white', color: Colors.theme1, textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                                            <input
                                                type='text'
                                                placeholder='e.g. https://www.google.com'
                                                style={{ fontSize: 16, border: '0px', width: '100%', height: '100%', paddingLeft: 15, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                                                value={item}
                                                onChange={(event) => {
                                                    const arr = links.map((item_, index_) => {
                                                        if (index === index_) {
                                                            return event.target.value;
                                                        }
                                                        else {
                                                            return item_;
                                                        }
                                                    });
                                                    setLinks(arr)
                                                    updateValue(arr)
                                                }} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', height: 25, alignSelf: 'center', marginLeft: 5 }}>
                                            <button
                                                onClick={() => {
                                                    const temp = links.filter((item, j) => {
                                                        return index !== j; //false will delete
                                                    });
                                                    setLinks(temp)
                                                    updateValue(temp)
                                                }}
                                                style={{ border: '0px', fontSize: 14, backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>
                                                X
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>

                <button
                    onClick={() => {
                        let temp = [...links]
                        temp.push('')
                        setLinks(temp)
                    }}
                    className='login-btn vendor-view-profile'
                    style={{ height: 45, fontSize: 17, marginTop: 15, backgroundColor: 'transparent', border: '1px solid ' + Colors.theme1, borderColor: Colors.theme1, color: Colors.theme1 }}>
                    Add Link
                </button>

            </div>

        </div>
    )
}

export default ProfileSection7; 
import React, { useState, useRef, useEffect } from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux';

const ProfileSection3 = (props) => {
    const history = useHistory()
    let businessProfile = { ...props.businessProfile }

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [fax, setFax] = useState('');
    const [address, setAddress] = useState('');

    const [tick, setTick] = useState(false);

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const checkValid = (key) => {
        return businessProfile[key] !== undefined && businessProfile[key] != null && businessProfile[key] !== '' && businessProfile[key] !== 'null'
    };

    useEffect(() => {
        if (checkValid('email'))
            setEmail(businessProfile.email)
        if (checkValid('phone'))
            setPhone(businessProfile.phone)
        if (checkValid('whatsapp'))
            setWhatsapp(businessProfile.whatsapp)
        if (checkValid('fax'))
            setFax(businessProfile.fax)
        if (checkValid('address'))
            setAddress(businessProfile.address)
        if (checkValid('allow_notifications'))
            setTick(businessProfile.allow_notifications + '' === '1')
    }, [props])

    const updateValue = (key, value) => {
        businessProfile[key] = value
        props.dispatch(props.actions.setBusinessProfile(businessProfile))
    };

    return (
        <div>
            <div
                className='option-label'
                style={{ alignSelf: 'center', marginTop: 0, textAlign: 'center' }}>
                Contact Information
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <TextField
                    disabled
                    contentEditable={false}
                    id="outlined-basic"
                    label="Email"
                    type='email'
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    value={email}
                    size='small'
                    onChange={(event) => {
                        setEmail(event.target.value)
                        updateValue('email', event.target.value)
                    }} />
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'gray', fontSize: 16 }}>Phone</div>
                <PhoneInput
                    inputStyle={{ height: 45, fontSize: 16, width: '100%' }}
                    country={'my'}
                    value={phone}
                    onChange={phone => {
                        setPhone(phone)
                        updateValue('phone', phone)
                    }} />
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'gray', fontSize: 16 }}>Whatsapp (optional)</div>
                <PhoneInput
                    inputStyle={{ height: 45, fontSize: 16, width: '100%' }}
                    country={'my'}
                    value={whatsapp}
                    onChange={phone => {
                        setWhatsapp(phone)
                        updateValue('whatsapp', phone)
                    }} />
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'gray', fontSize: 16 }}>Fax (optional)</div>
                <PhoneInput
                    inputStyle={{ height: 45, fontSize: 16, width: '100%' }}
                    country={'my'}
                    value={fax}
                    onChange={phone => {
                        setFax(phone)
                        updateValue('fax', phone)
                    }} />
            </div>

            <div
                className='col'
                style={{ flex: 1, marginTop: 15, display: 'flex', flexDirection: 'column' }}>
                <TextField
                    id="outlined-basic"
                    label="Address"
                    type='text'
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    value={address}
                    size='small'
                    onChange={(event) => {
                        setAddress(event.target.value)
                        updateValue('address', event.target.value)
                    }} />
            </div>

            {/* <div
                className='col'
                style={{ flex: 1, marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                    <input
                        style={{ width: 20, height: 20 }}
                        type='checkbox'
                        id="option1"
                        checked={tick}
                        name="fav_language"
                        value="option1"
                        onChange={(event) => {
                            let val = event.target.checked ? '1' : '0'
                            setTick(val)
                            updateValue('allow_notifications', val)
                        }} />
                    <label style={{ marginLeft: 10 }} for="option1">
                        I don't want to receive "You have a new job request" emails notification.
                    </label>
                </div>
            </div> */}

        </div>
    )
}

export default ProfileSection3; 
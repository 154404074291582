import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import logo from '../assets/pros_logo.png';
import facebook from '../assets/facebook.png';
import google from '../assets/google.png';
import showToast from './util/toast'

const ForgotPassword = () => {
    const history = useHistory()
    const [email, setEmail] = useState('')

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const submit = () => {
        if (email === '') {
            return
        }

        setLoader(true)
        Api.forgotPassword(email,
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    showToast('success', 'Please check your email')
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                setLoader(false)
                console.log(error);
                // setLoader(false)
                showToast('error', 'Sorry some network problem! Please try later.')
            });
    };

    const signupUser = () => {
        history.push('/signup')
    };

    return (
        <div className='main'>

            <div className='login'>

                {/* <img
                    src={logo}
                    style={{ width: 200, objectFit: 'contain', alignSelf: 'center', marginBottom: 20 }} /> */}

                <div className='home-label' style={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}>
                    Reset Password
                </div>

                <div className="form" style={{ marginTop: 20 }}>
                    <FormGroup>
                        <Input
                            style={{ fontSize: 18 }}
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </FormGroup>

                    <MyError showError={showError} error={error} />

                    <button
                        className='login-btn'
                        style={{}}
                        onClick={submit}>
                        Submit New Password
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default ForgotPassword; 
import Loader from 'react-loader-spinner'

const MyLoader = ({ loader, marginTop }) => {
    if (loader === false)
        return null
    return (
        <div style={{ position: 'absolute', pointerEvents:'none', display:'flex', top:0, left:0, width:'100%', height:'100%', flex:1, alignItems:'center',justifyContent:'center'}}>
            <div style={{ alignSelf: 'center'}}>
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={70}
                    width={70}
                    visible={true} />
            </div>
        </div>
    )
};

export default MyLoader
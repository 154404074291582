import React, { useState } from 'react';
import '../../App.css';
import { useLocation, useHistory } from 'react-router-dom';
import MyLoader from '.././util/loader'
import Api from '.././api/api'
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css'
import calendar from '../../assets/calendar.png';
import profile from '../../assets/profile.png';
import email from '../../assets/email.png';
import phone from '../../assets/phone.png';
import marker from '../../assets/marker.png';
import showToast from '../util/toast';
import GLOBAL from '.././constants/global';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '.././redux/Actions'

const QuoteConfirmation = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();

    let user = useSelector(state => state.MyReducer.user);
    if(user == null && localStorage.getItem('user') != null){
        user = JSON.parse(localStorage.getItem('user'))
    }

    const jobType = location.state.jobType
    const job = location.state.job

    let quoteContactDetails = JSON.parse(localStorage.getItem('quoteContactDetails'))

    const [questions, setQuestions] = useState(job.questions);
    console.log('questions: '+JSON.stringify(questions));

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const submitJobRequest = () => {
        if (jobType === 'plumber') {
            postPlumberJob()
        }
        else if (jobType == 'painting') {
            postPaintJob()
        }
        else if (jobType == 'aircon') {
            postAirconJob()
        }
        else if (jobType == 'mover') {
            postMoverJob()
        }

        // update profile info
        Api.updateProfile(user.first_name, user.last_name, quoteContactDetails.phone, '', '',
            (response) => {
                console.log('updated profile: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    localStorage.setItem('user', JSON.stringify(response.user))
                    dispatch(Actions.setUser(response.user))
                }
            },
            (error) => {
                console.log(error);
            });
    };

    const postPlumberJob = () => {
        console.log('postPlumberJob');
        setLoader(true)
        let jobObj = {
            job_type: 'plumber',
            service_type: job.questions[0].a,
            problems: job.questions[1].a,
            items_to_do: job.questions[2].a,
            service_place: job.questions[3].a,
            building_type: job.questions[4].a,
            preferred_date: job.preferredDate,
            preferred_time: job.timesSelected,
            more_details: job.details,
            photos: job.selectedFiles,
            state: job.state,
            address: job.address,
        }
        Api.createPlumberJob(jobObj,
            (response) => {
                console.log('response: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // clear caches
                    localStorage.removeItem('plumberJobState')
                    GLOBAL.plumberCacheFiles = []
                    showToast('success', 'Job Posted!')
                    history.replace('/customer-dashboard')
                }
                else {
                    showToast('error', response.msg)
                }
                setLoader(false)
            },
            (error) => {
                setLoader(false)
                console.log(JSON.stringify(error));
                showToast('error', 'Some issue on server side. Please try later')
            });
    };

    const postPaintJob = () => {
        console.log('postPaintJob');
        setLoader(true)
        let jobObj = {
            job_type: 'painting',
            paint_surface: job.questions[0].a,
            floor_space: job.questions[1].a,
            provide_paint: job.questions[2].a === 'Yes' ? 1 : 0,
            preferred_date: job.preferredDate,
            preferred_time: job.timesSelected,
            more_details: job.details,
            photos: job.selectedFiles,
            state: job.state,
            address: job.address,
        }
        Api.createPaintingJob(jobObj,
            (response) => {
                console.log('response: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // clear caches
                    localStorage.removeItem('paintJobState')
                    GLOBAL.paintCacheFiles = []
                    showToast('success', 'Job Posted!')
                    history.replace('/customer-dashboard')
                }
                else {
                    showToast('error', response.msg)
                }
                setLoader(false)
            },
            (error) => {
                setLoader(false)
                console.log(JSON.stringify(error));
                showToast('error', 'Some issue on server side. Please try later')
            });
    };

    const postAirconJob = () => {
        console.log('postAirconJob');
        setLoader(true)
        let jobObj = {
            job_type: 'aircon',
            service_type: job.questions[0].a,
            building_type: job.questions[1].a,
            preferred_date: job.preferredDate,
            preferred_time: job.timesSelected,
            more_details: job.details,
            state: job.state,
            address: job.address,
            budget: job.budget,
        }
        Api.createAirconJob(jobObj,
            (response) => {
                console.log('response: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // clear caches
                    localStorage.removeItem('airconJobState')
                    showToast('success', 'Job Posted!')
                    history.replace('/customer-dashboard')
                }
                else {
                    showToast('error', response.msg)
                }
                setLoader(false)
            },
            (error) => {
                setLoader(false)
                console.log(JSON.stringify(error));
                showToast('error', 'Some issue on server side. Please try later')
            });
    };

    const postMoverJob = () => {
        console.log('postMoverJob');
        setLoader(true)
        let jobObj = {
            job_type: 'mover',
            moving_from: job.questions[0].a,
            moving_to: job.questions[1].a,
            lorry_type: job.questions[2].a,
            no_of_boxes: job.questions[3].a,
            total_weight: job.questions[4].a,
            other_services: job.questions[5].a,
            pick_from_property: job.questions[6].a,
            move_to_property: job.questions[7].a,
            more_details: job.questions[8].a,
            budget: job.questions[9].a,
            preferred_date: job.preferredDate,
            preferred_time: job.timesSelected,
            alternative_date: job.aPreferredDate,
            alternative_time: job.aTimesSelected,
            state: job.state,
            address: job.address,
            within_area: 1,
        }
        Api.createMovingJob(jobObj,
            (response) => {
                console.log('response: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // clear caches
                    // localStorage.removeItem('movingJobState')
                    showToast('success', 'Job Posted!')
                    history.replace('/customer-dashboard')
                }
                else {
                    showToast('error', response.msg)
                }
                setLoader(false)
            },
            (error) => {
                setLoader(false)
                console.log(JSON.stringify(error));
                showToast('error', 'Some issue on server side. Please try later')
            });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f2f2f2', flex: 1, paddingBottom: 30 }}>

            <div className='login quote-top' style={{ paddingLeft: 0, paddingRight: 0, marginTop: 30 }}>

                <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>

                    <div className='home-label' style={{ marginTop: -10, marginBottom: 10, marginLeft: 0, alignSelf: 'center', fontSize: 20 }}>
                        Confirmation
                    </div>

                    <div
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div
                            className='option-label'
                            style={{ marginTop: 10 }}>
                            Appointment Date/Time & Contact Detail
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10 }}>
                        <img
                            style={{ width: 20, height: 20, objectFit: 'contain' }}
                            src={calendar} />
                        <div
                            style={{ fontSize: 16, marginLeft: 10 }}>
                            {job.preferredDate}(Preferred)  {job.timesSelected}
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10, marginLeft: -4 }}>
                        <img
                            style={{ width: 25, height: 25, objectFit: 'contain' }}
                            src={profile} />
                        <div
                            style={{ fontSize: 16, marginLeft: 10 }}>
                            {user.first_name} {user.last_name}
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
                        <img
                            style={{ width: 20, height: 20, objectFit: 'contain' }}
                            src={email} />
                        <div
                            style={{ fontSize: 16, marginLeft: 10 }}>
                            {user.email}
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
                        <img
                            style={{ width: 20, height: 20, objectFit: 'contain' }}
                            src={phone} />
                        <div
                            style={{ fontSize: 16, marginLeft: 10 }}>
                            {quoteContactDetails.phone}
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10, marginLeft: -5, alignItems: 'center' }}>
                        <img
                            style={{ width: 30, height: 30, objectFit: 'contain' }}
                            src={marker} />
                        <div
                            style={{ fontSize: 16, marginLeft: 6 }}>
                            {job.address}, {job.state}
                        </div>
                    </div>

                    <div
                        style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div
                            className='option-label'
                            style={{ marginTop: 10 }}>
                            Request Detail
                        </div>
                    </div>

                    {questions.map((item, index) => {
                        return (
                            <div>
                                <div
                                    className='option-label'
                                    style={{ marginTop: 10, fontSize: 14 }}>
                                    {item.q}
                                </div>
                                <div
                                    style={{ marginTop: 0, fontSize: 14 }}>
                                    {item.a}
                                </div>
                            </div>
                        )
                    })}

                    <button
                        onClick={() => {
                            let pathName = ''
                            if (jobType === 'plumber') {
                                pathName = '/plumber-quote'
                            }
                            else if (jobType === 'painting') {
                                pathName = '/paint-quote'
                            }
                            else if (jobType === 'aircon') {
                                pathName = '/aircon-quote'
                            }
                            else if (jobType === 'mover') {
                                pathName = '/mover-quote'
                            }
                            history.push({
                                pathname: pathName,
                                state: { selectedFiles: job.selectedFiles }
                            })
                        }}
                        className='login-btn'
                        style={{ marginTop: 20, fontSize: 17, width: '100%', backgroundColor: 'gray' }}>
                        Change Details
                    </button>

                    <button
                        onClick={submitJobRequest}
                        className='login-btn'
                        style={{ marginTop: 10, fontSize: 17, width: '100%' }}>
                        Submit Quote Request
                    </button>

                </div>

                <MyLoader loader={loader} marginTop={-10} />

            </div>
        </div>
    );
}

export default QuoteConfirmation;
export const categories = [
  "Information Technology",
  "Healthcare",
  "Finance",
  "Education",
  "Engineering",
  "Manufacturing",
  "Retail",
  "Hospitality",
  "Transportation",
  "Construction",
  "Real Estate",
  "More..."
];

export const catEmojis = [
  "📱",
  "🩺",
  "💰",
  "📚",
  "🛠️",
  "🏭",
  "🛒",
  "🏖️",
  "🚗",
  "🏗️",
  "🏠",
  "➕"
];

export const countries = [
  "Select Country",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]

export const categories1 = [
  "Information Technology",
  "Healthcare",
  "Finance",
  "Education",
  "Engineering",
  "Manufacturing",
  "Retail",
  "Hospitality",
  "Transportation",
  "Construction",
  "Real Estate",
  "Telecommunications",
  "Energy",
  "Legal",
  "Marketing and Advertising",
  "Media and Entertainment",
  "Nonprofit",
  "Public Sector",
  "Agriculture",
  "Aerospace",
  "Automotive",
  "Biotechnology",
  "Pharmaceuticals",
  "Consulting",
  "Insurance",
  "Logistics",
  "Food and Beverage",
  "Human Resources",
  "Research and Development",
  "Sales",
  "Supply Chain",
  "Travel and Tourism",
  "Utilities"
];

export const caribbeanCities = [
    // Antigua and Barbuda
    "St. John's",

    // Bahamas
    "Nassau",
    "Freeport",

    // Barbados
    "Bridgetown",

    // Cuba
    "Havana",
    "Santiago de Cuba",
    "Camagüey",
    "Holguín",
    "Santa Clara",
    "Guantánamo",
    "Bayamo",
    "Cienfuegos",
    "Pinar del Río",
    "Matanzas",
    "Ciego de Ávila",
    "Las Tunas",
    "Sancti Spíritus",

    // Dominica
    "Roseau",

    // Dominican Republic
    "Santo Domingo",
    "Santiago de los Caballeros",
    "San Pedro de Macorís",
    "La Romana",
    "San Cristóbal",
    "Puerto Plata",
    "Higüey",
    "La Vega",
    "Bonao",
    "Moca",
    "Nagua",

    // Grenada
    "St. George's",

    // Haiti
    "Port-au-Prince",
    "Cap-Haïtien",
    "Les Cayes",
    "Gonaïves",
    "Jacmel",

    // Jamaica
    "Kingston",
    "Montego Bay",
    "Spanish Town",
    "Portmore",
    "Mandeville",
    "Ocho Rios",
    "Negril",

    // Puerto Rico (U.S. Territory)
    "San Juan",
    "Ponce",
    "Mayagüez",
    "Caguas",
    "Arecibo",
    "Bayamón",
    "Carolina",

    // Saint Kitts and Nevis
    "Basseterre",

    // Saint Lucia
    "Castries",
    "Soufrière",

    // Saint Vincent and the Grenadines
    "Kingstown",

    // Trinidad and Tobago
    "Port of Spain",
    "San Fernando",
    "Chaguanas",
    "Arima",

    // Aruba (Netherlands Territory)
    "Oranjestad",

    // Curaçao (Netherlands Territory)
    "Willemstad",

    // Sint Maarten (Netherlands Territory)
    "Philipsburg",

    // Bonaire (Netherlands Territory)
    "Kralendijk",

    // Guadeloupe (France Territory)
    "Basse-Terre",
    "Pointe-à-Pitre",

    // Martinique (France Territory)
    "Fort-de-France",

    // U.S. Virgin Islands
    "Charlotte Amalie",
    "Christiansted",

    // British Virgin Islands
    "Road Town"
];
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Colors from '../colors'
import QuotesView from '../customer/quotes-view';
import ChatView from '../customer/chat-view';
import DetailView from '../customer/detail-view';
import SubmitQuote from '../vendor/submit-quote';

const VendorProjectDetails = () => {
    const history = useHistory()
    const location = useLocation()
    const [loader, setLoader] = useState(false);

    const job = location.state.job
    const leftPanel = location.state.leftPanel
    // quote:
    // { 
    //     id: '3', 
    //     accepted: 0,
    //     no_of_reviews: 3,
    //     rating: 4.5,
    //     price: '50',
    //     created_at: new Date(),
    //     vendor: {
    //         first_name: 'Faisal',
    //         last_name: 'Nadeem',
    //         photo: '',
    //         email: 'saif052m@gmail.com',
    //     } 
    // },

    const [tabSel, setTabSel] = useState(1)

    useEffect(() => {
        console.log('useEffect');
    }, [])

    return (
        <div style={{ backgroundColor: Colors.background, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 40, }}>

            <div
                className='home-panel'
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>

                <div style={{ fontSize: 24, alignSelf: 'flex-start', marginBottom: 10 }}>
                    {job.title}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                    <button
                        onClick={() => {
                            setTabSel(1)
                        }}
                        className={tabSel == 1 ? 'tab-btn1-sel' : 'tab-btn1'}>
                        Details
                    </button>
                    <button
                        onClick={() => {
                            setTabSel(2)
                        }}
                        className={tabSel == 2 ? 'tab-btn2-sel' : 'tab-btn2'}>
                        Chat
                    </button>
                </div>

                <div style={{ width: '100%', paddingBottom: 20, paddingTop: 0, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

                    <div
                        style={{ flex: 3, display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: 20, backgroundColor: 'white', height: '90vh', overflow: 'scroll', marginLeft: 0, }}>
                        {tabSel == 1 ?
                            <div>
                                <DetailView job={job} />
                                {leftPanel === 'quote' ?
                                    <div>
                                        <div style={{ marginTop: 30 }} />
                                        <SubmitQuote job={job} />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            <ChatView
                                job={job}
                                showVendor={false}
                                onAccept={() => {
                                    //
                                }} />
                        }
                    </div>

                </div>

            </div>

        </div>
    );
}

export default VendorProjectDetails; 
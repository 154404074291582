import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Colors from '../colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from '../util/loader'
import Api, { photosUrl } from '../api/api'
import logo from '../../assets/pros_logo.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import pros_banner from '../../assets/pros_banner.png';
import Rating from '@mui/material/Rating';
import worker from '../../assets/worker.jpg';
import heart from '../../assets/heart.png';
import heart_fill from '../../assets/heart_fill.png';
import avatar from '../../assets/avatar.png';
import share from '../../assets/share.png';
import mover1 from '../../assets/mover1.png';
import ShowMoreText from "react-show-more-text";
import QuotePopup from "./quote-popup";
import showToast from '../util/toast';
import { useSelector } from 'react-redux';
import moment from 'moment';

const VendorProfile = () => {
    const history = useHistory()

    const { id } = useParams()

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const [loader, setLoader] = useState(false);
    const [vendor, setVendor] = useState(null);
    const [profile, setProfile] = useState(null);
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        loadVendor()
    }, [])

    const loadVendor = () => {
        setLoader(true)
        console.log('customer_id: ' + user.id);
        console.log('loadVendor: ' + id);
        Api.vendorProfile(id, user.id,
            (response) => {
                console.log('vendorProfile: ' + JSON.stringify(response));
                setLoader(false)
                if (response.status === 'success') {
                    console.log('saved: ' + response.vendor.saved);
                    setVendor(response.vendor)
                    if (response.vendor.reviews && response.vendor.reviews != null) {
                        setReviews(response.vendor.reviews)
                    }
                }
            },
            (error) => {
                setLoader(false)
            });

        Api.getBusinessProfile(id,
            (response) => {
                setLoader(false)
                console.log('businessProfile: ' + JSON.stringify(response));
                if (response.status === 'success') {
                    // localStorage.setItem('user', JSON.stringify(response.user))
                    if (response.profile != null) {
                        let profile = response.profile
                        profile.no_of_employees = profile.no_of_employees == null ? '' : profile.no_of_employees
                        setProfile(profile)
                    }
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                setLoader(false)
                console.log(error);
            });
    };

    const saveVendor = () => {
        setLoader(true)
        Api.addVendorToFavorite(user.id, id,
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    showToast('success', 'Vendor added to favorites')
                }
                else {
                    showToast('error', response.msg)
                }
            },
            (error) => {
                setLoader(false)
            });
    };

    const checkValid = (val) => {
        return val !== undefined && val != null && val !== '' && val !== 'null'
    };

    return (
        <div style={{ backgroundColor: Colors.background, minHeight: 500 }}>

            {vendor != null && profile != null &&
                <div style={{ backgroundColor: 'white', marginTop: 0, paddingBottom: 20, paddingTop: 20, display: 'flex', justifyContent: 'center', minHeight: '90vh' }}>

                    <div
                        className='home-panel'
                        style={{ display: 'flex', alignSelf: 'flex-start', flexWrap: 'wrap' }}>

                        <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>

                            {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ flex: 2, minWidth: 450 }}>
                                    <Carousel
                                        showArrows={true}
                                        showThumbs={false}>
                                        <div style={{ width: '100%', backgroundColor: '#000' }}>
                                            <img
                                                style={{ width: '100%', height: 250, objectFit: 'contain' }}
                                                src={vendor.photo == null ? avatar : vendor.photo} />
                                        </div>
                                    </Carousel>
                                </div>

                            </div> */}

                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', marginTop: -20, flexDirection: 'column', flex: 2 }}>

                                    <div style={{ padding: 15, borderRadius: 10, backgroundColor: '#fafafa', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>

                                        <div>
                                            <div className='vendor-info' style={{ marginTop: 0 }}>
                                                <img
                                                    className='vendor-logo-big'
                                                    style={{ marginLeft: 0, width: 100, height: 100, borderRadius: 50, border: '1px solid lightgray' }}
                                                    src={checkValid(vendor.photo) ? photosUrl + '/logos/' + vendor.photo : avatar} />
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div style={{ fontSize: 17, marginTop: 5, marginBottom:-5, marginLeft: 15, fontWeight: 'bold' }}>
                                                        {vendor.first_name} {vendor.last_name}
                                                    </div>
                                                    <div style={{ display: 'flex', height: 28 }}>
                                                        <Rating
                                                            readOnly
                                                            style={{ marginLeft: 15, marginTop: 5 }}
                                                            size='small'
                                                            name="simple-controlled"
                                                            value={vendor.rating ? vendor.rating : 0}
                                                            onChange={(event, newValue) => {
                                                                // setValue(newValue);
                                                            }}
                                                        />
                                                        <p style={{ fontSize: 16, marginTop: 5 }}>{vendor.rating ? vendor.rating : ''}</p>
                                                    </div>
                                                    <p style={{ marginLeft: 15, fontSize: 16, color: 'gray' }}>({vendor.reviews.length} Reviews)</p>

                                                </div>
                                            </div>
                                            <div style={{ marginTop: 10, alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <div style={{ fontSize: 15, color: Colors.theme1, fontWeight: 'normal' }}>
                                                    <span style={{ color: 'black', fontWeight: 'normal' }}>Phone:</span> {profile.phone}
                                                </div>
                                                <div style={{ fontSize: 15, marginLeft: 20, color: Colors.theme1, fontWeight: 'normal' }}>
                                                    <span style={{ color: 'black', fontWeight: 'normal' }}>Email:</span> {profile.email}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{ display: 'flex', marginTop: 10, alignSelf: 'flex-start', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-end' }}
                                            className='vendor-btns'>
                                            <button
                                                onClick={async () => {
                                                    try {
                                                        await navigator.clipboard.writeText(window.location.href)
                                                        showToast('success', 'Link copied!')
                                                    }
                                                    catch (err) {
                                                        showToast('success', 'Link copy failed!')
                                                    }
                                                }}
                                                className='save-btn'
                                                style={{ borderWidth: 0, backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img
                                                    style={{ width: 28, height: 28 }}
                                                    src={share} />
                                                <div style={{ fontSize: 16, marginLeft: 5, color: Colors.gray, fontWeight: 'bold' }}>
                                                    Share
                                                </div>
                                            </button>
                                            <button
                                                onClick={saveVendor}
                                                className='save-btn'
                                                style={{ marginTop: -1, borderWidth: 0, backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img
                                                    style={{ width: 30, height: 30 }}
                                                    src={vendor.saved + '' === '1' ? heart_fill : heart} />
                                                <div style={{ fontSize: 16, marginLeft: 5, color: Colors.gray, fontWeight: 'bold' }}>
                                                    Save
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                    {/* <div
                                        className='quote-popup-parent' style={{ marginTop: 10 }}>
                                        <QuotePopup type='all' />
                                    </div> */}

                                    <div style={{ marginTop: 10 }} />

                                    <ShowMoreText
                                        lines={3}
                                        more={<span style={{ textDecoration: 'none' }}>Show more</span>}
                                        less={<span style={{ textDecoration: 'none' }}></span>}
                                        className="content-css show-more-less"
                                        anchorClass="show-more-less-clickable"
                                        onClick={() => {
                                            //
                                        }}
                                        expanded={true}
                                        truncatedEndingComponent={"... "}>
                                        {profile != null ? profile.detail : ''}
                                    </ShowMoreText>

                                    <div style={{ fontSize: 18, marginTop: 15, fontWeight: 'bold' }}>
                                        Services Provided
                                    </div>
                                    <div className='my-line' />
                                    <div style={{ display: 'flex', marginTop: 0, flexWrap: 'wrap' }}>
                                        {profile.services.split(',').map((item, index) => {
                                            return (
                                                <div className='tab-item'>{item}</div>
                                            )
                                        })}
                                    </div>

                                    <div id='area' style={{ fontSize: 18, marginTop: 20, fontWeight: 'bold' }}>
                                        Skills
                                    </div>
                                    <div className='my-line' />
                                    <div style={{ display: 'flex', marginTop: 0, flexWrap: 'wrap' }}>
                                        {profile.skills.split(',').map((item, index) => {
                                            return (
                                                <div className='tab-item'>{item}</div>
                                            )
                                        })}
                                    </div>

                                    <div style={{ fontSize: 18, marginTop: 20, fontWeight: 'bold' }}>
                                        Reviews
                                    </div>
                                    <div className='my-line' />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {reviews.length == 0 &&
                                            <div style={{ color: 'gray', fontSize: 16 }}>
                                                No Reviews Found
                                            </div>
                                        }
                                        {reviews.map((item, index) => {
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 0, marginTop: 0 }}>
                                                    <div className='vendor-info' style={{}}>
                                                        {/* <img
                                                            style={{ width: 50, height: 50, marginTop: 0 }}
                                                            src={avatar} /> */}
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ fontSize: 14, marginLeft: 0, fontWeight: 'bold' }}>
                                                                    {item.customer.first_name} {item.customer.last_name}
                                                                </div>
                                                                <div style={{ fontSize: 14, marginLeft: 10, color: 'gray' }}>
                                                                    {moment(item.created_at).format('DD-MM-YYYY h:m a')}
                                                                </div>
                                                            </div>

                                                            <div style={{ display: 'flex' }}>
                                                                <Rating
                                                                    style={{ marginLeft: 0, marginTop: 0 }}
                                                                    size='small'
                                                                    name="simple-controlled"
                                                                    value={item.rating}
                                                                    onChange={(event, newValue) => {
                                                                        // setValue(newValue);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ fontSize: 16, marginTop: 10 }}>
                                                        {item.review}
                                                    </div>

                                                    {/* <div style={{ display: 'flex', marginTop: 5, flexWrap: 'wrap' }}>
                                                        {['Reasonable Price', 'Responsive', 'Excellent Service'].map((item, index) => {
                                                            return (
                                                                <div className='tab-item'>{item}</div>
                                                            )
                                                        })}
                                                    </div> */}

                                                    <div className='my-line' style={{ marginTop: 15, marginBottom: 15 }} />

                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {/* <div className='dummy-panel' /> */}
                            </div>
                        </div>

                        {/* <div className='dummy-panel' /> */}

                        {/* <div
                            id='get-qoute'
                            className='get-qoute'>
                            <div
                                style={{ width: '100%' }}>
                                <QuotePopup type='vendor' />
                            </div>
                        </div> */}

                    </div>

                </div>
            }

            <MyLoader loader={loader} marginTop={-10} />

        </div>
    );
}

export default VendorProfile; 
import React from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import Api from './api/api'

const HowPros = () => {
    const history = useHistory()

    const showStep = (step, stepTxt) => {
        return (
            <div className='step-parent' style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                <div className='step' style={{ backgroundColor: 'white' }}>
                    <div>{step}</div>
                </div>
                <div className='step-txt'>
                    {stepTxt}
                </div>
            </div>
        )
    };

    return (
        <div className='main' style={{ overflow: 'scroll' }}>

            <div style={{ width: '100%', backgroundColor: 'white', marginTop: 20, paddingBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    className='home-panel'
                    style={{ alignSelf: 'center', paddingTop: 20 }}>
                    <div className='home-label' style={{ alignSelf: 'center', display: 'flex', textAlign: 'center' }}>
                        Use Cariworks as a Service Provider!
                    </div>
                    <div
                        style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 30, alignItems: 'flex-start', justifyContent: 'center' }}>
                        {showStep(1, 'Complete your business profile')}
                        {showStep(2, 'Appy on jobs')}
                        {showStep(3, 'Complete the job and earn money')}
                    </div>
                    <div style={{width:'100%', height:1, marginTop:20, marginBottom:20, backgroundColor:'lightgray'}}/>
                    <div style={{ display: 'flex', marginBottom:10, flexDirection: 'column', alignSelf: 'stretch', height: 70 }}>
                        <button
                            className='search-btn'
                            style={{ display: 'flex', alignSelf: 'center', marginTop: 10, width: 300 }}
                            onClick={() => {
                                history.push({
                                    pathname: '/signup',
                                    state: { type: 'vendor' }
                                })
                            }}>
                            Join Now
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default HowPros; 
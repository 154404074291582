import React, { useState } from 'react';
import '../../App.css';
import { useHistory, useParams } from 'react-router-dom';
import * as Colors from '../colors'
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/Actions'
import Api from '../api/api';
import MyLoader from '../util/loader'
import showToast from '../util/toast'

const SubmitQuote = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    let user = useSelector(state => state.MyReducer.user);
    if (user == null && localStorage.getItem('user') != null) {
        user = JSON.parse(localStorage.getItem('user'))
    }

    const job = props.job

    const [loader, setLoader] = useState(false);

    const [price, setPrice] = useState('');
    const [proposal, setProposal] = useState('');

    const options = [
        'Per Hour Charge',
        'Total Charge',
        'Need to meet or require more details',
    ]
    const [checked, setChecked] = useState(-1)

    const saveQuote = () => {
        // check logged in and check vendor
        if (user == null) {
            history.replace('/login')
            return
        }
        if (user.user_type === 'customer') {
            // update customer to vendor
            Api.updateCustomer(user.id,
                (response) => {
                    console.log(JSON.stringify(response));
                },
                (error) => {
                    console.log(error);
                });
        }

        // save quote
        if (checked !== 2 && price === '') {
            showToast('error', 'Please enter price')
            return
        }

        if (proposal === '') {
            showToast('error', 'Please write your proposal.')
            return
        }

        setLoader(true)
        Api.createJobQuote(job.id, user.id, options[checked], price, proposal,
            (response) => {
                setLoader(false)
                console.log(JSON.stringify(response));
                if (response.status === 'success') {
                    // dispatch(Actions.seQuotedJobs([{}]))
                    showToast('success', 'Job Quoted!')

                    // send notification
                    // let title = 'New Quote'
                    // let msg = 'You got a price quote by ' + user.first_name + ' ' + user.last_name
                    // Api.sendNotification('', job.id, title, msg, 'alert', null, null);

                    history.goBack()
                }
                else {
                    if (response.msg.toLowerCase().includes('already'))
                        showToast('error', 'You already quoted on this job!')
                    else if (response.msg.toLowerCase().includes('not active')) {
                        showToast('error', 'Sorry your account is not yet verified by admin.')
                    }
                }
            },
            (error) => {
                console.log(error);
                setLoader(false)
                showToast('error', 'Request failed!')
            });
    };

    return (
        <div style={{ display: 'flex', flex: 1, minWidth: 270, backgroundColor: '#fafafa', padding: 20, marginRight: 20, border: '1px solid #f2f2f2', borderRadius: 10 }}>

            <div style={{ padding: 0, fontSize: 18, width: '100%' }}>
                <div style={{ padding: 0, fontSize: 22, color: 'black', fontWeight: 'bold' }}>
                    Apply for this Job
                </div>

                <div className='my-line' style={{ marginTop: 0, marginBottom: 0 }} />

                <div style={{ fontSize: 16, color: 'gray', marginTop: 10, marginLeft: 10 }}>
                    Price Type
                </div>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 5, marginLeft: 10, marginRight: 0 }}>

                    {options.map((item, index) => {
                        return (
                            <div key={index.toString()} style={{ display: 'flex', marginTop: index == 0 ? 0 : 15, fontSize: 16, alignItems: 'center' }}>
                                <input
                                    style={{ width: 20, height: 20 }}
                                    type="radio"
                                    checked={checked == index}
                                    id={index.toString()}
                                    value={index.toString()}
                                    name="fav_language"
                                    onChange={(e) => {
                                        setChecked(index)
                                        // updateAns(props.index, item)
                                    }} />
                                <label style={{ marginLeft: 10 }} for={index.toString()}>
                                    {item}
                                </label>
                            </div>
                        )
                    })}
                </div>

                {checked == 2 ?
                    null
                    :
                    <div
                        className='col'
                        style={{ flex: 1, marginTop: 15, marginLeft: 12, marginRight: 12, display: 'flex', flexDirection: 'column' }}>
                        <input
                            type='text'
                            placeholder='Estimate in $'
                            value={price}
                            onChange={(event) => {
                                setPrice(event.target.value)
                            }}
                            style={{ padding: 10, borderRadius: 5, border: '1px solid gray' }} />
                    </div>
                }

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 15, marginLeft: 12, marginRight: 12, display: 'flex', flexDirection: 'column' }}>
                    <textarea
                        placeholder='Write your proposal...' id="msg" name="msg" rows="4" cols="50"
                        style={{ padding: 10, borderRadius: 5 }}
                        value={proposal}
                        onChange={(event) => {
                            setProposal(event.target.value)
                        }}>
                        {proposal}
                    </textarea>
                </div>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 5, marginLeft: 12, marginRight: 12, display: 'flex', flexDirection: 'column' }}>
                    <button
                        onClick={() => {
                            saveQuote()
                        }}
                        className='login-btn'
                        style={{ fontSize: 17, minWidth: 200, }}>
                        {'Submit Proposal'}
                    </button>
                </div>

                <MyLoader loader={loader} marginTop={-10} />

                {/* <div style={{ marginTop: 10, fontSize: 16, marginLeft: 12, marginRight: 12 }}>
                    <span style={{ color: 'red' }}>Note: </span>Have a look at <a href='/vendor-terms'>Our Payment Terms</a>.
                </div> */}

            </div>

        </div>
    );
}

export default SubmitQuote; 